<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: bolder;" class="modal-title mb-0">Add Lead</h1>

    <div class="addProductButtons">

        <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
            (click)="clearInput()">Cancel</button>
        <button type="submit" class="btn btn-save" (click)="onSaveLead()">Save</button>

    </div>

</div>
<div class="modal-body">
    <form [formGroup]="LeadForm">
        <div class="row">
            <div class="mb-3 col-lg-6">
                <div class="image-wrapper">
                    <label class="profile-pic" ondragover="return false;" [class.loaded]="loaded"
                        [style.outlineColor]="dragging ? activeColor : baseColor" (dragenter)="handleDragEnter()"
                        (dragleave)="handleDragLeave()" (drop)="handleDrop($event)">
                        <img [src]="profilePic ? profilePic : '/assets/images/default-profile-pic.JPG'"
                            (load)="handleImageLoad()" [class.loaded]="imageLoaded" class="shadow-sm" />
                        <input type="file" name="file" accept="image/*" (change)="handleInputChange($event)">
                    </label>
                    <button *ngIf="profilePic" class="delete-button" type="button" (click)="deleteProfilePic()">
                        <i class="bi bi-x bi-sm"></i>
                    </button>
                </div>
            </div>
            <div class="mb-3 col-lg-6">
                <label class="form-label">Full Name <span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" matInput formControlName="firstName" 
                    (keypress)="StringOnly($event)" autocomplete="off" placeholder="Full Name"
                        [ngClass]="{
                      'is-invalid': (f.firstName.invalid && f.firstName.touched) && submitted }">
                </mat-form-field>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.firstName.touched) && f.firstName.errors?.required">
                    Full Name is Required.
                </mat-error>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.firstName.touched) && f.firstName.errors?.alphabeticOnly && !f.firstName.errors?.required">
                    Only Alphabets are allowed.
                </mat-error>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <label class="form-label">Email <span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">

                    <input type="text" matInput formControlName="email" placeholder="Email" [ngClass]="{
                          'is-invalid': (f.email.invalid && f.email.touched) && submitted }"
                        required >
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                    Email is required.
                </mat-error>
                <mat-error class="text-danger" *ngIf="(submitted || f.email.touched) && f.email.errors?.pattern">
                    Email must be a valid email address.
                </mat-error>
            </div>

            <div class="col-lg-6">
                <label class="form-label">Contact # <span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" (keypress)="numberOnly($event)" matInput formControlName="mobile"
                        minlength="10" maxlength="10" placeholder="Contact #" [ngClass]="{
                          'is-invalid': (f.mobile.invalid && f.mobile.touched) && submitted }" autocomplete="off">
                </mat-form-field>

                <mat-error class="text-danger" *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                    Contact # is required.
                </mat-error>
                <!-- <mat-error class="text-danger" *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.pattern">
                    mobile must be a valid mobile number .
                </mat-error> -->
                <mat-error class="text-danger" *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.minlength">
                    Contact # must be 10-digits only.
                </mat-error>
                <mat-error class="text-danger" *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.maxlength">
                    Contact # must be 10-digits only.
                </mat-error>
            </div>
        </div>
        <div class="row">
            <!-- Item Description -->
            <div class="col-sm-12 col-md-12 col-lg-12">
                <label class="form-label">Address <span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <textarea matInput rows="1" placeholder="Enter Address"
                        formControlName="address" autocomplete="off" maxlength="100"></textarea>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.address.touched) && f.address.errors?.required">
                    Address is required.
                </mat-error>
                <!-- <mat-error class="text-danger" *ngIf="(submitted || f.description.touched)" >
                    <mat-error *ngIf="f.description.errors?.required">
                        Item Description is required!
                    </mat-error>
                    [ngClass]="{
                        'is-invalid': (f.itemCode.invalid && f.itemCode.touched) && submitted }" 
                </mat-error> -->
            </div>

        </div>
        <div class="row">
            <div class="col-lg-6">
                <label class="form-label">Pincode </label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" matInput  formControlName="pincode" maxlength="6" minlength="6"  (keypress)="numberOnly($event)" placeholder="Pincode"
                        [ngClass]="{'is-invalid': (f.pincode.invalid && f.pincode.touched) && submitted }"
                        autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.pincode.touched) && f.pincode.errors?.pattern">
                    Pincode should be only digits.
                </mat-error>
            </div>

            <div class="col-lg-6">
                <label class="form-label">City <span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <!-- <input type="text" matInput formControlName="city"  placeholder="City"
                        autocomplete="off"> -->
                        <mat-select required formControlName="city" name="pintxt" id="pintxt" placeholder="City"
                        [ngClass]="{
                      'is-invalid': (f.city.invalid && f.city.touched) && submitted }">
                        <mat-option *ngFor="let city of cityArr" [value]="city">{{ city }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.city.touched) && f.city.errors?.required">
                    City is Required.
                </mat-error>
            </div>
        </div>

        <div class="row">
            <!-- <div class="col-lg-6">
                <label class="form-label">State <span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" matInput  formControlName="state"  placeholder="State"
                        autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.state.touched) && f.state.errors?.required">
                    State is Required.
                </mat-error>
            </div> -->

            <div class="col-lg-6">
                <label class="form-label">Country <span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" matInput  formControlName="country" placeholder="Country"
                        autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.country.touched) && f.country.errors?.required">
                    Country is Required.
                </mat-error>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Product</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select formControlName="product" matNativeControl disableOptionCentering
                        placeholder="Select Support Type" autocomplete="off">
                        <mat-option value="P1">P1</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Status <span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select formControlName="status" matNativeControl disableOptionCentering
                        placeholder="Status" autocomplete="off">
                        <mat-option value="FOLLOWUP">Follow Up</mat-option>
                        <mat-option value="REJECT">Reject</mat-option>
                        <mat-option value="CLOSE">Close</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.status.touched) && f.status.errors?.required">
                    Status is Required.
                </mat-error>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Contact Type <span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select formControlName="contact" matNativeControl disableOptionCentering
                        placeholder="Select Contact Type" autocomplete="off">
                        <mat-option value="CLIENT">Client</mat-option>
                        <mat-option value="CUSTOMER">Customer</mat-option>
                        <mat-option value="LEAD">Lead</mat-option>
                    </mat-select>
                   
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.contact.touched) && f.contact.errors?.required">
                    Contact Type is Required.
                </mat-error>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Type <span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-select formControlName="type" matNativeControl disableOptionCentering placeholder="Select Type"
                        (selectionChange)="getOfferType($event)" autocomplete="off">
                        <mat-option value="HOURS">Hours</mat-option>
                        <mat-option value="COST">Cost</mat-option>
                    </mat-select>
                   
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.type.touched) && f.type.errors?.required">
                    Type is Required.
                 </mat-error>
            </div>
        </div>

        <div class="row" *ngIf="type == 'HOURS' || type == 'COST'">
            <div class="col-lg-6">
                <label class="form-label">Hours <span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="number" min="0" matInput  formControlName="hours" placeholder="Enter Hours"
                        autocomplete="off">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.hours.touched) && f.hours.errors?.required">
                    Hours is Required.
                 </mat-error>
            </div>

        </div>

    </form>

</div>