<script src="vendors/choices/choices.min.js"></script>

<div class="container-fluid mb-3 mx-0 bg-white">
    <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
        <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
          Vendor Statement
        </div>
        <div class="col-6 navOption-btns" style ="display: flex; justify-content: flex-end;">
          <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i>
            Export</button>
    
          <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
          </button>
        </div>
    </div>
    <hr class="mt-1" />
    <div class="fs--1 ">
        <div class="row">
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-4 col-lg-3" id = "dateRangePrintHide">
                <label for="Date Range" class="form-label">Date</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input  class="uppercase-date-range" matInput placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true" ngModel
                        bsDaterangepicker [maxDate]="today"
                        [(ngModel)]="dateRange"
                        [bsConfig]="{ranges: ranges,showWeekNumbers:false, showNextMonth: false, rangeInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
                    <span><mat-icon class="iconcalender p-1">date_range</mat-icon></span>
                </mat-form-field>
            </div>
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-4 col-lg-3" id = "searchBarHide">
                <label class="form-label">Select Vendor Name</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" placeholder="Vendor Name" aria-label="Select Party Name" 
                    (keyup)="searchPartyByName1($event.target.value)" matInput [matAutocomplete]="auto" [ngClass]="{
                    'is-invalid': (partyName.invalid && partyName.touched) && submitted }" required>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of allPartyList" [value]="option.partyName"
                            (onSelectionChange)="setSelectedPartyDeatails($event,option)">
                            {{option.partyName}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>
        <div class="table-responsive scrollbar mt-4 fs--1 mb-2">
            <table class="table table-bordered table-stripped" id="excel-table">
                <thead class="" style="background-color: #F0F0F0;">
                    <tr class="text-900">
                        <th scope="col" class="text-center text-nowrap p-1">Date
                        </th>
                        <th scope="col" class="text-center text-nowrap p-1">TXN Type

                            <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>

                            <div class="dropdown-menu sm p-3 " aria-labelledby="dropdownMenuButton" style="width: 28%;">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example"
                                        (change)="onselectxnType($event.target.value)">
                                        <option value="SALES">SALES</option>
                                        <option value="PURCHASE">PURCHASE</option>
                                        <option value="SALEORDER">SALE ORDER</option>
                                        <option value="PURCHASEORDER">PURCHASE ORDER</option>
                                        <option value="ESTIMATE">ESTIMATE</option>
                                    </select>
                                </li>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group sm-2 mb-md-0 ">
                                        <button class="btn btn-primary btn-sm" type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group sm-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm" type="button"
                                            style="margin-left: 8px;"
                                            (click)="applytxnType()">APPLY</button>

                                    </div>
                                </div>
                            </div>

                        </th>
                        <th scope="col" class="text-center text-nowrap p-1">Ref No.
                            <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example">
                                        <option selected="">Contains</option>
                                        <option value="1">Exact match</option>
                                    </select>

                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" [(ngModel)]="Ref"/>
                                    </div>
                                </li>

                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;"
                                            (click)="applyRef()">APPLY</button>

                                    </div>
                                </div>
                            </div>
                        </th>

                        <th class="text-center text-nowrap p-1">Payment Type
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <ul class="list-unstyled">
                                    <li>
                                        <select class="form-select form-select-sm" aria-label="Default select example"
                                            (change)="onselectPayment($event.target.value)">
                                            <option value="CASH">CASH</option>
                                            <option value="CHEQUE">CHEQUE</option>
                                        </select>
                                    </li>
                                    
                                </ul>
                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;"
                                            (click)="applyPayment()">APPLY</button>

                                    </div>
                                </div>
                            </div>
                        </th>

                        <th scope="col text-center text-nowrap " class="text-nowrap p-1">Total
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example"
                                        (change)="onselectotal($event.target.value)">
                                        <option value="Equal">Equal to</option>
                                        <option value="Greater">Greater than</option>
                                        <option value="Less">Less than</option>
                                    </select>

                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input"  [(ngModel)]="total"/>
                                    </div>
                                </li>

                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;"
                                            (click)="applytotal()">APPLY</button>

                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="text-center text-nowrap p-1">Received/Paid
                            <button class="btn fs--1  p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example"
                                        (change)="onselectvalueReceivedPaid($event.target.value)">
                                        <option value="Equal">Equal to</option>
                                        <option value="Greater">Greater than</option>
                                        <option value="Less">Less than</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" [(ngModel)]="totalvalueReceivedPaid"/>
                                    </div>
                                </li>

                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;"
                                            (click)="applyReceivedPaid()">APPLY</button>

                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="text-center text-nowrap p-1">TXN Balance
                            <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example"
                                        (change)="onselectTXN($event.target.value)">
                                        <option value="Equal">Equal to</option>
                                        <option value="Greater">Greater than</option>
                                        <option value="Less">Less than</option>
                                    </select>

                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" [(ngModel)]="totalTXN"/>
                                    </div>
                                </li>

                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;"
                                            (click)="applyTXN()">APPLY</button>

                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class=" text-center text-nowrap p-1">Receivable Balance
                            <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example"
                                        (change)="onselectreceiveBalance($event.target.value)">
                                        <option value="Equal">Equal to</option>
                                        <option value="Greater">Greater than</option>
                                        <option value="Less">Less than</option>
                                    </select>

                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" [(ngModel)]="totalreceiveBalance"/>
                                    </div>
                                </li>

                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;"
                                            (click)="applyreceiveBalance()">APPLY</button>

                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="text-center text-nowrap p-1">Payable Balance
                            <button class="btn fs--1 p-0" id="dropdownMenuButton" type="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    style="margin-left: 10px;color:#FF9A62" class="text-1400  fs-1 bi-filter-right"></i></button>
                            <div class="dropdown-menu scrollbar p-3 " style="width: 22%;"
                                aria-labelledby="dropdownMenuButton">
                                <li>
                                    <select class="form-select form-select-sm" aria-label="Default select example"
                                        (change)="onselectPayable($event.target.value)">
                                        <option value="Equal">Equal to</option>
                                        <option value="Greater">Greater than</option>
                                        <option value="Less">Less than</option>
                                    </select>
                                </li>
                                <li>
                                    <div class="col-sm" style="margin-top: 10px;">
                                        <input class="form-control fs--1" id="inputPassword3" type="input" [(ngModel)]="totalPayable"/>
                                    </div>
                                </li>

                                <div class="dropdown-divider"></div>
                                <div class="text-center">
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button">CLEAR</button>

                                    </div>
                                    <div class="btn-group mb-2 mb-md-0">
                                        <button class="btn btn-primary btn-sm " type="button"
                                            style="margin-left: 8px;"
                                            (click)="applyPayable()">APPLY</button>

                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="text-center p-1">
                            <div class="text-center">
                                <button class="btn btnb btnp" type="button" data-bs-toggle="modal" (click)="getReceiptById()">
                                    <span class="bi-printer icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top" title="print"
                                        style="margin-right: 5px !important;"></span>
                                </button>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="btn-reveal-trigger text-left" *ngFor="let item of searchpartyList.result;let i = index">
                        <td>{{item.invoiceDate}}</td>
                        <td>{{item.receiptType}}</td>
                        <td>{{item.invoiceNo}}</td>
                        <td>{{item.paymentsType === null ? "NA" : item.paymentsType}}</td>
                        <td>{{item.totalBillAmount | number:'1.2-2'}}</td>
                        <td>{{item.received | number:'1.2-2'}}</td>
                        <td>{{item.billGstAmount | number:'1.2-2'}}</td>
                        <td>{{item.totalCredit | number:'1.2-2'}}</td>
                        <td>{{item.totalDebt | number:'1.2-2'}}</td> 
                        <td></td>        
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer p-0">
            <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                        <button class="accordion-button" style="height: 10px" type="button" data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseOne">
                            Vendor Statement Summary

                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne">
                        <div class="accordion-body">
                            <div>
                                <div class="row">
                                    <div class="col-sm-3 ">
                                        <strong class=" text-dark">Total Sales: ₹ {{this.searchpartyList.length !== 0 ? (this.searchpartyList.totalAmount.totalSales| number:'1.2-2') : "0.00"}}</strong>
                                        <div class="link-400">
                                            (Sales - Sales Return)

                                        </div>
                                    </div>
                                    <div class="col-sm-3 ">
                                        <strong class=" text-dark">Total Purchase: ₹{{this.searchpartyList.length !== 0 ? (this.searchpartyList.totalAmount.totalPurchase | number:'1.2-2') : "0.00"}}</strong>
                                        <div class="link-400">
                                            (Purchase - Purchase Return)
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <strong class=" text-dark">Total Expense: ₹ 0.00</strong>
                                    </div>

                                </div>

                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-sm-3">
                                        <strong class=" text-dark">Total Money-In: ₹{{this.searchpartyList.length !== 0 ? (this.searchpartyList.totalAmount.moneyIn | number:'1.2-2') : "0.00"}}</strong>
                                    </div>
                                    <div class="col-sm-3">
                                        <strong class=" text-dark">Total Money-out: ₹{{this.searchpartyList.length !== 0 ? (this.searchpartyList.totalAmount.moneyOut| number:'1.2-2') : "0.00"}}</strong>
                                    </div>
                                    <div class="col-sm-3">
                                    </div>
                                    <div class="col-sm-3 ">
                                        <strong class="fw-bold text-dark "
                                            style="margin-left: 30px; margin-bottom: 30px;">Total Receivable</strong>
                                        <div class="text-success">
                                            <strong class="fw-bold text-success" style="margin-left: 30px;">₹
                                                {{this.searchpartyList.length !== 0 ? (this.searchpartyList.totalAmount.totalReceivable| number:'1.2-2') : "0.00"}}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>