<div class="container-fluid mb-3 px-0 mx-0">
    <div class="row pt-2 pb-3" id="headingPrintHide">
        <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:500;font-size:20px!important;padding-left: 15px;">
            GSTR 2A-B2B Invoice Summary
        </div>
        <div class="col-6 navOption-btns" style="display: flex; justify-content: flex-end;">
            <button class="btn me-3" style="white-space: nowrap;" (click)="exportExcel()"
                type="button"><i class="fas fa-download"></i></button>

            <button class="btn" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
            </button>
        </div>
    </div>
    <hr class="mt-1" />
    <div class="fs--1 bg-white">
        <div class="row mb-3">
            <div id="headingPrintHide">
                <u class="text-nowrap"><strong style="padding-left: 3px !important;font-weight: 500; font-size: 14px;">Uploaded by
                        Supplier</strong></u>
            </div>

            <!-- <div class="col-sm-4"></div>
                <div class="col-sm-1  mt-2 text-center" id="excelPrintHide" (click)="exportExcel()">
                    <a type="button" style="margin-left: 14px;" class="p-0 text-900 bi-file-earmark-excel fa-2x "></a>
                    <div class="text-nowrap text-900  fs--2  mt-1">Export to Excel</div>
                </div>
                <div class="col-sm-1 mt-2 text-center" id="PrintoptionHide" (click)="onPrint()">
                    <a type="button" class=" p-0 text-900 bi-printer float-right fa-2x"></a>
                    <div class="fs--2 text-900 text-nowrap mt-1">Print</div>
                </div> -->

        </div>

        <div class="table-responsive scrollbar">
            <!-- <div class="tab-pane fade show active" id="tab-home" role="tabpanel" aria-labelledby="home-tab"> -->
            <table class="table bg-white table-bordered table-stripped" id="excel-table">
                <thead class="table-secondary" style="background-color: #F0F0F0;">
                    <tr>
                        <th class="text-nowrap" scope="col">Invoice No.</th>
                        <th class="text-nowrap" scope="col">Invoice Date</th>
                        <th class="text-nowrap" scope="col">Invoice Type</th>
                        <th class="text-nowrap" scope="col">Place Of Supply</th>
                        <th class="text-nowrap" scope="col">Supply Attract Reverse Charge </th>
                        <th class="text-nowrap" scope="col" style="text-align: right;">Total Invoice Value</th>
                        <th class="text-nowrap" scope="col" style="text-align: right;">Total Taxable Value</th>
                        <th class="text-nowrap" scope="col" style="text-align: right;">Integrated Tax</th>
                        <th class="text-nowrap" scope="col" style="text-align: right;">Central Tax</th>
                        <th class="text-nowrap" scope="col" style="text-align: right;">State/UT Tax</th>
                        <th class="text-nowrap" scope="col" style="text-align: right;">Cess</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let gst2A of gst2AList">
                        <td>{{gst2A.invoiceNo}}</td>
                        <td>{{gst2A.invoiceDate| date:"dd MMM YYYY"| uppercase}}</td>
                        <td>{{gst2A.invoiceType}}</td>
                        <td>{{gst2A.billingAddress}}</td>
                        <td>{{gst2A.rcm}}</td>
                        <td style="text-align: right;"><i class="fas fa-angle-double-right    "></i>&nbsp;{{gst2A.total_invoice_value}}</td>
                        <td style="text-align: right;"><i class="fas fa-angle-double-right    "></i>&nbsp;{{gst2A.total_taxable_value | number:'1.2-2'}}</td>
                        <td style="text-align: right;"><i class="fas fa-angle-double-right    "></i>&nbsp;{{gst2A.integrated_Tax | number:'1.2-2'}}</td>
                        <td style="text-align: right;"><i class="fas fa-angle-double-right    "></i>&nbsp;{{gst2A.central_Tax | number:'1.2-2'}}</td>
                        <td style="text-align: right;"><i class="fas fa-angle-double-right    "></i>&nbsp;{{gst2A.state_UT_tax | number:'1.2-2'}}</td>
                        <td style="text-align: right;" *ngIf="gst2A.totalCess !== null">₹&nbsp;{{gst2A.totalCess | number:'1.2-2'}}</td>
                        <td style="text-align: right;" *ngIf="gst2A.totalCess == null">NA</td>
                    </tr>
                </tbody>
            </table>
            <!-- </div> -->
        </div>
    </div>
</div>
