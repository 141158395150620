<!-- Fixed navigation panel -->
<div class="container-fluid pt-3">
    <div class="fixed-nav-panel row">
        <div class="nav-panel-header col-lg-3">
            <label class="form-label mb-3" style="font-size: 20px; font-weight: 500;">
                Business Settings
            </label>
        </div>
        <div class="nav-panel-actions headerButtons col-lg-9">
            <div class="nav-panel-btns">
                <button mat-raised-button class="text-nowrap text-white" style="background: #110152;"
                        routerLink="/pages/add-new-trade" *appHasPermission="['delete', 'modify','create', 'read']; name 'shopschedule'">Add New Trade</button>
                <!-- <button mat-stroked-button class="text-nowrap"
                    style="background: rgb(64, 156, 193, 0.3); color: rgb(29, 145, 174)">
                    <i class="fas fa-comment"></i>
                    Chat Support</button> -->
                <button mat-raised-button class="text-nowrap" style="border: 1px solid #000;"
                    routerLink="/pages/profile">Cancel</button>
                <button mat-raised-button class="text-nowrap text-light" style="background: #a0a0a0;"
                    (click)="updateUserProfile()" type="submit" *appHasPermission="['modify', 'create']; name 'profile'">Save
                    Changes</button>
            </div>
        </div>
    </div>
    <hr style="background-color: black;font-weight: bold;">

    <form [formGroup]="ProfileForm" autocomplete="off">
        <!-- Profile options -->
        <div class="profile-options row">
            <div class="profile-details col-sm-12 col-md-6 col-lg-6 pe-4">
                <div class="row mb-5">
                    <div class="col-lg-6 col-md-12 col-sm-12 pic-container">
                        <div class="image-wrapper">
                          <button *ngIf="profilePic" class="img-delete-button" type="button" (click)="deleteProfilePic()">
                            <i class="bi bi-x bi-sm"></i>
                          </button>
                          <label class="profile-pic" ondragover="return false;" [class.loaded]="loaded"
                              [style.outlineColor]="dragging ? activeColor : baseColor"
                              (dragenter)="handleDragEnter()" (dragleave)="handleDragLeave()"
                              (drop)="handleDrop($event)">
                              <img [src]="profilePic ? profilePic : '/assets/images/default-profile-pic.JPG'"
                                  (load)="handleImageLoad()" [class.loaded]="imageLoaded" class="shadow-sm" />
                              <input type="file" name="file" accept="image/*" (change)="handleInputChange($event)">
                          </label>
                        </div>
                        <label class="pic-label">Add Profile Pic</label>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2">Admin Name<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" matInput required formControlName="firstName" maxLength="50"
                                placeholder="First Name" [ngClass]="{
                                'is-invalid': (f.firstName.invalid && f.firstName.touched) && submitted }">
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.firstName.touched) && f.firstName.errors?.required">
                            First Name is Required.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.firstName.touched) && f.firstName.errors?.pattern">
                            Only Characters Allowed.
                        </mat-error>

                    </div>
                </div>

                <div class="row mb-3">
                    <div class=" col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2">Email Address<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="email" readonly matInput required formControlName="email" placeholder="Email"
                                [ngClass]="{
                                'is-invalid': (f.email.invalid && f.email.touched) && submitted }">
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                            Email is required.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.email.touched) && f.email.errors?.pattern">
                            Email must be a valid email address.
                        </mat-error>
                    </div>
                    <div class=" col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2">Contact #<span class="error-asterisk">*</span></label>
                        <mat-form-field appearance="outline" class="example-full-width">
                            <input type="text" matInput formControlName="mobile" minlength="10" maxlength="10"
                               required (keypress)="numberOnly($event)" placeholder="Contact #" [ngClass]="{
                                    'is-invalid': (f.mobile.invalid && f.mobile.touched) && submitted }">
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                            Contact # is required.
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.minlength">
                            Contact # must be 10-digits only
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.maxlength">
                            Contact # must be 10-digits only
                        </mat-error>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="isShown">
                        <div><label class="form-label ms-2" >GSTIN<span class="error-asterisk">*</span></label  ></div>
                            <mat-form-field class="example-full-width" appearance="outline" style="width: 85%;">
                                <input type="text" matInput formControlName="gstinNo"
                                    (keyup)="validateGSTIN($event.target.value)" maxlength="15" #input1 placeholder="GSTIN"
                                    (input)="input1.value=$event.target.value.toUpperCase()"
                                    [ngClass]="{'is-invalid': (f.gstinNo.invalid && f.gstinNo.touched) && submitted }"
                                    required>
                            </mat-form-field>
                            <mat-spinner *ngIf="isLoading" diameter="30"></mat-spinner>
                            <mat-icon *ngIf="ProfileForm.controls.gstinNo.valid && !isLoading"
                                [ngClass]="{ 'gst-status-icon': true, 'active': !isDisabledGST }">
                                {{ !isDisabledGST ? '✓' : '✗' }}
                            </mat-icon>
                            <mat-error class="text-danger" *ngIf="(submitted && f.gstinNo.errors?.required)">
                                GSTIN is Required!
                            </mat-error>
                            <mat-error class="text-danger"
                                *ngIf="f.gstinNo.invalid && (f.gstinNo.dirty && f.gstinNo.touched)">
                                Please enter the valid GSTIN number
                            </mat-error>
                            <mat-error class="text-danger" *ngIf="f.gstinNo.errors?.maxlength">
                                GSTIN must be of 15-digits
                            </mat-error>
                            <mat-error class="text-danger" *ngIf="f.gstinNo.errors?.minlength">
                                GSTIN must be of 15-digits
                            </mat-error>
                            <!-- <mat-error class="text-danger" *ngIf="f.gstinNo.errors?.required">GSTIN Is Required!</mat-error> -->
                            <mat-error class="text-danger" *ngIf="f.gstinNo.errors?.pattern">
                                GSTIN must be valid number
                            </mat-error>
                        </div>   

                        
                        
                    <div class="col-lg-6 col-md-12 col-sm-12"
                        *ngIf="(ProfileForm.get('businessType').value =='Private Limited Company' || ProfileForm.get('businessType').value =='Public Limited Company') && isShown">
                        <label class="form-label ms-2">CIN<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" formControlName="cinNumber"
                                (keyup)="validatecinNumber($event.target.value)" #input1
                                (input)="input1.value=$event.target.value.toUpperCase()" placeholder="CIN"
                                [ngClass]="{'is-invalid': (f.cinNumber.invalid && f.cinNumber.touched) && submitted }"
                                required>
                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="f.cinNumber.errors?.required">
                            CIN is Required.
                        </mat-error>

                        <mat-error class="text-danger"
                            *ngIf="f.gstinNo.invalid && (f.cinNumber.dirty && f.cinNumber.touched)">
                            Please enter the valid CIN number
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.cinNumber.touched) && f.cinNumber.errors?.pattern">
                            CIN pattern is Required.
                        </mat-error>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-lg-6 col-md-12 col-sm-12"
                        *ngIf="ProfileForm.get('businessType').value =='Proprietorship' || ProfileForm.get('businessType').value =='Hindu Undivided Family' && isShown">
                        <label class="form-label ms-2">Legal Name<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput type="text" formControlName="legalName"
                                placeholder="Legal Name" [ngClass]="{
                                    'is-invalid': (f.legalName.invalid && f.legalName.touched) && submitted }" requied>
                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="(submitted || f.legalName.touched) && f.legalName.errors">
                            Legal name must be in Alphabets
                        </mat-error>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.legalName.touched) && f.legalName.errors?.required">
                            Legal Name is Required.
                        </mat-error>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="isShown">
                        <label class="form-label ms-2">Financial Year Start From<span
                                class="error-asterisk">*</span></label>
                        <mat-form-field style="font-size: 12px" class="example-full-width" appearance="outline">
                            <input matInput bsDatepicker required placeholder="Financial Year Start From" [maxDate]="today"
                                formControlName="financialYearFrom" 
                                bsDatepicker [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: 'DD MMM YYYY',
                          showWeekNumbers:false }" (click)="openDatePicker()" autocomplete="off" [ngClass]="{
                            'is-invalid': (f.financialYearFrom.invalid && f.financialYearFrom.touched) && submitted }">
                            <span>
                                <mat-icon class="iconcalender">date_range</mat-icon>
                            </span>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.financialYearFrom.touched) && f.financialYearFrom.errors?.required">
                            Financial Year Start From is Required.
                        </mat-error>
                    </div>
                </div>


                <div class="row mb-3">
                    <div class="col-sm-12">
                        <label class="form-label ms-2" for="billingAddress">Billing Address<span
                                class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <textarea matNativeControl matInput required type="text" formControlName="address" row="2"
                                placeholder="Billing Address" [ngClass]="{
                                'is-invalid': (f.address.invalid && f.address.touched) && submitted }"
                                required maxlength="100"></textarea>
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.address.touched) && f.address.errors?.required">
                            Billing Address is Required.
                        </mat-error>
                    </div>
                </div>

                <div class="row mb-3">

                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2">City<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select required formControlName="city" name="pintxt" id="pintxt" placeholder="City"
                                [ngClass]="{
                              'is-invalid': (f.city.invalid && f.city.touched) && submitted }">
                                <mat-option *ngFor="let city of cityArr" [value]="city">{{ city }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.city.touched) && f.city.errors?.required">
                            City is Required.
                        </mat-error>
                    </div>
               

                <!-- <div class="row mb-3"> -->
                    <!-- <div class="col-lg-6 col-md-12 col-sm-12">

                        <label class="form-label ms-2">City<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput required type="text" formControlName="district"
                                placeholder="City" [ngClass]="{
                                'is-invalid': (f.district.invalid && f.district.touched) && submitted }" required>
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.district.touched) && f.district.errors?.required">
                            District is Required!
                        </mat-error>
                    </div> -->
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2">Country<span class="error-asterisk">*</span></label>

                        <mat-form-field class="example-full-width" appearance="outline">



                            <input matNativeControl matInput required type="text" value="India" placeholder="Country"
                                [ngClass]="{ 'is-invalid': (f.country.invalid && f.country.touched) && submitted }">
                        </mat-form-field>

                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="isShown">
                        <label class="form-label ms-2" >Tax Payer Type<span class="error-asterisk">*</span></label  >
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl matInput disableOptionCentering formControlName="gstType"
                                placeholder="GST Type" [ngClass]="{
                                    'is-invalid': (f.gstType.invalid && f.gstType.touched) && submitted }"
                                [disabled]="!isDisabledGST">
                                <mat-option value="Regular">Regular</mat-option>
                                <mat-option value="Composite">Composite
                                </mat-option>
                                <mat-option value="Exempted">Exempted</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.gstType.touched) && f.gstType.errors?.required">
                            Tax Payer Type is Required!
                        </mat-error>
                    </div>
                <!-- </div> -->
            </div>

            </div>

            <div class="profile-details col-sm-12 col-md-6 col-lg-6 ps-4">
                <div class="row">
                    <!-- <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="isShown && ProfileForm.get('gstType').value =='Composite'"> -->
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2">Trade Name<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl required matInput formControlName="tradeName" placeholder="Trade Name" maxlength="100"
                                [ngClass]="{
                                    'is-invalid': (f.tradeName.invalid && f.tradeName.touched) && submitted }">
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.tradeName.touched) && f.tradeName.errors?.required">
                            Trade Name is Required!
                        </mat-error>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="isShown">
                        <label class="form-label ms-2">Constitution of Business<span
                                class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select formControlName="businessType" matNativeControl matInput disableOptionCentering
                                placeholder="Constitution of Business" [ngClass]="{
                                    'is-invalid': (f.businessType.invalid && f.businessType.touched) && submitted }" required>
                                <mat-option *ngFor="let item of uaeCosntBusiness" [value]="item">{{item}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.businessType.touched) && f.businessType.errors?.required">
                            Constitution of Business is Required.
                        </mat-error>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="isShown">
                        <label class="form-label ms-2">Business Type(Select multiple, if applicable)<span
                                class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select matNativeControl matInput disableOptionCentering
                                formControlName="businessNature" placeholder="Business Type" multiple
                                [ngClass]="{'is-invalid': (f.businessNature.invalid && f.businessNature.touched) && submitted }"
                                required>
                                <mat-option *ngFor="let type of typeList" [value]="type.id">{{type.businessType}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.businessNature.touched) && f.businessNature.errors?.required">
                            Business Type is Required.
                        </mat-error>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <label class="form-label ms-2">Account Method<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width mt" appearance="outline">
                            <mat-select matNativeControl matInput disableOptionCentering required
                                formControlName="accountMethod" placeholder="Account Method" [ngClass]="{
                                    'is-invalid': (f.accountMethod.invalid && f.accountMethod.touched) && submitted }"
                                required>
                                <mat-option value="Accounts">Only Accounts</mat-option>
                                <mat-option value="Accounts With Stock Management">Accounts With Stock
                                    Management</mat-option>
                                <!-- <option value="Both">Both</option> -->
                            </mat-select>
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.accountMethod.touched) && f.accountMethod.errors?.required">
                            Account Method is Required.
                        </mat-error>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" *ngIf="isShown">
                        <label class="form-label ms-2">Business Category<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-chip-list #chipList aria-label="category selection" [multiple]="true">
                                <mat-chip *ngFor="let category of categoryName" [selectable]="category == 'Education'"
                                    [removable]="removable" (removed)="remove(category)">
                                    {{ category }}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="Business Category" #categoryInput required
                                    #autocompleteTrigger="matAutocompleteTrigger" [formControl]="categoryCtrl"
                                    [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                    (click)="openAuto(autocompleteTrigger)"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event)" [ngClass]="{
                            'is-invalid':
                              (categoryCtrl.invalid && categoryCtrl.touched) && submitted }" >
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                <mat-option *ngFor="let item of filteredCategory | async" [value]="item.id"
                                    (onSelectionChange)="getCategoryId(item)">
                                    <mat-checkbox color="primary" style="padding: 0 12px"
                                        [checked]="categoryName.indexOf(item.category) >= 0"></mat-checkbox>
                                    {{ item.category }}
                                </mat-option>
                            </mat-autocomplete>
                            <a mat-icon-button matSuffix style="width: 34px; height: 34px"
                                (click)="openAuto(autocompleteTrigger)">
                                <mat-icon style="width: 34px">expand_more</mat-icon>
                            </a>
                        </mat-form-field>
                        <mat-error class="text-danger"
                        *ngIf="(submitted || categoryCtrl.touched) && categoryCtrl.errors?.required">
                            Category is Required.
                        </mat-error>
                    </div>
                    <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" *ngIf="isShown">
                        <label class="form-label ms-2">Business Category<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-chip-list #chipList aria-label="category selection" [multiple]="true">
                                <mat-chip *ngFor="let category of categoryName" [selectable]="category=='Education'"
                                    [removable]="removable" (removed)="remove(category)">
                                    {{category}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="Business Category" #categoryInput
                                    #autocompleteTrigger="matAutocompleteTrigger" [formControl]="categoryCtrl"
                                    [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                    (click)="openAuto(autocompleteTrigger)"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="add($event)" [ngClass]="{
                                        'is-invalid': (categoryCtrl.invalid && categoryCtrl.touched) && submitted }">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                <mat-option *ngFor="let item of filteredCategory | async" [value]="item.id"
                                    (onSelectionChange)="getCategoryId(item)">
                                    <mat-checkbox color="primary" style="padding: 0 12px;"
                                        [checked]="categoryName.indexOf(item.category) >=0"></mat-checkbox>
                                    {{item.category}}
                                </mat-option>
                            </mat-autocomplete>
                            <a mat-icon-button matSuffix style="width: 34px; height: 34px;"
                                (click)="openAuto(autocompleteTrigger)">
                                <mat-icon style="width: 34px;">expand_more</mat-icon>
                            </a>
                        </mat-form-field>
                        <mat-error class="text-danger"
                            *ngIf="(submitted || categoryCtrl.touched) && categoryCtrl.errors?.required">
                            Category is Required!
                        </mat-error>
                    </div> -->
                </div>

                <div class="row mb-3">
                    <!-- for partener table -->
                    <div *ngIf="ProfileForm.get('businessType').value =='Partnership'" class="mb-3">
                        <label class="pb-3 form-label" style="font-size: 16px; font-weight: 500;">
                            Partner Details
                        </label>
                        <div class="">
                            <form [formGroup]="businessForm">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered bg-white">
                                        <thead class="inner-model-table-heading">
                                            <tr class="btn-reveal-trigger">
                                                <th style="vertical-align: middle;">#</th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Position</th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Name of Partner
                                                </th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let partner of partnerDetails; let i = index">
                                                <td style="vertical-align: middle;">{{ i + 1 }}</td>
                                                <td style="vertical-align: middle;">{{ partner.position }}</td>
                                                <td style="vertical-align: middle;">{{ partner.nameOfPartner }}</td>
                                                <td style="vertical-align: middle;">
                                                    <div class="">
                                                        <button class="btn btnp" (click)="openEditModal(i, partner)">
                                                            <span class="bi-pencil-square fs-1" data-bs-toggle="tooltip"
                                                                data-bs-placement="top" title="Edit"></span>
                                                        </button>
                                                        <span class="m-1" text-center></span>
                                                        <button class="btn btnp" style="color : red"
                                                            data-bs-toggle="modal" data-bs-target="#errorDelete-modal"
                                                            (click)="deletePartner(partner.id)">
                                                            <span class="bi-trash fs-1" data-bs-toggle="tooltip"
                                                                data-bs-placement="top" title="Delete"></span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="vertical-align: middle;"></td>
                                                <td style="vertical-align: middle;">
                                                    <mat-select placeholder="Position" formControlName="position"
                                                        [value]="'Partner'">
                                                        <mat-option [value]="'Managing Partner'">Managing
                                                            Partner</mat-option>
                                                        <mat-option [value]="'Partner'" selected>Partner</mat-option>
                                                    </mat-select>
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    <input matInput type="text" placeholder="Name Of Partner"
                                                        formControlName="nameOfPartner"
                                                        (keydown.enter)="addPartner($event)">
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    <div class="">
                                                        <button class="btn btnp" [disabled]="true">
                                                            <span class="bi-pencil-square  fs-1"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="Edit"></span>
                                                        </button>
                                                        <span class="m-1" text-center></span>
                                                        <button class="btn btnp" [disabled]="true">
                                                            <span class="bi-trash  fs-1" data-bs-toggle="tooltip"
                                                                data-bs-placement="top" title="Delete"></span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>

                    </div>

                    <!-- for LLP  -->
                    <div *ngIf="ProfileForm.get('businessType').value =='Limited Liability Partnership'" class="mb-3">
                        <label class="pb-3 form-label" style="font-size: 16px; font-weight: 500;">
                            LLP Details
                        </label>
                        <div class="">
                            <form [formGroup]="businessForm">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered bg-white">
                                        <thead class="inner-model-table-heading">
                                            <tr class="btn-reveal-trigger">
                                                <th style="vertical-align: middle;">#</th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Position</th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Name of Partner
                                                </th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let partner of partnerDetails; let i = index">
                                                <td style="vertical-align: middle;">{{ i + 1 }}</td>
                                                <td style="vertical-align: middle;">{{ partner.position }}</td>
                                                <td style="vertical-align: middle;">{{ partner.nameOfPartner }}</td>
                                                <td style="vertical-align: middle;">
                                                    <div class="">
                                                        <button class="btn btnp" (click)="openEditModal(i, partner)">
                                                            <span class="bi-pencil-square  fs-1"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="Edit"></span>
                                                        </button>
                                                        <span class="m-1" text-center></span>
                                                        <button class="btn btnp" style="color : red"
                                                            data-bs-toggle="modal" data-bs-target="#errorDelete-modal"
                                                            (click)="deletePartner(partner.id)">
                                                            <span class="bi-trash  fs-1" data-bs-toggle="tooltip"
                                                                data-bs-placement="top" title="Delete"></span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td style="vertical-align: middle;">
                                                    <mat-select placeholder="Position" formControlName="position"
                                                        [value]="'Partner'">
                                                        <mat-option [value]="'Managing Partner'">Managing
                                                            Partner</mat-option>
                                                        <mat-option [value]="'Partner'" selected>Partner</mat-option>
                                                    </mat-select>
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    <input matInput type="text" placeholder="Name Of Partner"
                                                        formControlName="nameOfPartner"
                                                        (keydown.enter)="addPartner($event)">
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    <div class="">
                                                        <button class="btn btnp" [disabled]="true">
                                                            <span class="bi-pencil-square  fs-1"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="Edit"></span>
                                                        </button>
                                                        <span class="m-1" text-center></span>
                                                        <button class="btn btnp" [disabled]="true">
                                                            <span class="bi-trash  fs-1" data-bs-toggle="tooltip"
                                                                data-bs-placement="top" title="Delete"></span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>

                    </div>

                    <!-- for private limited -->
                    <div *ngIf="ProfileForm.get('businessType').value =='Private Limited Company'" class="mb-3">
                        <label class="pb-3 form-label" style="font-size: 16px; font-weight: 500;">
                            Private Limited Company Details
                        </label>
                        <div class="">
                            <form [formGroup]="businessForm">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered bg-white">
                                        <thead class="inner-model-table-heading">
                                            <tr class="btn-reveal-trigger">
                                                <th style="vertical-align: middle;">#</th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Name of Director
                                                </th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Position</th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let partner of partnerDetails; let i = index">
                                                <td style="vertical-align: middle;">{{ i + 1 }}</td>
                                                <td style="vertical-align: middle;">{{ partner.nameOfPartner }}</td>
                                                <td style="vertical-align: middle;">{{ partner.position }}</td>
                                                <td style="vertical-align: middle;">
                                                    <div class="">
                                                        <button class="btn btnp" (click)="openEditModal(i, partner)">
                                                            <span class="bi-pencil-square  fs-1"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="Edit"></span>
                                                        </button>
                                                        <span class="m-1" text-center></span>
                                                        <button class="btn btnp" style="color : red"
                                                            data-bs-toggle="modal" data-bs-target="#errorDelete-modal"
                                                            (click)="deletePartner(partner.id)">
                                                            <span class="bi-trash  fs-1" data-bs-toggle="tooltip"
                                                                data-bs-placement="top" title="Delete"></span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="vertical-align: middle;"></td>
                                                <td style="vertical-align: middle;">
                                                    <input matInput type="text" placeholder="Name Of Director"
                                                        formControlName="nameOfPartner">
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    <input matInput type="text" placeholder="Position"
                                                        formControlName="position" (keydown.enter)="addPartner($event)">
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    <div class="">
                                                        <button class="btn btnp" [disabled]="true">
                                                            <span class="bi-pencil-square  fs-1"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="Edit"></span>
                                                        </button>
                                                        <span class="m-1" text-center></span>
                                                        <button class="btn btnp" [disabled]="true">
                                                            <span class="bi-trash  fs-1" data-bs-toggle="tooltip"
                                                                data-bs-placement="top" title="Delete"></span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>

                    </div>

                    <!-- for public limited -->
                    <div *ngIf="ProfileForm.get('businessType').value =='Public Limited Company'" class="mb-3">
                        <label class="pb-3 form-label" style="font-size: 16px; font-weight: 500;">
                            Public Limited Company Details
                        </label>
                        <div>
                            <form [formGroup]="businessForm">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered bg-white">
                                        <thead class="inner-model-table-heading">
                                            <tr class="btn-reveal-trigger">
                                                <th style="vertical-align: middle;">#</th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Name of Director
                                                </th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Position</th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let partner of partnerDetails; let i = index">
                                                <td style="vertical-align: middle;">{{ i + 1 }}</td>
                                                <td style="vertical-align: middle;">{{ partner.nameOfPartner }}</td>
                                                <td style="vertical-align: middle;">{{ partner.position }}</td>
                                                <td style="vertical-align: middle;">
                                                    <div class="">
                                                        <button class="btn btnp" (click)="openEditModal(i, partner)">
                                                            <span class="bi-pencil-square  fs-1"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="Edit"></span>
                                                        </button>
                                                        <span class="m-1" text-center></span>
                                                        <button class="btn btnp" style="color : red"
                                                            data-bs-toggle="modal" data-bs-target="#errorDelete-modal"
                                                            (click)="deletePartner(partner.id)">
                                                            <span class="bi-trash fs-1" data-bs-toggle="tooltip"
                                                                data-bs-placement="top" title="Delete"></span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="vertical-align: middle;"></td>
                                                <td style="vertical-align: middle;">
                                                    <input matInput type="text" placeholder="Name Of Director"
                                                        formControlName="nameOfPartner">
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    <input matInput type="text" placeholder="Position"
                                                        formControlName="position" (keydown.enter)="addPartner($event)">
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    <div class="">
                                                        <button class="btn btnp" [disabled]="true">
                                                            <span class="bi-pencil-square fs-1" data-bs-toggle="tooltip"
                                                                data-bs-placement="top" title="Edit"></span>
                                                        </button>
                                                        <span class="m-1" text-center></span>
                                                        <button class="btn btnp" [disabled]="true">
                                                            <span class="bi-trash fs-1" data-bs-toggle="tooltip"
                                                                data-bs-placement="top" title="Delete"></span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>

                    </div>

                    <!-- for hindu  undidvided family -->
                    <div *ngIf="ProfileForm.get('businessType').value =='Hindu Undivided Family'" class="mb-3">
                        <label class="pb-3 form-label" style="font-size: 16px; font-weight: 500;">
                            HUF Details
                        </label>
                        <div>
                            <form [formGroup]="businessForm">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered bg-white">
                                        <thead class="inner-model-table-heading">
                                            <tr class="btn-reveal-trigger">
                                                <th style="vertical-align: middle;">#</th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Position</th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Members</th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let partner of partnerDetails; let i = index">
                                                <td style="vertical-align: middle;">{{ i + 1 }}</td>
                                                <td style="vertical-align: middle;">{{ partner.position }}</td>
                                                <td style="vertical-align: middle;">{{ partner.nameOfPartner }}</td>
                                                <td style="vertical-align: middle;">
                                                    <div class="">
                                                        <button class="btn btnp" (click)="openEditModal(i, partner)">
                                                            <span class="bi-pencil-square  fs-1"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="Edit"></span>
                                                        </button>
                                                        <span class="m-1" text-center></span>
                                                        <button class="btn btnp" style="color : red"
                                                            data-bs-toggle="modal" data-bs-target="#errorDelete-modal"
                                                            (click)="deletePartner(partner.id)">
                                                            <span class="bi-trash  fs-1" data-bs-toggle="tooltip"
                                                                data-bs-placement="top" title="Delete"></span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="vertical-align: middle;"></td>
                                                <td style="vertical-align: middle;">
                                                    <mat-select placeholder="Position" formControlName="position"
                                                        [value]="'Karta'">
                                                        <mat-option [value]="'Karta'">Karta</mat-option>
                                                        <mat-option [value]="'Member'">Member</mat-option>
                                                    </mat-select>
                                                <td style="vertical-align: middle;">
                                                    <input matInput type="text" placeholder="Name of Member"
                                                        formControlName="nameOfPartner"
                                                        (keydown.enter)="addPartner($event)">
                                                </td>
                                                <td style="vertical-align: middle;">
                                                    <div class="">
                                                        <button class="btn btnp" [disabled]="true">
                                                            <span class="bi-pencil-square  fs-1"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="Edit"></span>
                                                        </button>
                                                        <span class="m-1" text-center></span>
                                                        <button class="btn btnp" [disabled]="true">
                                                            <span class="bi-trash  fs-1" data-bs-toggle="tooltip"
                                                                data-bs-placement="top" title="Delete"></span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </form>
                        </div>


                    </div>

                    <div class="form-check form-switch mb-3" style="padding-left: 3.7rem;">
                        <input class="form-check-input" type="checkbox" id="toggleAdditionalFields"
                            (change)="toggleAdditionalFields()">
                        <label class="form-check-label" for="toggleAdditionalFields" *ngIf="showAdditionalFields"><b
                                style="font-size: 14px; font-weight:500">Show Additional Fields</b></label>
                        <label class="form-check-label" for="toggleAdditionalFields" *ngIf="!showAdditionalFields"
                            style="font-size: 14px;">Show
                            Additional Fields</label>
                    </div>

                    <div class="mb-3 pb-1" *ngIf="showAdditionalFields">
                        <label class="pb-3 form-label" style="font-size: 16px; font-weight: 500;">
                            Additional Fields
                        </label>
                        <div>
                            <form [formGroup]="businessForm">
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered bg-white">
                                        <thead class="inner-model-table-heading">
                                            <tr class="btn-reveal-trigger">
                                                <th style="vertical-align: middle;">#</th>
                                                <th class="text-nowrap" style="vertical-align: middle;">
                                                    License/Certificate </th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Document #
                                                </th>
                                                <th class="text-nowrap" style="vertical-align: middle;">Print in Sale
                                                    Invoice</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of additionaDetailsData; let i = index">
                                                <td style="vertical-align: middle;">{{ i + 1 }}</td>
                                                <td style="vertical-align: middle;">{{ item.licenceAndCertificate }}
                                                </td>
                                                <td style="vertical-align: middle;">{{ item.documentNumber }}</td>
                                                <td style="vertical-align: middle;">
                                                    <div class="">
                                                <td class="flex d-flex flex-row"
                                                    style="justify-content: center; align-items: center; vertical-align: middle;">
                                                    <div class="form-check form-switch mb-0">
                                                        <input class="form-check-input text-center" id="customSwitch1"
                                                            type="checkbox" />
                                                    </div>
                                                </td>
                                </div>
                                </td>
                                </tr>
                                <tr>
                                    <td style="vertical-align: middle;"></td>
                                    <td style="vertical-align: middle;">
                                        <input matInput type="text" placeholder="License/Certificate"
                                            formControlName="licenceAndCertificate">
                                    </td>
                                    <td style="vertical-align: middle;">
                                        <input matInput type="text" placeholder="Document #"
                                            formControlName="documentNumber" (keyup.enter)="addDetails()">
                                    </td>
                                    <td style="vertical-align: middle;">
                                        <div class="">
                                    <td class="flex d-flex flex-row"
                                        style="justify-content: center; align-items: center; vertical-align: middle;">
                                        <div class="form-check form-switch mb-0">
                                            <input class="form-check-input text-center" id="customSwitch1"
                                                type="checkbox" />
                                        </div>

                                    </td>
                        </div>
                        </td>
                        </tr>
                        </tbody>
                        </table>
                    </div>
    </form>
</div>
</div>

</div>

<!-- <div class="row mb-3">
    <div class="col-sm-12 col-md-12">
        <div class="notes-section">
            <p class="notes-textarea text-black" style="background: #e4e3e6;">
                <b>Note:</b> Terms & condition and Signature added below will be shown on your invoices.
            </p>
        </div>
    </div>
    <div class="col-sm-12 col-md-12 ps-2">
        <div class="terms-section">

        </div>
        <label class="form-label ms-2">Terms & Conditions</label>
        <mat-form-field class="example-full-width" appearance="outline">
            <textarea matInput required rows="3" placeholder="Terms & Conditions">
                       1. Goods once sold will not be taken back or exchanged.
                    </textarea>
            <!-- 1. Goods once sold will not be taken back or exchanged. -->
        <!-- </mat-form-field>
    </div>
</div> -->

<!-- <div class="row mb-3">
    <div class="col-md-5 col-lg-5 col-sm-6">
        <div class="image-wrapper">
            <label class="signature-img" ondragover="return false;" [class.loaded]="loaded"
                (dragenter)="signatureDragEnter()" (dragleave)="signatureDragLeave()" (drop)="signatureDrop($event)">
                <i class="bi bi-plus-lg fs--1">Add Signature</i>
                <img *ngIf="signImg" [src]="signImg" (load)="signatureLoad()" [class.loaded]="imageLoaded" />
                <input #fileInput type="file" name="file" accept="image/*" (change)="signatureChange($event)" hidden>
            </label>
            <button *ngIf="signImg" class="delete-button" (click)="deleteSignature()">
                <i class="bi bi-x bi-sm"></i>
            </button>
        </div>
    </div>
</div> -->

</div>

</div>
<hr class="my-3">
<div class="mb-3" *appHasPermission="['read']; name: 'profile'">
    <form [formGroup]="addressForm">
        <label class="pb-3 form-label" style="font-size: 16px; font-weight: 500;">
            Add multiple Business Addresses
        </label>
        <div>
            <div formArrayName="addresses" *ngFor="let item of addressControls.controls; let i = index">
                <div [formGroupName]="i" class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4 ">
                        <label class="form-label ms-2">Pincode</label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input type="text" matInput 
                                (input)="getPincodeDetailsForAdditionalAddress($event.target.value,i)" maxlength="6" minlength="6"
                                minlength="5" formControlName="pincode"  (keypress)="numberOnly($event)" placeholder="Pincode" [ngClass]="{
                                    'is-invalid': (f.pincode.invalid && f.pincode.touched) && submitted }">
                        </mat-form-field>

                        <mat-error class="text-danger" *ngIf="(submitted || item.get('pincode').touched) &&  item.get('pincode').errors?.minlength">
                                Pincode should be 5 digits.
                          </mat-error>
                          
                        <!-- <mat-error class="text-danger"
                            *ngIf="(submitted || f.pincode.touched) && f.pincode.errors?.required">
                            Pincode is Required !
                        </mat-error> -->
                        <!-- <mat-error class="text-danger"
                            *ngIf="(submitted || f.pincode.touched) && f.pincode.errors?.minlength">
                            Pincode should be 5 digits.
                        </mat-error> -->
                    </div>

                    <!-- <div class="col-lg-6 col-md-12 col-sm-12"> -->
                    <div class="col-sm-12 col-md-4 col-lg-4 ">
                        <label class="form-label ms-2">City<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-select required formControlName="city" name="pintxt" id="pintxt" placeholder="city"
                                [ngClass]="{
                              'is-invalid': (f.city.invalid && f.city.touched) && submitted }">
                                <mat-option *ngFor="let city of cityArr" [value]="city">{{ city }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="(submitted || item.get('city').touched) &&  item.get('city').errors?.required">
                            City is Required.
                      </mat-error>
                    </div>

                    

                    <!-- <div class="col-sm-12 col-md-4 col-lg-4 ">

                        <label class="form-label ms-2">City<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput disableOptionCentering required type="text" ngxOnlyString
                                formControlName="city" name="pintxt" id="pintxt" placeholder="city" [ngClass]="{
                                'is-invalid': (f.city.invalid && f.city.touched) && submitted }" value="">
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.city.touched) && f.city.errors?.required">
                            city is Required.
                        </mat-error>
                    </div> -->

                    <!-- <div class="col-sm-12 col-md-4 col-lg-4 ">
                        <label class="form-label ms-2">District<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput required type="text" formControlName="district"
                                placeholder="District" [ngClass]="{
                                'is-invalid': (f.district.invalid && f.district.touched) && submitted }" required>
                        </mat-form-field>

                        <mat-error class="text-danger"
                            *ngIf="(submitted || f.district.touched) && f.district.errors?.required">
                            District is Required!
                        </mat-error>
                    </div> -->

                    <div class="col-sm-12 col-md-4 col-lg-4 ">
                        <label class="form-label ms-2">Country<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput required type="text" formControlName="country"
                                placeholder="Country" [ngClass]="{
                            'is-invalid': (f.country.invalid && f.country.touched) && submitted }">
                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="(submitted || item.get('country').touched) &&  item.get('country').errors?.required">
                            Country is Required.
                      </mat-error>

                    </div>

                    <!-- <div class="col-sm-12 col-md-4 col-lg-4 ">
                        <label class="form-label ms-2">Town/City<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput required type="text" formControlName="city"
                                placeholder="Town/City" [ngClass]="{
                            'is-invalid': (f.city.invalid && f.city.touched) && submitted }" required>
                        </mat-form-field>

                        <mat-error class="text-danger" *ngIf="(submitted || f.city.touched) && f.city.errors?.required">
                            Town/City is Required.
                        </mat-error>
                    </div> -->

                    <div class="col-sm-12 col-md-4 col-lg-4 ">
                        <label class="form-label ms-2">House #<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput required type="text" formControlName="homeno" maxlength="15"
                                placeholder="House #,Apartment,Building,Company" [ngClass]="{
                            'is-invalid': (f.homeno.invalid && f.homeno.touched) && submitted }" required>
                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="(submitted || item.get('homeno').touched) &&  item.get('homeno').errors?.required">
                            House # is Required.
                      </mat-error>
                    </div>

                    <div class="col-sm-12 col-md-4 col-lg-4 ">
                        <label class="form-label ms-2">Area, Street, Sector, Village<span
                                class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput required type="text" formControlName="address"
                                placeholder="Area" [ngClass]="{
                            'is-invalid': (f.address.invalid && f.address.touched) && submitted }" required>
                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="(submitted || item.get('address').touched) &&  item.get('address').errors?.required">
                            Area, Street, Sector, Village is Required.
                      </mat-error>
                    </div>

                    <div class="col-sm-12 col-md-4 col-lg-4 ">
                        <label class="form-label ms-2">Landmark<span class="error-asterisk">*</span></label>
                        <mat-form-field class="example-full-width" appearance="outline">
                            <input matNativeControl matInput required type="text" formControlName="landmark"
                                placeholder="Landmark" [ngClass]="{
                                'is-invalid': (f.landmark.invalid && f.landmark.touched) && submitted }" required>
                        </mat-form-field>
                        <mat-error class="text-danger" *ngIf="(submitted || item.get('landmark').touched) &&  item.get('landmark').errors?.required">
                            Landmark is Required.
                      </mat-error>
                    </div>
                </div>
                <div class="row">
                    <div class="">
                        <div class="d-flex justify-content-center">
                            <div *ngIf="item.value.id">
                                <button class="btn btn-save mb-1" type="button" style="margin-right: 10px;"
                                    *appHasPermission="['modify']; name: 'profile'"
                                    (click)="updateMultipleAddress(item)">
                                    Update
                                </button>
                            </div>
                            <div>
                                <button type="button" class="btn btn-cancel" *ngIf="item.value.id"
                                    (click)="deleteAddress(item)">
                                    -
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-end pb-4">
                <button type="button" class="btn btn-cancel" style="margin-right: 10px" (click)="addAddress()">
                    +
                </button>

                <button class="btn btn-save" type="button" *appHasPermission="['modify']; name: 'profile'"
                    (click)="addMultipleAddress()">
                    Add
                </button>
            </div>
        </div>
    </form>
</div>
</form>
</div>
