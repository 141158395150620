<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 5px;" class="modal-title mb-0">Add Promotion</h1>

    <div class="addProductButtons">
        <button type="button" style="margin-right: 10px;" class="btn btn-cancel" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-save ms-2" (click)="onSave()" *appHasPermission="['create']; name 'offers'">Save</button>
    </div>
</div>

<div class="modal-body">
    <form [formGroup]="promotionForm">
        <div class="row mb-3">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label class="form-label">Schema Name<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input matInput placeholder="Schema Name" maxlength="50" formControlName="schemaName" >
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submitted || f.schemaName.touched) && f.schemaName.errors?.required">
                Schema Name is required.
              </mat-error>
            </div>
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label class="form-label">Type<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-select formControlName="type" placeholder="Type" >
                  <mat-option *ngFor="let item of types" [value]="item">
                    {{ item }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submitted || f.type.touched) && f.type.errors?.required">
                Schema Type is required.
              </mat-error>
            </div>
        </div>         

        <div class="row mb-3" *ngIf="f.type.value === 'DISCOUNT' ">
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Discount Percentage</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Discount Percentage" maxlength="3" formControlName="discountPercentage" (keypress)="numberOnly($event)">
                    <span class="matSuffix" matSuffix>%</span>
                </mat-form-field>
                <mat-error *ngIf="f.discountPercentage.errors?.max">
                    Discount Percentage cannot exceed 100%.
                </mat-error>
            </div>
            
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Discount Amount</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Discount Amount" maxlength="10" formControlName="discountAmount" (keypress)="numberOnly($event)">
                    <span class="matSuffix" matSuffix>₹</span>
                </mat-form-field>
            </div>

            <!-- Display error for both fields having errors and at least one being touched or submitted -->
            <div *ngIf="promotionForm.hasError('atLeastOneError') && submitted" class="text-danger" style="font-size:9px;">
                Please fill at least one of Discounted Amount or Discount Percentage.
            </div>
        </div>
        
        <div class="row mb-3" *ngIf="f.type.value === 'FLAT'">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label class="form-label">Minimum Bill Amount<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <span class="matSuffix" matSuffix>₹</span>
                <input matInput placeholder="Minimum Bill Amount" maxlength="10" formControlName="minBillAmt" (keypress)="numberOnly($event)">
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submitted || f.minBillAmt.touched) && f.minBillAmt.errors?.required">
                Minimum bill amount is required.
                </mat-error>
            </div>
          
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label class="form-label">Discount Amount<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input matInput placeholder="Discount Amount" maxlength="10" formControlName="discountAmount" (keypress)="numberOnly($event)">
                <span class="matSuffix" matSuffix>₹</span>
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submitted || f.discountAmount.touched) && f.discountAmount.errors?.required">
                Discount amount is required.
                </mat-error>
            </div>
          
            <!-- Display error if Discount Amount is greater than Minimum Bill Amount -->
            <div *ngIf="promotionForm.hasError('discountInvalid') && (submitted || f.discountAmount.touched)" class="text-danger" style="font-size: 9px;">
              Discount Amount cannot be more than Minimum Bill Amount.
            </div>
          </div>          

        <div class="row mb-3" *ngIf="f.type.value !== 'FLAT'">
            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Buy Quantity<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Buy Quantity" maxlength="5" formControlName="buyQuantity" (keypress)="numberOnly($event)">
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.buyQuantity.touched) && f.buyQuantity.errors?.required">
                    Buy quantity is required.
                </mat-error>
            </div>

            <div style=" font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Get Quantity<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <div style="display: inline-block;">
                        <input matInput placeholder="Get Quantity" maxlength="5" formControlName="getQuantity" (keypress)="numberOnly($event)">
                    </div>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.getQuantity.touched) && f.getQuantity.errors?.required">
                    Get quantity is required.
                </mat-error>
            </div>
        </div>

    </form>
    <div class="row" *ngIf="f.type.value === 'PRODUCT'">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <label class="form-label">Products<span class="error-asterisk">*</span></label>
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-select matNativeControl disableOptionCentering [(ngModel)]="selectedProductIds" placeholder="Product">
                    <mat-option *ngFor="let product of productsIdList" [value]="product.id">
                        {{ product.itemName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>          
    </div>
</div>