import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { SaleInvoiceService } from 'src/app/core/services/sale-invoice.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-close-register',
  templateUrl: './close-register.component.html',
  styleUrls: ['./close-register.component.scss']
})
export class CloseRegisterComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem('currentUser'));
  public closingBalance = null;
  public openingBalance = null;
  denominationForm: FormGroup;
  denominations = [1000, 500, 200, 100, 50, 10, 5];
  totalAdd: any;
  totalSubtract: any;
  cashOpenAmount: number = 0;
  cashSale: number = 0;
  totalamount: any;
  submitted: boolean = false;
  
  constructor(private commonService: CommonService,
    private fb: FormBuilder,
    private saleInvoiceService: SaleInvoiceService,
    private toastService: ToastNotificationService,
    public router: Router,
    private authService: AuthService,
  ) { }

  async ngOnInit() {
    this.denominationForm = this.fb.group({
      denominationsArray: this.fb.array(this.denominations.map(() => this.fb.group({
        count: [0]  // Initial value for count
      })))
    });

    await this.getCheckoutData();
    await this.getDailyRegisterById();
  }

  isClosingBalance(){
    // Check if closingBalance is empty, null, or undefined
    if (this.closingBalance === null || this.closingBalance === undefined || this.closingBalance === '') {
      return true; // Return true if closingBalance is not provided or is empty
    }
    return false; // Return false if closingBalance is valid
  }

  async getDailyRegisterById(){
    const res = await this.saleInvoiceService.getDailyRegisterById({}, this.currentUser.dailysessionId).then((res: any) => {
      if (res.success) {
        this.totalamount = res.data?.totalamount;
        this.cashSale = res.data?.totalamount;
        // this.closingBalance = res.data.closingbalance;
        this.cashOpenAmount = res.data.openingbalance;
        // this.cashSale = res.data.cashsale;
      }
    }).catch((err) => {
      this.toastService.toastMsg({
        title: "Error",
        content: err.error.error_message || 'Something went Wrong.',
      });
    });
  }

  async getCheckoutData(){
    const res = await this.saleInvoiceService.getCheckoutData({}, this.currentUser.dailysessionId).then((res: any) => {
      if (res.success) {
        let totalAdd = 0;
        let totalSubtract = 0;
    
        res.data.cashdailyregisters.forEach((item: any) => {
          if (item.type === 'ADD') {
            totalAdd += item.addremoveamount;
          } else if (item.type === 'REMOVE') {
            totalSubtract += item.addremoveamount;
          }
        });
    
        this.totalAdd = totalAdd;
        this.totalSubtract = totalSubtract;
    
        console.log(this.totalAdd);
        console.log(this.totalSubtract);
      }
    }).catch((err) => {
      this.toastService.toastMsg({
        title: "Error",
        content: err?.error?.error_message || 'Something went Wrong.',
      });
    });
  }

  get denominationsArray(): FormArray {
    return this.denominationForm.get('denominationsArray') as FormArray;
  }

  getTotal(): number {
    let total = 0;
    this.denominationsArray.controls.forEach((group, index) => {
      total += this.denominations[index] * group.value.count;
    });
    return total;
  }
  
  save() {
    this.submitted = true;
    // Calculate the total from the denominations array
    const calculatedTotal = this.getTotal();
    console.log(this.isClosingBalance())
    // Assuming totalamount is a variable that holds the expected total amount
    if (calculatedTotal !== this.totalamount) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Total value does not match. Please check your entries. You have in your register " + this.totalamount + "₹",
      });

      return;  // Prevent the save operation
    } else if(this.isClosingBalance()){
      this.toastService.toastMsg({
        title: "Error",
        content: "Please add closing amount",
      });

      return;  // Prevent the save operation
    }else {
      const requestBody = {
        userId: this.currentUser.id,
        openingbalance: this.openingBalance,
        closingbalance: this.closingBalance,
        thousand: this.denominationForm.get('denominationsArray').value[0]?.count || 0, // Value for 1000's
        fivehundred: this.denominationForm.get('denominationsArray').value[1]?.count || 0, // Value for 500's
        twohundred: this.denominationForm.get('denominationsArray').value[2]?.count || 0, // Value for 200's
        hundred: this.denominationForm.get('denominationsArray').value[3]?.count || 0, // Value for 100's
        fifty: this.denominationForm.get('denominationsArray').value[4]?.count || 0, // Value for 50's
        twenty: this.denominationForm.get('denominationsArray').value[5]?.count || 0, // Value for 20's
        ten: this.denominationForm.get('denominationsArray').value[6]?.count || 0, // Value for 10's
        five: this.denominationForm.get('denominationsArray').value[7]?.count || 0, // Value for 5's
      };
    
      // Now send the request body to the backend
      this.commonService.endSession(requestBody, this.currentUser?.dailysessionId).then((res: any) => {
        if (res.success) {
          this.toastService.toastMsg({
            title: "Success",
            content: 'Session Ended Successfully!',
          });
          this.logout();
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));

          if (currentUser && currentUser.hasOwnProperty('dailysessionId')) {
            delete currentUser.dailysessionId;
          }
          localStorage.setItem('currentUser', JSON.stringify(currentUser));

          this.currentUser = currentUser;
          // this.router.navigateByUrl('/pages/sale_new');
        } else {
          console.log('Error Ending Session:', res.message);
        }
      }).catch((err) => {
        console.error('Error in Request:', err);
        this.toastService.toastMsg({
          title: "Error",
          content: err.error?.error_message,
        });
      });
    }
  }
  
  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl('/auth/role-selection');
  }
}
