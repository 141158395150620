
  <!-- UAE -->
  <div class="" style="background-color: #FFEBD2;padding:12px;">
    <div class="d-flex justify-content-end mb-3">
      <div class="customise-widget-btn" style=" margin-right: 1%">
        <!-- <button mat-raised-button class="px-2 py-1" id="customizeWidgetBtn" style="border: '1px solid #C3D3E2'" routerLink="/pages/customise-widget-modal" -->
        <button mat-raised-button class="px-2 py-1 mt-2" id="customizeWidgetBtn"
          (click)="openWidgetModal(selectedWidget)"><mat-icon>widgets</mat-icon> <span
            style="font-family: 'Poppins', sans-serif;padding-left: 8px;">Customize Your Widgets</span>
        </button>
        <ng-template #widgetModalContent let-modal>
          <div class="modal-header">
            <h2 class="modal-title mt-3">Select Widgets...</h2>
            <button type="button" class="btn-close btn btn-sm btn-circle" aria-label="Close" (click)="modal.dismiss()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-11 col-md-11 col-lg-12 mb-2">
                <div class="card mt-1" *ngFor="let widget of widgets">
                  <div class="row customise-widget-modal-row mt-1" (click)="widget.checked = !widget.checked">
                    <div class="col-sm-9 p-1">
                      <h3 class="card-title ms-3"><b>{{ widget.name }}</b></h3>
                    </div>
                    <!-- <div class="col-sm-1"></div> -->
                    <div class="col-sm-2 align-items-center ps-5">
                      <button *ngIf="widget.checked" class="close-button" mat-button matTooltip="Select">
                        <mat-icon [ngClass]="{'green-icon': widget.checked}">checked</mat-icon>
                      </button>
                      <!-- <button class="close-button" mat-button matTooltip="Close" (click)="widget.checked = false">
                      <mat-icon [ngClass]="{'red-icon': !widget.checked}">close</mat-icon>
                    </button> -->
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!-- <app-widget *ngIf="selectedWidget"></app-widget> -->
          </div>
        </ng-template>
      </div>
    </div>

    <div class="row" style="margin: 14px 0px;">
      <div class="col-sm-12 col-md-8 col-lg-8 mb-2 " *ngIf="isWidgetChecked(1)">
        <div class="card widget">
          <div class="card-body">

            <div class="row todaySaleHeader">
              <div class="flex-item saleHeading">
                <h2 class="card-title"><b style="font-family: 'Poppins', sans-serif;">Sales Activity</b></h2>
              </div>
              <div class="flex-item exportButton">
                <!-- <div class="dropdown">
                  <button mat-raised-button class="dropdown-toggle" type="button" id="filterByDropdown"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="filterMenu"
                    style="height: 35px;">
                    <mat-icon>get_app</mat-icon> Export
                  </button>

                  <mat-menu #filterMenu="matMenu">
                    <button mat-menu-item (click)="exportData('csv')">CSV</button>
                    <button mat-menu-item (click)="exportData('excel')">Excel</button>
                    <button mat-menu-item (click)="exportData('pdf')">PDF</button>
                  </mat-menu>
                </div> -->
              </div>
            </div>

            <div class="row saleSummaryBoxes mt-4">
              <div class="mb-1 col-3 p-2 d-flex justify-content-center">
                <div>
                  <div class="d-flex justify-content-center">
                    <span class="rounded-circle" style="background: #FC7643;">
                      <img src="assets/img/dashboard-party-profiles/Group 1000002808.svg">
                    </span>
                  </div>
                  <div class="d-flex justify-content-center">
                    <h2><b>{{(salesActivityData?.NOTPACKED ? salesActivityData?.NOTPACKED : 0) | number:'1.0-2' }}</b></h2>
                  </div>
                  <div class="d-flex justify-content-center">
                    <p style="font-family: 'Poppins', sans-serif;">To be Packed</p>
                  </div>
                </div>
              </div>

              <div class="mb-1 col-3 p-2 d-flex justify-content-center">
                <div>
                  <div class="d-flex justify-content-center">
                    <span class="rounded-circle" style="background: #FC7643;">
                      <img src="assets/img/dashboard-party-profiles/Group 1000002807.svg">
                    </span>
                  </div>
                  <div class="d-flex justify-content-center">
                    <h2><b>{{(salesActivityData?.PACKED ? salesActivityData?.PACKED : 0) | number:'1.0-2'}}</b></h2>
                  </div>
                  <div class="d-flex justify-content-center">
                    <p>To be Shipped</p>
                  </div>
                </div>
              </div>
              
              <div class="mb-1 col-3 p-2 d-flex justify-content-center">
                <div>
                  <div class="d-flex justify-content-center">
                    <span class="rounded-circle" style="background: #FC7643;">
                      <img src="assets/img/dashboard-party-profiles/Group 1000002806.svg">
                    </span>
                  </div>
                  <div class="d-flex justify-content-center">
                    <h2><b>{{(salesActivityData?.SHIPPED ? salesActivityData?.SHIPPED : 0) | number:'1.0-2'}}</b></h2>
                  </div>
                  <div class="d-flex justify-content-center">
                    <p>To be Delivered</p>
                  </div>
                </div>
              </div>
              
              <div class="mb-1 col-3 p-2 d-flex justify-content-center">
                <div>
                  <div class="d-flex justify-content-center">
                    <span class="rounded-circle" style="background: #FC7643;">
                      <img src="assets/img/dashboard-party-profiles/Group 1000002806.svg">
                    </span>
                  </div>
                  <div class="d-flex justify-content-center">
                    <h2><b>{{(salesActivityData?.DELIVERED ? salesActivityData?.DELIVERED : 0) | number:'1.0-2'}}</b></h2>
                  </div>
                  <div class="d-flex justify-content-center">
                    <p>Delivered</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4 mb-2" *ngIf="isWidgetChecked(2)">
        <div class="net-profit-card card widget h-lg-100">
          <div class="card-body pe-3 ps-3 py-3">
            <h2 class="card-title">
              <b style="font-family: 'Poppins', sans-serif;">Inventory Summary</b>
            </h2>
            <div class="row">
              <div class="col-md-5 col-sm-5 d-flex align-items-center justify-content-center">
                <div class="text-center">
                  <img src="assets/img/dashboard-party-profiles/net-profit.png" class="img-fluid mb-2" alt="Net Profit Image">
                  <h2 class="mb-0"><b style="font-family: 'Poppins', sans-serif;">{{(stockInHand ? stockInHand : 0) | amountFormat }}</b></h2>
                  <h4 class="mb-0" style="font-family: 'Poppins', sans-serif;"><b>Quantity In Hand</b></h4>
                </div>
                <!-- <hr class="vertical-line"> -->
              </div>
              <div class="col-md-2 d-flex align-items-center justify-content-center">
                <hr class="vertical-line">
              </div>
              <div class="col-md-5 col-sm-5 d-flex align-items-center justify-content-center">
                <div class="text-center">
                  <img src="assets/img/dashboard-party-profiles/net-sales.png" class="img-fluid mb-2" alt="Net Sales Image">
                  <h2 class="mb-0"><b style="font-family: 'Poppins', sans-serif;">₹ {{(netSales ? netSales : 0) | amountFormat }}</b></h2>
                  <h4 class="mb-0" style="font-family: 'Poppins', sans-serif;"><b>To be Received</b></h4>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row" style="margin: 14px 0px;">
      <div class="col-sm-12 col-md-6 col-lg-4 mb-2" *ngIf="isWidgetChecked(3)">
        <app-total-sales></app-total-sales>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 mb-2" *ngIf="isWidgetChecked(4)">
        <app-total-purchase></app-total-purchase>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4 ps-lg-3 mb-2" *ngIf="isWidgetChecked(5)">
        <app-stock-details></app-stock-details>
      </div>
    </div> -->

    <div class="row" style="margin: 14px 0px;">
      <div class="col-sm-12 col-md-6 col-lg-6 mb-2" *ngIf="isWidgetChecked(3)">
        <app-sales-purchase-chart></app-sales-purchase-chart>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 mb-2" *ngIf="isWidgetChecked(4)">
        <app-order-summary-chart></app-order-summary-chart>
      </div>
    </div>

    <div class="row" style="margin: 14px 0px;">
      <!-- <div class="col-sm-12 col-md-6 col-lg-6 mb-2" *ngIf="isWidgetChecked()">
        <div class="card widget">
          <div class="card-body" style="max-height: 408px; min-height: 408px;">
            <div class="row">
              <h2 class="card-title col-sm-9 col-md-9"><b style="font-family: 'Poppins', sans-serif;">Recent Contacts</b>
              </h2>
              <h5 class="col-sm-3 col-md-3 mt-2 ps-4" style="color:#96A5B8;font-size: 14px;">Sales</h5>
            </div>
            <div class="row" *ngFor="let item of recentPartyList | slice:0:5">
              <div class="col-sm-2 col-md-2 p-2 profile-pic">
                <img src="assets/img/dashboard-party-profiles/party-profile-1.png" alt="Profile" class="rounded-circle">
              </div>
              <div class="col-sm-6 col-md-6 p-2 text-left">
                <h3 class="card-title partyName" style="font-family: 'Poppins', sans-serif;">{{item.partyName}}</h3>
                <h5 class="card-text" style="font-family: 'Poppins', sans-serif;">{{item.email}}</h5>
              </div>
              <div class="col-sm-3 col-md-3" style = "display:flex;align-items: center;justify-content: flex-end;">
                <span class="green-digit p-1" style="font-family: 'Poppins', sans-serif;">₹&nbsp;{{ item.saleAmount | number:'1.2-2'}}</span>
              </div>
            </div>
            <div *ngIf="recentPartyList.length === 0"
              style="display: flex;justify-content: center;align-items: center;margin-top: 50px;">
              <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                alt="notfound1" width="200" height="150" />
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-sm-12 col-md-6 col-lg-6 mb-2" *ngIf="isWidgetChecked(5)">
        <div class="card widget">
          <div class="card-body" style="max-height: 408px; min-height: 408px; overflow-y: auto;">
            <h2 class="card-title"><b style="font-family: 'Poppins', sans-serif;">Low Stocks Products</b></h2>
            <table class="table">
              <thead>
                <tr class="stocks-col">
                  <th class="text-nowrap" scope="col" style="width: 10%">#</th>
                  <th class="text-nowrap" scope="col" style="width: 40%">Name</th>
                  <th class="text-nowrap" scope="col" style="width: 50%">Left Stock</th>
                </tr>
              </thead>
              <tbody *ngFor="let item of lowStockProductList | slice:0:5; let i = index ">
                <tr>
                  <td style="font-family: 'Poppins', sans-serif;width: 10%">{{i + 1 | number:'2.0-0'}}</td>
                  <td style="font-family: 'Poppins', sans-serif;width: 40%">{{item.itemName}}</td>
                  <td style="width: 50%">
                    <progress value="{{item.stock}}"
                      ngbTooltip="{{item.stock}} stocks are remaining out of {{item.openingStock}}"
                      max="{{item.openingStock}}"></progress>&nbsp;
                    <span class="sales-percentage p-2" style="font-family: 'Poppins', sans-serif;">{{item.stock}}</span>
                  </td>
                </tr>
                <!-- add more rows for additional products -->
              </tbody>
              <tbody *ngIf="lowStockProductList.length === 0"
                style="display: flex;justify-content: center;align-items: center; margin-left:120px;margin-top: 50px;">
                 <tr class="text-center">
                  <td colspan="2">

                    <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                      alt="notfound1" width="200" height="150" />
                     
                  </td>
                </tr> 
              </tbody>

            </table>
          </div>
        </div>

      </div>
    </div>

    <!-- <div class="row" style="margin: 14px 0px;">
      <div class="col-sm-12 col-md-6 col-lg-6 mb-2" *ngIf="isWidgetChecked(10)">
        <div class="card widget">
          <div class="card-body" style="max-height: 408px;min-height: 408px;">
            <div class="row">
              <h2 class="card-title col-sm-9 col-md-9 col-lg-9"><b style="font-family: 'Poppins', sans-serif;">Pending
                  Payment</b></h2>
              <h5 class="col-sm-3 col-md-4 col-lg-3 mt-2 ps-5" style="color:#96A5B8;font-size: 14px;">Payment</h5>
            </div>
            <div class="row" *ngFor="let item of pendingPartyList | slice:0:5">
              <div class="col-md-2 col-sm-2 p-2 profile-pic">
                <img src="assets/img/dashboard-party-profiles/party-profile-1.png" alt="Profile" class="rounded-circle">
              </div>
              <div class="col-md-7 col-sm-7 p-1 text-left">
                <h3 class="card-title partyName" style="font-family: 'Poppins', sans-serif;">{{item.partyName}}</h3>
                <h5 class="card-text" style="font-family: 'Poppins', sans-serif;">{{item.email}}</h5>
              </div>
              <div class="col-md-2 col-sm-2 p-1 px-0" style = "display:flex;align-items: center;justify-content: flex-end;">
                <span class="green-digit" style="font-family: 'Poppins', sans-serif;">₹&nbsp;{{ item.totalDebt | number:'1.2-2'}}</span>
              </div>
            </div>
            <div *ngIf="pendingPartyList.length === 0"
              style="display: flex;justify-content: center;align-items: center;margin-top: 50px;">
              <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                alt="notfound1" width="200" height="150" />
            </div>
          </div>

        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 mb-2" *ngIf="isWidgetChecked(11)">
        <div class="card widget">
          <div class="card-body" style="max-height: 48px;min-height: 408px;">
            <div class="row">
              <h2 class="card-title col-sm-8 col-md-8"><b style="font-family: 'Poppins', sans-serif;">Credit Vendor
                  Name</b></h2>
              <h5 class="col-sm-4 col-md-4 mt-2 ps-2" style="color:#96A5B8;font-size: 14px;text-align: center;">Receivable Payment</h5>
            </div>
            <div class="row" *ngFor="let item of creditorsPartyList | slice:0:5">
              <div class="col-sm-2 col-md-2 p-2 profile-pic">
                <img src="assets/img/dashboard-party-profiles/party-profile-1.png" alt="Profile" class="rounded-circle">
              </div>
              <div class="col-sm-6 col-md-6 p-2 text-left">
                <h3 class="card-title partyName" style="font-family: 'Poppins', sans-serif;">{{item.partyName}}</h3>
                <h5 class="card-text" style="font-family: 'Poppins', sans-serif;">{{item.email}}</h5>
              </div>
              <div class="col-sm-3 col-md-3 py-3" style = "display:flex;align-items: center;justify-content: flex-end;">
                <span class="green-digit" style="font-family: 'Poppins', sans-serif;">₹&nbsp;{{item.totalCredit | number:'1.2-2'}}</span>
              </div>
            </div>
            <div *ngIf="creditorsPartyList.length === 0"
              style="display: flex;justify-content: center;align-items: center;margin-top: 50px;">
              <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                alt="notfound1" width="200" height="150" />
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row" style="margin: 14px 0px;">
      <div class="col-sm-12 col-md-12 col-lg-12 mb-2" *ngIf="isWidgetChecked(6)">
        <div class="card widget">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 col-sm-5">
                <h2 class="card-title"><b style="font-family: 'Poppins', sans-serif;">Best Selling Products</b></h2>
              </div>
              <div class="col-md-2 col-sm-3" *ngIf="updatedProductList.length !== 0">
                <div class="dropdown">
                  <button mat-raised-button class="dropdown-toggle" type="button" id="filterByDropdown"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="filterMenu">
                    Filter By : {{ selectedFilter || 'All' }}
                  </button>
                  <mat-menu #filterMenu="matMenu">
                    <button mat-menu-item (click)="selectFilter('low Stock')"
                      [ngClass]="{ 'selected' : selectedFilter === 'low Stock' }">
                      Low Stock
                    </button>
                    <button mat-menu-item (click)="selectFilter('In Stock')"
                      [ngClass]="{ 'selected' : selectedFilter === 'In Stock' }">
                      In Stock
                    </button>
                    <button mat-menu-item (click)="selectFilter('out Of Stock')"
                      [ngClass]="{ 'selected' : selectedFilter === 'out Of Stock' }">
                      Out Of Stock
                    </button>
                    <button mat-menu-item (click)="openPriceRangeDialog()"
                      [ngClass]="{ 'selected' : selectedFilter === 'Price' }">
                      Price
                    </button>
                    <button mat-menu-item (click)="selectFilter('All')"
                      [ngClass]="{ 'selected' : selectedFilter === 'All' }">
                      Remove All
                    </button>
                  </mat-menu>
                </div>
              </div>
              <div class="col-md-3 col-sm-3" *ngIf="updatedProductList.length !== 0">
                <div class="dropdown">
                  <button mat-raised-button class="dropdown-toggle" type="button" id="sortByDropdown"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="sortMenu">
                    Sort By : {{ selectedSort || 'Relevance' }}
                  </button>
                  <mat-menu #sortMenu="matMenu">
                    <button mat-menu-item (click)="selectSort('Price: Low to High')">Price: Low to High</button>
                    <button mat-menu-item (click)="selectSort('Price: High to Low')">Price: High to Low</button>
                    <button mat-menu-item (click)="selectSort('Stock: Low to High')">Stock: Low to High</button>
                    <button mat-menu-item (click)="selectSort('Stock: High to Low')">Stock: High to Low</button>
                    <button mat-menu-item (click)="selectSort('Total Sales')">Total Sales</button>
                    <button mat-menu-item (click)="selectSort('Relevance')">Relevance</button>
                  </mat-menu>
                </div>
              </div>
            </div>
            <div class="table-container">
              <div *ngIf="filteredProductList.length === 0" style="color : red">{{ emptyListMessage }}</div>

              <table class="table table-responsive mt-3">
                <thead>
                  <tr style="font-family: 'Poppins', sans-serif;">
                    <th>Product ID</th>
                    <th>Image</th>
                    <th>Product Name</th>
                    <th>Total Sales</th>
                    <th>Stock</th>
                    <th>Status</th>
                    <th style = "text-align: right;width:5%;">Price</th>
                  </tr>
                </thead>
                <tbody *ngFor="let item of updatedProductList" style="font-family: 'Poppins', sans-serif;">
                  <tr style="align-items: center;">
                    <td>#{{item.itemCode}}</td>
                    <td><img src="{{item.coverImage}}" height="70px" width="90px" alt="Product Image"
                        onerror="this.src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcm9rPgL_MfNP7BIgeb8vn5Gb8J0F2Y_EAYylwRWTakA&usqp=CAU&ec=48665699'">
                    </td>
                    <td>{{item.itemName}}</td>
                    <td>{{item.totalSold}}</td>
                    <td>{{item.stock}}</td>
                    <td>
                      <button mat-flat-button
                      [ngClass]="{'status-badge': true, 'danger': item.status === 'outOfStock', 'warning': item.status === 'lowStock', 'success': item.status !== 'outOfStock' && item.status !== 'lowStock'}">
                      {{
                        item.status === 'outOfStock' ? 'Out of Stock' :
                        item.status === 'lowStock' ? 'Low Stock' :
                        item.status === 'inStock' ? 'In Stock' :
                        item.status
                      }}
                    </button>
                  </td>
                  <td style = "text-align: right;width:5%;">₹&nbsp;{{ item.salePrice | number:'1.2-2'}}</td>
                  </tr>
                </tbody>
                <tbody *ngIf="updatedProductList.length === 0"
                  style="display: flex;justify-content: center;align-items: center; margin-left: 150%; margin-top: 70px;">
                  <tr class="text-center">
                    <td colspan="12">

                      <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                        alt="notfound1" width="200" height="150" />
                      <!-- </div> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>
</div>
