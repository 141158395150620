<div class="container-fluid mb-3 mx-0 bg-white">
    <div class="row pt-3 pb-3 mt-" id="headingPrintHide">
        <div class="col-6 d-md-inline-block fs--1 mt-3 ml-5" style="font-weight:600;font-size:20px!important;">
            Item Report By Vendor
        </div>
        <div class="col-6 navOption-btns" style ="display: flex; justify-content: flex-end;">
          <button class="btn me-3" id="excelPrintHide" style="white-space: nowrap;" (click)="exportExcel()" type="button"><i class="fas fa-download"></i>
            Export</button>
          <button class="btn" id="PrintoptionHide" (click)="onPrint()" type="button"><i class="bi-printer fa-1x"></i>
          </button>
        </div>
    </div>

    <hr class="mt-1" />
    <div class="fs--1 ">
        <div class="row mx-auto">
            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-4 col-lg-3" id = "dateRangePrintHide">
                <label class="form-label">Date</label>
                <mat-form-field class="example-full-width" appearance="outline">     
                    <input  class="uppercase-date-range"tem-wise matInput placeholder="Date Range" id="Date Range" placeholder="Date Range" id="Date Range" type="text" [outsideClick]="true" ngModel
                        bsDaterangepicker [maxDate]="today" [(ngModel)]="dateRange" (ngModelChange)="changeDate($event)"
                        [bsConfig]="{ranges: ranges,showWeekNumbers:false, showNextMonth: false, rangeInputFormat : 'DD MMM YYYY', containerClass: 'theme-dark-blue', displayOneMonthRange: true }">
                    <span><mat-icon class="iconcalender p-1">date_range</mat-icon></span>
                </mat-form-field>
            </div>

            <div style="font-size: 12px;display: flex; flex-direction: column;justify-content:flex-start ;" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 mr-0" id = "searchBarHide">
                <label class="form-label">Select Vendor Name</label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="text" placeholder="Vendor Name" aria-label="FILTERS" matInput
                    (keyup)="searchPartyByName($event.target.value)"[matAutocomplete]="auto" [ngClass]="{
                            'is-invalid': (partyName.invalid && partyName.touched) && submitted }" required>
                    <mat-icon class="m-2" matSuffix>search</mat-icon>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option value="clear" (onSelectionChange)="clearSelection()">Clear Selection</mat-option>
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partyName"
                        (onSelectionChange)="setSelectedPartyDeatails(option)">
                            {{option.partyName}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
          
            <div class="table-responsive scrollbar mb-3">
                <table class="table table-bordered table-striped fs--1 mb-0" id="excel-table">
                    <thead class="" style="background-color: #F0F0F0;">
                        <tr class="text-900">
                            <th class="nowrap text-center" scope="col">Item Name</th>
                            <th class="nowrap text-center" scope="col">Sales Quantity</th>
                            <th class="nowrap text-end" scope="col">Sales Amount</th>
                            <th class="nowrap text-center" scope="col">Purchase Quantity</th>
                            <th class="nowrap text-end" scope="col">Purchase Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="btn-reveal-trigger text-left" *ngFor="let item of itemReportList;let i = index">
                            <td class="text-center">{{item.itemName}}</td>
                            <td class="text-center">{{item.saleQuantity}}</td>
                            <td class="text-end">{{item.saleAmount}}</td>
                            <td class="text-center">{{item.purchaseQuantity}}</td>
                            <td class="text-end">{{item.purchaseAmount}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="itemReportList == 0">
                        <tr class="text-center">
                            <td colspan="10">
                                <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png" alt="notfound1"  width="200" height="150">                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td class="nowrap text-center" scope="col"><strong class= "text-dark">Total</strong></td>
                            <td class="nowrap text-center" scope="col"><strong><a class="text-success" style="text-decoration: none;">{{itempartyTotal.saleQuantityTotal}} </a></strong></td>
                            <td class="nowrap text-end" scope="col"><strong>₹ <a class="text-success" style="text-decoration: none;">{{itempartyTotal.saleAmountTotal}} </a></strong></td>
                            <td class="nowrap text-center" scope="col"><strong><a class="text-success" style="text-decoration: none;">{{itempartyTotal.purchaseQuantityTotal}} </a></strong></td>
                            <td class="nowrap text-end" scope="col"><strong>₹<a class="text-success" style="text-decoration: none;"> {{itempartyTotal.purchaseAmountTotal}} </a></strong></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
  