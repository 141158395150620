import { TitleCasePipe } from "@angular/common";
import lang from "src/assets/langTranslation/language_translation"
import { ConstantPool } from "@angular/compiler";
import { isNull } from "@angular/compiler/src/output/output_ast";
import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith, tap } from "rxjs/operators";
import { CommonService } from "src/app/core/services/common.service";
import { PartyService } from "src/app/core/services/party.service";
import { ProfileService } from "src/app/core/services/profile.service";
import { RemoteShopService } from "src/app/core/services/remote-shop.service";
import { SaleInvoiceService } from "src/app/core/services/sale-invoice.service";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
import { Constants } from "src/app/_helpers/constant";
import { StateConstants } from "src/app/_helpers/state-constans";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BiddingService } from "src/app/core/services/bidding.service";
import { PromotionService } from "src/app/core/services/promotion.service";
import { ApplyCouponComponent } from "../../Invoice/sales-invoice/apply-coupon/apply-coupon.component";
import { WarehouseService } from "src/app/core/services/warehouse.service";
import { SharedDataService } from "src/app/core/services/shared-data.service";
import * as moment from "moment";
import { CashToRegisterComponent } from "../../cash-to-register/cash-to-register.component";
import { ToWords } from "to-words";
import * as numberToWords from 'number-to-words';

@Component({
  selector: 'app-cashier-sale-invoice',
  templateUrl: './cashier-sale-invoice.component.html',
  styleUrls: ['./cashier-sale-invoice.component.scss']
})
export class CashierSaleInvoiceComponent implements OnInit {

  @ViewChild("closebutton") closebutton;
  isEditMode = false;
  saveAsDraft: boolean = false;
  public lang = lang.UAE;
  public gstRate = 0;
  public gstAmount = 0;
  public Units = Constants.Units;
  public unitValue: any;
  public index: any;
  public product: any;
  public amount: any;
  public qty: any;
  public billNo: any;
  public allStates = StateConstants.states;
  public unit: any;
  public wtax: any;
  public wotax: any;
  public ramount: any;
  public formControl = new FormControl(new Date());
  public ngModelDate = new Date();
  public isShippingAddress: boolean;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public partyData: any;
  public allPartyList: any = [];
  public partyId: any;
  public today: Date;
  public selectedClient: any;
  public productData: any = [];
  public sendArray: any = [];
  public taxRate: any;
  public maxDate: Date;
  public fieldArray: Array<any> = [];
  public newAttribute: any = {};
  public SaleInvoiceForm: any;
  public subTotal = 0;
  public totalGST = 0;
  public total = 0;
  public todaysDate;
  public invoiceNo;
  public lastInvoiceNumber: string;
  public invoiceDate;
  public gstType: any;
  public gstInRs: any;
  public gstInPer: any;
  public additionalCess: number = 0;
  public myControl = new FormControl(null);
  public myControl1 = new FormControl();
  public isShown: boolean = false;
  paymentMode: string;
  // serialNumber: number = 1;

  public mask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  public filteredOptions: Observable<string[]>;
  public partyName: any;
  public compositeType: any;
  public isShowGstFields: boolean = true;
  public isShippingAddressinModal: boolean = false;
  public orderId: any;
  public orderData: any;
  public showInvoiceGenerateData: boolean = false;
  public showPrintBtn: boolean = false;
  public remoteProductData = [];
  public remoteGSTAmount: any;
  public remoteTotalBillAmount: any;
  public legalName: any;
  public homeNo: any;
  public city: string;
  public state: string;
  public district: any;
  public pincode: number;
  public country: string;
  public address: any;
  public billingAddress: any;
  public customerName: string;
  public ismodelShow: boolean = false;
  public isCashSale: boolean = true;
  public selectedCheckBox: boolean = false;
  public checkedAll: boolean = false;
  public singleChecked: boolean = false;
  public read: boolean;
  public deleteProductData: any = [];
  public isAllChecked: boolean = false;
  public totalPrice: any;
  public isDisabledGST: boolean = true;
  public barcode: any;
  public submitted: boolean = false;
  public submittedParty: boolean = false;
  public currentPageNo: number = 0;
  public partyPagesize: number = 10;
  public userProfileGSTNo: any;
  public gstStateMatch: boolean = true;
  public finalObject;
  public supplytype: any;
  public SearchParty: any;
  public paramID: any;
  PartyForm: FormGroup;
  searchedProduct: any;
  filteredOptions2: Observable<any>;
  amtToMinusFromTotal: number = 0;
  newGstRate: number = 0;
  showCounterDiscountField: boolean = false;
  allValuesZero: boolean = false;
  freeProductData: any;
  freeProductDataList: any[] = [];
  counterAmt: any;
  suggesionList: any;
  counterOn: boolean = false;
  couponApplied: boolean = false;
  appliedCoupon: any;
  warehouseList: any;
  public lastScanned: { code: string, times: number };
  private code: string = '';
  draftId: any;
  billFinalTotal: number;
  billFlatOffer: any;
  changeDue: GLfloat;
  isEstimateDraft: boolean = false;
  estimationData: any;
  displayToCustomer: boolean = false;
  showPrintPage: boolean;
  lastInvoiceId: null;
  giftCardApplied: boolean = false;
  giftCardAmtToMinusFromTotal: number;
  giftcardId: any;
  giftcardCode: any;
  private giftCardSubject = new Subject<string>();
  private giftCardSubscription: Subscription;
  isLoading: boolean;
  public invoiceData: any = [];
  public saleProductData: any = [];
  public basicDetails: any = [];
  public addressDetails: any = [];
  public salesData: any = [];
  public BusinessName: any;
  
  public dueDate: any;
  public placeOfSupply: any;
  public shippingAddress: any;
  public imageSrc: any = '';
  public imageLoaded: boolean = false;
  public billGstAmount: number;
  public totalBillAmount: number;
  public finalAmount: any;
  public wordAmount = '';
  invoiceTermsCon: string;
  public tradeName: any
  public gstinNo: any;
  public mobile: any;
  public hsn: any;
  public searchKey: any;
  public Registered: any;
  downloadData: any = [];
  toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: true,
    }
  });
  masterFinalGiftcardPrice: any;
  currencySymbol: string;
  flatDiscount: number;
  signImg: any = '';
  TextSizeprint: string = 'Medium';
  selectedColor: string | null = null;
  selectedTextSize: string = 'Medium';
  textColor: string = 'black'; // Default text color

  constructor(
    private saleInvoiceService: SaleInvoiceService,
    private formBuilder: FormBuilder,
    private partyService: PartyService,
    private biddingService: BiddingService,
    private router: Router,
    private toastService: ToastNotificationService,
    // @Inject(MAT_DIALOG_DATA) public estimationData: any,
    private commonService: CommonService,
    private profileService: ProfileService,
    private modal: NgbModal,
    private route: ActivatedRoute,
    private remoteShopService: RemoteShopService,
    private titleCasePipe: TitleCasePipe,
    private promotionService: PromotionService,
    public dialog: MatDialog,
    public warehouseService: WarehouseService,
    private sharedDataService: SharedDataService,
    private cdref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const storedColor = localStorage.getItem('selectedColor');
		if (storedColor) {
		  this.selectedColor = storedColor;
		}
    this.invoiceTermsCon = localStorage.getItem('termsAndConditions');
    // Sale invoice form
    this.SaleInvoiceForm = this.formBuilder.group({
      partyName: [null, Validators.required],
      invoiceNo: [{ value: null, disabled: this.showInvoiceGenerateData }, Validators.required],
      reverseCharge: [false],
      invoiceDate: [null, new Date(), Validators.required],
      gstIn: [null, [Validators.pattern("^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$"),
        Validators.minLength(15)]], 
      barcode: [null],
      totalBillAmount: [null],
      billGstAmount: [null],
      credit: [false, Validators.required],
      paymentMode: ["CASH", Validators.required],
      gstRate: [null],
      billingAddress: [null, Validators.required],
      shippingAddress: [null],
      ewayBillNo: [null],
      cashSale: [null],
      subcheckbox: this.formBuilder.array([]),
      shippedFrom: [null],
      counterDiscount: [0],
      amountByUser: [0],
      giftcard_code: [null],
      warehouse: [null],
      sendWhatsappMsg: [false],
      currency: [{ value: '₹', disabled: true }],
    });

    this.getAllParties();
    this.getSaleInvoiceNumber();
    this.getProfileData();
    this.getWarehouseList();
    this.todaysDate = new Date();
    this.today = new Date();
    this.newAttribute = {
      unit: "",
      ramount: "",
      wtax: "",
      wotax: "",
      index: "",
      product: "",
      gty: "",
      select: "",
    };
    this.fieldArray.push(this.newAttribute);

    // Sale invoice form
    this.PartyForm = this.formBuilder.group({
      partyName: [null, Validators.required],
      billingAddress: [null, Validators.required],
      shippingAddress: [null],
      balanceStatus: ["1"],
      isCustomer: [false],
    });

    if (this.estimationData && this.estimationData.draftData?.id) {
      this.isEstimateDraft = false;
      this.getSalesDataForDraft(this.estimationData)
    }

    if (this.estimationData && this.estimationData.item?.id) {
      this.isEstimateDraft = true;
      this.setEstimateToSaleForm(this.estimationData)
    }

    this.route.paramMap.subscribe((p) => {
      this.orderId = p.get("id");
    });
    if (this.orderId) {
      this.getCustomerProductByOrderId();
    }

    const counterDiscountControl = this.SaleInvoiceForm.get('counterDiscount');

    // Subscribe to valueChanges of counterDiscount control
    counterDiscountControl?.valueChanges.subscribe(value => {
      // const maxAllowedAmount = (this.total - (this.amtToMinusFromTotal ? this.amtToMinusFromTotal : 0) - 1);
      // const maxAllowedAmount = (this.total * this.counterAmt.percent) / 100; // Assuming 25% is the maximum allowed discount
      if (value > this.counterAmt?.calculatedAmount) {
        // If the entered value exceeds the maximum allowed, set it to the maximum
        counterDiscountControl?.setValue(this.counterAmt.calculatedAmount);
      }
    });

    // Listen to cashSale checkbox changes to update validation
    this.SaleInvoiceForm.get('cashSale').valueChanges.subscribe((isCashSale: boolean) => {
      if (isCashSale) {
        this.SaleInvoiceForm.get('billingAddress').clearValidators();
        this.SaleInvoiceForm.get('partyName').clearValidators();
        this.SaleInvoiceForm.get('amountByUser').setValidators(Validators.required);
        // amountByUserControl.setValidators([Validators.required, this.minimumAmountValidator(finalAmount)]);
      } else {
        this.SaleInvoiceForm.get('amountByUser').clearValidators();
        this.SaleInvoiceForm.get('billingAddress').setValidators(Validators.required);
        this.SaleInvoiceForm.get('partyName').setValidators(Validators.required);
      }
      this.SaleInvoiceForm.get('billingAddress').updateValueAndValidity();
      this.SaleInvoiceForm.get('amountByUser').updateValueAndValidity();
      this.SaleInvoiceForm.get('partyName').updateValueAndValidity();
    });

    // Controls that need to have their validations cleared and values set to null for "EMPLOYEE" user type
    const controlsToClearValidatorsAndNullify = [
      'sendWhatsappMsg',
      'warehouse',
      'shippedFrom',
      'ewayBillNo',
      'shippingAddress',
      'billingAddress',
      'barcode',
      'gstNo',
      'invoiceDate'
    ];

    if (this.currentUser.userType === 'EMPLOYEE') {

      controlsToClearValidatorsAndNullify.forEach(controlName => {
          const control = this.SaleInvoiceForm.get(controlName);
          if (control) {
              control.clearValidators(); // Remove validators
              control.setValue(null);    // Set value to null
              control.updateValueAndValidity(); // Update the validity state
          }
      });

      // Dynamically add the comments field for EMPLOYEE user type
      this.SaleInvoiceForm.addControl('comment', this.formBuilder.control(null));


      // Subscribe to changes in paymentMode to run calculationTotal function
      this.SaleInvoiceForm.get('paymentMode')?.valueChanges.subscribe(() => {
        this.calculationTotal();
      });

    this.giftCardSubscription = this.giftCardSubject
      .pipe(debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.isLoading = true; // Show the loader when the API call is initiated
        })).subscribe(value => {
          // Call the API with the search key
          if (value.length >= 3) {
            this.giftCardCall(value);
          } else if (value === '') {
            // this.isLoading = true; // Hide the loader if the search key is less than the threshold
            this.giftCardCall(value);
          } else {
            this.isLoading = false; // Hide the loader if the search key is less than the threshold
          }
        });
    }
  }

  giftCardCall(value){
    setTimeout(async () => {
      await this.calculationTotal();
      // const value = event.target.value;
      this.isLoading = false; // Hide the loader after the API call is completed
    }, 100);
  }

  giftcard_codeInput(event: any) {
    this.giftCardSubject.next(event.target.value);
  }

  setPaymentMode(mode: string) {
    // Update form control when button is clicked
    this.SaleInvoiceForm.get('paymentMode')?.setValue(mode);
  }

  minimumAmountValidator(totalAmount: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value < totalAmount) {
        return { minimumAmount: { requiredAmount: totalAmount, actualAmount: value } };
      }
      return null;
    };
  }

  restrictAmountInput(event: any) {
    const value = +event.target.value;
    this.calculationTotal()
    // if (value < (this.billFinalTotal - this.billFlatOffer?.discountAmount)) {
    //   event.target.value = this.billFinalTotal - this.billFlatOffer?.discountAmount;
    // }
  }

  get f() {
    return this.SaleInvoiceForm.controls;
  }

  get pf() {
    return this.PartyForm.controls;
  }

  get formArr() {
    return this.SaleInvoiceForm.get("subcheckbox") as FormArray;
  }

  // to listen for events on the host element
  // The window:keypress event is listened for, is triggered when a key is pressed on the keyboard
  @HostListener('window:keypress', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    // checks if the pressed key is the Enter key
    if (event.key === 'Enter') {
      // If it is, the method processes the scanned code.

      // checks if the last scanned code is the same as the current code
      if (this.lastScanned?.code === this.code) {
        // If it is, it increments the times property of the lastScanned object
        this.lastScanned.times++;
      } else {
        // f not, it creates a new lastScanned object with the current code and sets times to 1.
        this.lastScanned = {
          code: this.code,
          times: 1
        };
      }

      // calls the onScan method, passing the scanned code as an argument, and resets the code property to an empty string.
      this.onScan(this.code);
      this.code = '';
    } else {
      // If not, it appends the pressed key to the code property.
      this.code += event.key;
    }
  }

  onScan(value: string) {
    const dataArray = value.split('|');
    // Assuming the barcode is at a specific position in the array (for example, position 9)
    const barcode = dataArray[0]; // This should correspond to the barcode in the data

    // Optionally, update the input field with the scanned barcode
    this.myControl1.patchValue(barcode);
    // Now, call the getGoodsByBarcodeSelected function with the scanned barcode
    this.getGoodsByBarcodeSelected(barcode, { isUserInput: true });
  }

  addDetailsButtonClick(i): void {
    const content = this.SaleInvoiceForm.get("subcheckbox") as FormArray;
    content.push(this.addRolesPermission(i));
  }

  /**
   * Method for creating foemcontrol for each row in role permission table
   * @param id
   * @returns
   */
  addRolesPermission(id): FormGroup {
    return new FormGroup({
      read: new FormControl(false),
    });
  }

  getSalesDataForDraft(estimationData: any) {
    this.saleInvoiceService.getSaleInvoiceById({}, this.estimationData.draftData.id).then((saleInvoiceData: any) => {
      if (saleInvoiceData.success) {
        for (let index = 0; index < saleInvoiceData.data.sales_products.length; index++) {
          const element = saleInvoiceData.data.sales_products[index];
          this.productData.push({
            basePrice: element.pricePerUnit,
            salePrice: element.totalPrice,
            gstRate: element.gst,
            ...element
          })
        }

        this.isCashSaleToggle(estimationData.draftData?.cashSale)

        this.myControl.patchValue(estimationData.draftData.customerName);
        this.isCashSale = estimationData.draftData?.cashSale
        this.draftId = estimationData.draftData?.id

        this.SaleInvoiceForm.patchValue({
          // gstNo: saleInvoiceData.data.party.gstIn,
          // billingAddress: saleInvoiceData.data.party.billingAddress,
          // shippedFrom: saleInvoiceData.data.party.shippingAddress,
          // ewayBillNo: saleInvoiceData.data.party.ewayBillNo,
          credit: estimationData.draftData.credit ? estimationData.draftData.credit : false,
          paymentMode: saleInvoiceData.data?.paymentMode ? saleInvoiceData.data?.paymentMode : 'CASH',
          // cashSale: saleInvoiceData.data.cashSale ? "true" : "false",
          // reverseCharge: saleInvoiceData.data.reverseCharge ? true : false,
          invoiceNo: estimationData.draftData?.invoiceNo,
          invoiceDate: moment(estimationData.draftData.invoiceDate).format("YYYY-MM-DD"),
          // credit: estimationData.draftData?.credit,
          cashSale: estimationData.draftData?.cashSale,
          billingAddress: estimationData.draftData?.billingAddress,
          shippingAddress: estimationData.draftData?.shippingAddress,
          ewayBillNo: estimationData.draftData?.ewayBillNo,
          reverseCharge: estimationData.draftData?.reverseCharge ? estimationData.draftData?.reverseCharge : false,
          counterDiscount: estimationData.draftData?.counterDiscountAmount,
          sendWhatsappMsg: estimationData.draftData?.isWhatsapp || false,
          gstNo: estimationData.draftData?.party?.gstIn,

        });


        if (this.estimationData && this.estimationData.item && this.estimationData.item.vendor) {
          this.myControl.patchValue(this.estimationData.item.vendor.partyName);
        }

        // this.myControl.patchValue(this.estimationData.item.party.partyName)
        this.setSelectedPartyDeatails({
          id: saleInvoiceData?.data?.partyId,
          partyName: saleInvoiceData?.data?.party?.partyName
        })
        for (let i = 0; i <= this.productData.length; i++) {
          this.addDetailsButtonClick(i);
        }
        this.calculationTotal();
      }
    });
  }

  setEstimateToSaleForm(estimationData: any) {
    this.saleInvoiceService.getSaleInvoiceById({}, estimationData.item.id).then((saleInvoiceData: any) => {
      if(saleInvoiceData.success){
        for (let index = 0; index < saleInvoiceData.data.sales_products.length; index++) {
          const element = saleInvoiceData.data.sales_products[index];
          this.productData.push({
            basePrice: element.pricePerUnit,
            salePrice: element.totalPrice,
            gstRate: element.gst,
            ...element
          })
        }
        // this.isCashSaleToggle(saleInvoiceData.data?.cashSale || false)
        this.myControl.patchValue(saleInvoiceData.data.customerName);
        // this.isCashSale = saleInvoiceData.data?.cashSale || false

        this.SaleInvoiceForm.patchValue({
          gstNo: saleInvoiceData.data.party?.gstIn,
          billingAddress:  saleInvoiceData.data.party?.billingAddress,
          shippedFrom:  saleInvoiceData.data.party?.shippingAddress,
          ewayBillNo : saleInvoiceData.data.party?.ewayBillNo,
          credit : saleInvoiceData.data.credit ? saleInvoiceData.data.credit : false,
          paymentMode : saleInvoiceData.data?.paymentMode ? saleInvoiceData.data?.paymentMode : "CASH",
          cashSale : saleInvoiceData.data.cashSale ? true : false,
          reverseCharge : saleInvoiceData.data.reverseCharge ? saleInvoiceData.data.reverseCharge : false,
        });

        this.myControl.patchValue(saleInvoiceData.data.party.partyName)
        this.setSelectedPartyDeatails({
          id: saleInvoiceData.data.partyId,
          partyName: saleInvoiceData.data.party.partyName
        })

        for (let i = 0; i <= this.productData.length; i++) {
          this.addDetailsButtonClick(i);
        }
        this.SaleInvoiceForm.get('sendWhatsappMsg').setValue(false); // setting bydefault false in sendWhatsappMsg cause it will be not shown to user
        this.calculationTotal();
      }
    });
  }

  getWarehouseList() {
    this.warehouseService.getAllWarehouse({
      data: {},
      userId: this.currentUser.userType !== 'CLIENT' ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search: '',
    }).then((res: any) => {
      if (res.success) {
        this.warehouseList = res.data.pageData;
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }

  getProfileData() {
    this.profileService.getUserProfile({}, this.currentUser.id).then((res) => {
      if (res.success) {
        this.gstType = res.data.additional.gstType;
        this.compositeType = res.data.additional.compositeType;
        this.legalName = res.data.additional.legalName;
        this.homeNo = res.data.primaryAddress.homeno;
        this.city = res.data.primaryAddress.city;
        this.state = res.data.primaryAddress.state;
        this.district = res.data.primaryAddress.district;
        this.country = res.data.primaryAddress.country;
        this.pincode = res.data.primaryAddress.pincode;
        this.address = res.data.primaryAddress.address;
        this.userProfileGSTNo = res.data.additional.gstinNo
          ? res.data.additional.gstinNo.slice(0, 2)
          : "";
      }
    });
  }

  /**
   * Filter customer name search
   * @param name s
   * @returns
   */
  private filterNames(name: string): string[] {
    return this.allPartyList.filter(
      (partyList) =>
        partyList.partyName.toLowerCase().indexOf(name.toLowerCase()) === 0
    );
  }

  getUnit(value: string) {
    this.unitValue = "1 " + value;
  }

  addFieldValue() {
    this.fieldArray.push(this.newAttribute);
    this.newAttribute = {};
  }

  deleteFieldValue(index: number) {
    this.fieldArray.splice(index, 1);
  }

  showShippingAddress(checked: boolean) {
    this.isShippingAddress = checked;
  }

  showShippingAddressinModal(checked: boolean) {
    this.isShippingAddressinModal = checked;
  }

  /**
   * get all party name
   */
  getAllParties() {
    this.partyService
      .getAllPartiesDetails(
        {},
        this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
        this.partyPagesize,
        this.currentPageNo + 1
      )
      .then((res) => {
        this.allPartyList = res.data.pageData;
        if (this.allPartyList) {
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(""),
            map((name) =>
              name ? this.filterNames(name) : this.allPartyList.slice()
            )
          );
        }
      });
  }

  validateTRN(TRN) {
    if (TRN.length === 15) {
      this.isDisabledGST = false;
      return true
    }
  }

  /**
   * get party by name
   * @param item
   */
  searchPartyByName(item) {
    this.saleInvoiceService
      .getPartyByName({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, item)
      .then((response: any) => {
        this.partyData = response.data.rows;
        this.allPartyList = response.data.rows;
        if (this.allPartyList) {
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(""),
            map((name) =>
              name ? this.filterNames(name) : this.allPartyList.slice()
            )
          );
        }
      });
  }

  setSelectedPartyDeatails(item) {
    this.partyId = item.id;
    if (this.partyData) {
      this.partyData.forEach((ele) => {
        this.SaleInvoiceForm.patchValue({
          gstNo: ele.gstIn,
          billingAddress: ele.billingAddress,
          partyName : ele.partyName
        });
        if (
          this.SaleInvoiceForm.controls.gstNo.value.slice(0, 2) ==
          this.userProfileGSTNo
        ) {
          this.gstStateMatch = true;
          this.supplytype = "INTRASTATE";
        } else {
          this.gstStateMatch = false;
          this.supplytype = "INTERSTATE";
        }
      });
      this.calculationTotal();
    } else {
      this.saleInvoiceService
        .getPartyByName({}, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id, item.partyName)
        .then((response: any) => {
          this.partyData = response.data.rows;
          this.allPartyList = response.data.rows;
          this.allPartyList.forEach((ele) => {
            this.SaleInvoiceForm.patchValue({
              gstNo: ele.gstIn,
              billingAddress: ele.billingAddress,
              partyName : ele.partyName
            });
            if (this.SaleInvoiceForm.controls.gstNo.value && this.SaleInvoiceForm.controls.gstNo.value.slice(0, 2) == this.userProfileGSTNo) {
              this.gstStateMatch = true;
              this.supplytype = "INTRASTATE";
            } else {
              this.gstStateMatch = false;
              this.supplytype = "INTERSTATE";
            }
            this.calculationTotal();
          });
        });
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(""),
        map((name) =>
          name ? this.filterNames(name) : this.allPartyList.slice()
        )
      );
    }
  }

  /**
   * calculation total for product
   */
  async calculationTotal() {
    this.barcode = "";
    let price = 0;
    let additionalCess = 0;
    let gstInPer = 0;
    this.totalPrice = 0;
    this.subTotal = 0;
    this.gstRate = 0;
    this.amtToMinusFromTotal = 0;
    this.newGstRate = 0;
    this.productData.forEach((element) => {
      let gstInPer = element.gstRate;
      price = price + element.salePrice * element.quantity;
      this.gstRate = (element.salePrice - element.salePrice / (1 + gstInPer / 100)) * element.quantity + this.gstRate;
      this.gstAmount = (element.salePrice - element.salePrice / (1 + gstInPer / 100)) * element.quantity;
      additionalCess = (element.salePrice * element.additionalCess) / 100;
      this.subTotal = element.basePrice * element.quantity + this.subTotal;
      element.subTotal = element.basePrice * element.quantity;
      element.gstAmount =
        (element.salePrice - element.salePrice / (1 + gstInPer / 100)) *
        element.quantity;
      element.total = element.salePrice * element.quantity;
      gstInPer = element.gstRate;
      // new gst rate and amount to Minus from total amounnt for discounted
      if (element.isFreeProduct) {
        // element.quantity = (element.quantity > 1) ? (element.quantity - element.freeProductQuantity) : element.quantity;
        this.subTotal = this.subTotal - element.basePrice * element.freeProductQuantity;
        this.gstRate = this.gstRate - element.freeProductTotalGst;
        price = price - element.freeProductTotalPrice;
        this.total = this.total - element.freeProductTotalPrice;
      }

      if (element.offerData) {
        this.amtToMinusFromTotal = (element.total - element?.offerData.totalDiscountedPrice) + this.amtToMinusFromTotal;
        this.newGstRate = element.gstAmount - (element.offerData.originalGst + this.newGstRate);
      }

      if (this.isShown) {
        this.totalPrice = price;
      } else {
        this.totalPrice = price;
      }
    });
    if (this.gstType === "Exempted") {
      this.totalGST = 0;
      this.gstInRs = 0;
      this.gstInPer = 0;
    } else if (this.gstType === "Composite") {
      if (
        this.compositeType == "Manufacturer" ||
        this.compositeType == "Trader"
      ) {
        this.totalGST = (this.subTotal * 1) / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else if (this.compositeType == "Restaurant") {
        this.totalGST = (this.subTotal * 5) / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      } else {
        this.totalGST = (this.subTotal * 6) / 100;
        this.gstInRs = 0;
        this.gstInPer = 0;
      }
    } else {
      this.totalGST = this.gstRate;
      this.gstInRs = this.gstRate;
      this.gstInPer = gstInPer;
    }

    this.additionalCess = additionalCess;
    if (this.isShown) {
      this.total = price;
    } else {
      this.total = price;
    }

    this.cdref.detectChanges()
    this.calculateFinalTotal()
  }

  /**
   * get goods by barcode
   */
  // getGoodsByBarcode() {
  //   this.saleInvoiceService
  //     .getGoodsByBarcode({}, this.barcode, this.currentUser.id)
  //     .then((response) => {
  //       response.data.quantity = 1;
  //       // Check if the barcode already exists in productData
  //       const existingProductIndex = this.productData.findIndex(
  //         (product) => product.barcode === response.data.barcode
  //       );

  //       if (existingProductIndex !== -1) {
  //         // Barcode exists, update quantity
  //         this.productData[existingProductIndex].quantity += 1;
  //       } else {
  //         // Barcode doesn't exist, add new product
  //         response.data.quantity = 1;
  //         this.productData.push(response.data);
  //         this.addDetailsButtonClick(this.productData.length - 1);
  //       }

  //       this.calculationTotal();
  //     });
  // }

  onSearchInputChange(searchKey: string) {
    if (searchKey.length >= 3) {
      this.getGoodsList(searchKey);
    }
  }

  getGoodsList(value) {
    if (value && (String(value).trim() !== '' || value !== null)) {
      this.biddingService.getAllProductsByUserSearch({}, this.SaleInvoiceForm.value.warehouse ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id, value, 1000, 1, this.SaleInvoiceForm.value.warehouse ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id)
        .then((res) => {
          if (res.success) {
            this.searchedProduct = res.data.pageData;
            this.filteredOptions2 = this.myControl1.valueChanges
              .pipe(
                startWith(''),
                map(name => name ? this.filterNames(name) : this.searchedProduct.slice())
              );
          }
        },
          (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went Wrong.",
              });
            }

          })
    } else {

    }
  }

  getGoodsByBarcodeSelected(barcode, event) {
    if (event.isUserInput) {
      this.saleInvoiceService
        .getGoodsByBarcode({}, barcode, this.SaleInvoiceForm.value.warehouse ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id, this.SaleInvoiceForm.value.warehouse ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id)
        .then(async (response) => {
          response.data.quantity = 1;
          // Check if the barcode already exists in productData
          const existingProductIndex = this.productData.findIndex(
            (product) => product.barcode === response.data.barcode
          );

          if (existingProductIndex !== -1) {
            // Barcode exists, update quantity
            this.productData[existingProductIndex].quantity += 1;
            await this.checkFreeProducts(this.productData)
            await this.checkOffer(this.productData[existingProductIndex].quantity, this.productData[existingProductIndex].id)
            this.removeCoupon()
            this.removeCounter()
          } else {
            // Barcode doesn't exist, add new product
            response.data.quantity = 1;
            this.productData.push(response.data);
            this.addDetailsButtonClick(this.productData.length - 1);
            await this.checkFreeProducts(this.productData)
            await this.checkOffer(response.data.quantity, response.data.id)
            this.removeCoupon()
            this.removeCounter()
          }


          this.showCounterDiscountField = this.productData.length > 0;
          // this.calculationTotal();
          this.myControl1.patchValue('')
          document.getElementById('inp12').nodeValue = ''
        }, (err) => {
          if (err.error.expose) {
            this.toastService.toastMsg({
              title: "Error",
              content: this.titleCasePipe.transform(err.error.error_message),
            });
          }
          else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        });
    }
  }

  /**
   * calculation for quantity change
   * @param i
   */
  changeQtyCalculation(i) {
    this.productData[i].quantity;
    this.calculationTotal();
  }

  setSaveAsDraft() {
    this.saveAsDraft = true;
    this.submitted = true;
    if (this.SaleInvoiceForm.invalid && this.myControl.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    } else {
      if (this.productData.length < 1) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Add At Least One Product To Proceed!!!",
        });
        return false;
      }

      this.productData.forEach((e) => {
        let data = {
          quantity: e.quantity,
          barcode: e.barcode,
        };
        this.sendArray.push(data);
      });
      let data = {}
      const userType = this.currentUser.userType.toLowerCase();
      const warehouseIdToSend =
        userType === 'warehouse' ? this.currentUser.id :
          (userType === 'shop' ? (this.SaleInvoiceForm.value.warehouse || this.currentUser.id) :
            ((userType === 'client' && this.SaleInvoiceForm.value.warehouse !== null) ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id));
      if (this.isCashSale) {
        let finalTPRICE = this.billFinalTotal

        if( this.billFlatOffer?.discountAmount){
          finalTPRICE = finalTPRICE - this.billFlatOffer?.discountAmount
        }

        data = {
          invoiceDetails: {
            cashSale: this.isCashSale,
            invoiceNo: this.SaleInvoiceForm.controls.invoiceNo.value.toString(),
            invoiceDate: this.todaysDate,
            credit: this.SaleInvoiceForm.controls.credit.value,
            transactionTypeNew: this.SaleInvoiceForm.controls.paymentMode.value,
            roundOff: false,
            userId: this.SaleInvoiceForm.value.warehouse ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id,
            lastInvoiceNumber: String(this.lastInvoiceNumber),
            reverseCharge: this.SaleInvoiceForm.controls.reverseCharge.value ? this.SaleInvoiceForm.controls.reverseCharge.value : false,
            rcm: this.SaleInvoiceForm.controls.reverseCharge.value ? this.SaleInvoiceForm.controls.reverseCharge.value : false,
            supplyType: this.supplytype,
            placeOfSupply: this.SaleInvoiceForm.controls.shippedFrom.value ? this.SaleInvoiceForm.controls.shippedFrom.value : null,
            counterDiscountAmount: this.SaleInvoiceForm.value.counterDiscount ? this.SaleInvoiceForm.value.counterDiscount : 0,
            couponId: this.appliedCoupon?.coupon_id,
            warehouseId: `${warehouseIdToSend}`,
            isWhatsapp: (this.currentUser.userType === 'EMPLOYEE' ? null : this.SaleInvoiceForm.value.sendWhatsappMsg) || false,
            isDraft: true,
            masterFinalPrice: finalTPRICE,
            masterFinalGSTPrice: this.gstRate,
            masterFinalGiftcardPrice: this.giftCardAmtToMinusFromTotal || 0,
            giftcardId: this.giftcardId || null,
            giftcardCode: this.giftcardCode || null,
            currency: this.SaleInvoiceForm.controls.currency.value ? this.SaleInvoiceForm.controls.currency.value : '₹',
          },
          products: this.sendArray,
        };

        if (this.currentUser.userType === 'EMPLOYEE') {
          data['invoiceDetails']['comment'] = this.SaleInvoiceForm.controls.comment?.value || null;
        }
      } else {
        let finalTPRICE = this.billFinalTotal

        if( this.billFlatOffer?.discountAmount){
          finalTPRICE = finalTPRICE - this.billFlatOffer?.discountAmount
        }

        data = {
          invoiceDetails: {
            cashSale: this.isCashSale,
            partyName: this.isCashSale ? null : this.partyName,
            invoiceNo: this.SaleInvoiceForm.controls.invoiceNo.value.toString(),
            invoiceDate: this.todaysDate,
            credit: this.SaleInvoiceForm.controls.credit.value,
            transactionTypeNew: this.SaleInvoiceForm.controls.paymentMode.value,
            roundOff: false,
            userId: this.SaleInvoiceForm.value.warehouse ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id,
            partyId: this.isCashSale ? null : this.partyId,
            lastInvoiceNumber: String(this.lastInvoiceNumber),
            billingAddress: this.isCashSale ? null : this.SaleInvoiceForm.controls.billingAddress.value,
            shippingAddress: this.isCashSale ? null : this.SaleInvoiceForm.controls.shippingAddress.value,
            ewayBillNo: this.isCashSale ? null : this.SaleInvoiceForm.controls.ewayBillNo.value ? this.SaleInvoiceForm.controls.ewayBillNo.value : null,
            mobile: this.isCashSale ? null : this.partyData[0].mobile,
            customerName: this.isCashSale ? null : this.partyData[0].partyName,
            reverseCharge: this.SaleInvoiceForm.controls.reverseCharge.value ? this.SaleInvoiceForm.controls.reverseCharge.value : false,
            rcm: this.SaleInvoiceForm.controls.reverseCharge.value ? this.SaleInvoiceForm.controls.reverseCharge.value : false,
            supplyType: this.supplytype,
            placeOfSupply: this.SaleInvoiceForm.controls.shippedFrom.value ? this.SaleInvoiceForm.controls.shippedFrom.value : null,
            counterDiscountAmount: this.SaleInvoiceForm.value.counterDiscount ? this.SaleInvoiceForm.value.counterDiscount : 0,
            couponId: this.appliedCoupon?.coupon_id,
            warehouseId: `${warehouseIdToSend}`,
            isWhatsapp: (this.currentUser.userType === 'EMPLOYEE' ? null : this.SaleInvoiceForm.value.sendWhatsappMsg) || false,
            isDraft: true,
            masterFinalPrice: finalTPRICE,
            masterFinalGSTPrice: this.gstRate,
            masterFinalGiftcardPrice: this.giftCardAmtToMinusFromTotal || 0,
            giftcardId: this.giftcardId || null,
            giftcardCode: this.giftcardCode || null,
            currency: this.SaleInvoiceForm.controls.currency.value ? this.SaleInvoiceForm.controls.currency.value : '₹',
          },
          products: this.sendArray,
        };

        if (this.currentUser.userType === 'EMPLOYEE') {
          data['invoiceDetails']['comment'] = this.SaleInvoiceForm.controls.comment?.value || null;
        }
      }
      this.saleInvoiceService.postSaleInvoice(data).then(
        (res) => {
          if (res.success) {
            this.sendArray = [];
            this.toastService.toastMsg({
              title: "Success",
              content: "Sale Invoice Drafted Successfully!",
            });
            this.submitted = false;
            this.saveAsDraft = false;
            this.commonService.notifyDataAdded();
            this.router.navigate(['/pages/sale_new/sale_draft']);
            // this.dialogRef.close();
          }
        },
        (err) => {
          this.sendArray = [];
          if (err.error.expose) {
            let errorMessage = err.error.error_message;
            if (errorMessage.includes('invoiceDetails.partyId')) {
              errorMessage = "Vendor Name  is required. Please select a valid Vendor Name option.";
            } else {
              errorMessage = this.titleCasePipe.transform(errorMessage);
            }
            this.toastService.toastMsg({
              title: "Error",
              content: errorMessage,
            });
          } else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        }
      );
    }
  }

  postSaleInvoice() {
    this.submitted = true;
    // if (this.SaleInvoiceForm.invalid && this.myControl.invalid) {
    if (this.SaleInvoiceForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    } else {
      if (this.productData.length < 1) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Add At Least One Product To Proceed!!!",
        });
        return false;
      }

      this.productData.forEach((e) => {
        let data = {
          quantity: e.quantity,
          barcode: e.barcode,
        };
        this.sendArray.push(data);
      });
      let data = {}
      const userType = this.currentUser.userType.toLowerCase();
      const warehouseIdToSend =
        userType === 'warehouse' ? this.currentUser.id :
          (userType === 'shop' ? (this.SaleInvoiceForm.value.warehouse || this.currentUser.id) :
            ((userType === 'client' && this.SaleInvoiceForm.value.warehouse !== null) ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id));
      if (this.isCashSale) {
        let finalTPRICE = this.billFinalTotal

        if( this.billFlatOffer?.discountAmount){
          finalTPRICE = finalTPRICE - this.billFlatOffer?.discountAmount
        }

        data = {
          invoiceDetails: {
            cashSale: this.isCashSale,
            partyName: this.isCashSale ? null : this.partyName,
            invoiceNo: this.SaleInvoiceForm.controls.invoiceNo.value.toString(),
            invoiceDate: this.todaysDate,
            credit: this.SaleInvoiceForm.controls.credit.value,
            transactionTypeNew: this.SaleInvoiceForm.controls.paymentMode.value,
            roundOff: false,
            userId: this.SaleInvoiceForm.value.warehouse ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id,
            lastInvoiceNumber: String(this.lastInvoiceNumber),
            reverseCharge: this.SaleInvoiceForm.controls.reverseCharge.value ? this.SaleInvoiceForm.controls.reverseCharge.value : false,
            rcm: this.SaleInvoiceForm.controls.reverseCharge.value ? this.SaleInvoiceForm.controls.reverseCharge.value : false,
            supplyType: this.supplytype,
            placeOfSupply: this.SaleInvoiceForm.controls.shippedFrom.value ? this.SaleInvoiceForm.controls.shippedFrom.value : null,
            counterDiscountAmount: this.SaleInvoiceForm.value.counterDiscount ? this.SaleInvoiceForm.value.counterDiscount : 0,
            couponId: this.appliedCoupon?.coupon_id,
            billingAddress: this.SaleInvoiceForm.controls.billingAddress.value,
            warehouseId: `${warehouseIdToSend}`,
            isWhatsapp: (this.currentUser.userType === 'EMPLOYEE' ? null : this.SaleInvoiceForm.value.sendWhatsappMsg) || false,
            isDraft: false,
            draftId: this.draftId ? this.draftId : null,
            masterFinalPrice: finalTPRICE,
            masterFinalGSTPrice: this.gstRate,
            masterFinalGiftcardPrice: this.giftCardAmtToMinusFromTotal || 0,
            giftcardId: this.giftcardId || null,
            giftcardCode: this.giftcardCode || null,
            currency: '₹',
          },
          products: this.sendArray,
        };

        if (this.currentUser.userType === 'EMPLOYEE') {
          data['invoiceDetails']['comment'] = this.SaleInvoiceForm.controls.comment?.value || null;
        }
      } else {

        let finalTPRICE = this.billFinalTotal

          if( this.billFlatOffer?.discountAmount){
            finalTPRICE = finalTPRICE - this.billFlatOffer?.discountAmount
          }

        data = {

          invoiceDetails: {
            cashSale: this.isCashSale,
            partyName: this.isCashSale ? null : this.partyName,
            invoiceNo: this.SaleInvoiceForm.controls.invoiceNo.value.toString(),
            invoiceDate: this.todaysDate,
            credit: this.SaleInvoiceForm.controls.credit.value,
            transactionTypeNew: this.SaleInvoiceForm.controls.paymentMode.value,
            roundOff: false,
            userId: this.SaleInvoiceForm.value.warehouse ? this.SaleInvoiceForm.value.warehouse : this.currentUser.id,
            partyId: this.isCashSale ? null : this.partyId,
            lastInvoiceNumber: String(this.lastInvoiceNumber),
            billingAddress: this.SaleInvoiceForm.controls.billingAddress.value ? this.SaleInvoiceForm.controls.billingAddress.value : null,
            // billingAddress: this.isCashSale ? null : this.SaleInvoiceForm.controls.billingAddress.value,
            shippingAddress: this.isCashSale ? null : this.SaleInvoiceForm.controls.shippingAddress.value,
            ewayBillNo: this.isCashSale ? null : this.SaleInvoiceForm.controls.ewayBillNo.value ? this.SaleInvoiceForm.controls.ewayBillNo.value : null,
            mobile: this.isCashSale ? null : this.partyData[0].mobile,
            customerName: this.isCashSale ? null : this.partyData[0].partyName,
            reverseCharge: this.SaleInvoiceForm.controls.reverseCharge.value ? this.SaleInvoiceForm.controls.reverseCharge.value : false,
            rcm: this.SaleInvoiceForm.controls.reverseCharge.value ? this.SaleInvoiceForm.controls.reverseCharge.value : false,
            supplyType: this.supplytype,
            placeOfSupply: this.SaleInvoiceForm.controls.shippedFrom.value ? this.SaleInvoiceForm.controls.shippedFrom.value : null,
            couponId: this.appliedCoupon?.coupon_id,
            warehouseId: `${warehouseIdToSend}`,
            isWhatsapp: (this.currentUser.userType === 'EMPLOYEE' ? null : this.SaleInvoiceForm.value.sendWhatsappMsg) || false,
            isDraft: false,
            draftId: this.draftId ? this.draftId : null,

            counterDiscountAmount: this.SaleInvoiceForm.value.counterDiscount ? this.SaleInvoiceForm.value.counterDiscount : 0,
            flatDiscount: this.billFlatOffer?.discountAmount,
            couponDiscountAmount: this.appliedCoupon?.discount_amount,
            couponName: this.appliedCoupon?.coupon_name,

            masterFinalPrice: finalTPRICE,
            masterFinalGSTPrice: this.gstRate,
            masterFinalGiftcardPrice: this.giftCardAmtToMinusFromTotal || 0,
            giftcardId: this.giftcardId || null,
            giftcardCode: this.giftcardCode || null,
            currency: '₹',
          },
          products: this.sendArray,
        };

        if (this.currentUser.userType === 'EMPLOYEE') {
          data['invoiceDetails']['comment'] = this.SaleInvoiceForm.controls.comment?.value || null;
        }
      }
      this.saleInvoiceService.postSaleInvoice(data).then(
        (res) => {
          if (res.success) {
            this.sendArray = [];
            // this.dialogRef.close();
            this.saleInvoiceService.notifySaleInvoiceAdded();
            this.commonService.notifyDataAdded();
            this.toastService.toastMsg({
              title: "Success",
              content: "Sale Invoice Added Successfully!",
            });
            this.onCancel();
          }
        },
        (err) => {
          this.sendArray = [];
          if (err.error.expose) {
            let errorMessage = err.error.error_message;
            if (errorMessage.includes('invoiceDetails.partyId')) {
              errorMessage = "Vendor Name  is required. Please select a valid Vendor Name option.";
            } else {
              errorMessage = this.titleCasePipe.transform(errorMessage);
            }
            this.toastService.toastMsg({
              title: "Error",
              content: errorMessage,
            });
          } else {
            this.toastService.toastMsg({
              title: "Error",
              content: "Something Went Wrong.",
            });
          }
        }
      );
    }
  }

  getSaleInvoiceNumber() {
    this.saleInvoiceService
      .getSaleInvoiceNumber({}, this.currentUser.id)
      .then((res) => {
        if (res.success) {
          this.SaleInvoiceForm.patchValue({
            invoiceNo: String(res.data.prefix).concat(String(res.data.suffix)),
          });
          this.lastInvoiceNumber = res.data.suffix;
        }
      });
  }

  toggleShow() {
    this.isShown = !this.isShown;
    if (this.isShown) {
      this.total = this.subTotal + this.totalGST + this.additionalCess;
    } else {
      this.total = this.subTotal + this.totalGST;
    }
  }

  /**
   * get gstin
   * @param gstin
   */
  getGstNumber(gstin) {
    this.partyService.getGstNumber({}, gstin).then(
      (res) => {
        if (res.success) {
          this.PartyForm.patchValue({
            gstType: res.data.taxPayerType,
            gstNo: res.data.gstIn,
          });
        }
        this.isDisabledGST = false;
      },
      (err) => {
        this.isDisabledGST = true;
      }
    );
  }

  get fp() {
    return this.PartyForm.controls;
  }
  /**
   * Post party details api call
   */
  // addNewParty() {
  //   console.log('This.partyForm',this.PartyForm)
  //   console.log('PartyForm Valid:', this.PartyForm.valid);
  //   console.log('PartyForm Controls:', this.PartyForm.controls);
  //   if (this.PartyForm.invalid) {
  //     this.toastService.toastMsg({
  //       title: "Error",
  //       content: "Fill All Required Fields.",
  //     });
  //     return;
  //   }

  //   const formValues = this.PartyForm.value;
  //   console.log('Form Values:', formValues);

  //   if (this.PartyForm.valid) {
  //     if (!this.isShowGstFields) {
  //       if ((this.PartyForm.controls.billingAddress.value === "" || this.PartyForm.controls.partyName.value === ""
  //         || this.PartyForm.controls.mobile.value === "" || this.PartyForm.controls.email.value === "")) {
  //         this.toastService.toastMsg({
  //           title: "Error",
  //           content: "Fill All Required Fields.",
  //         });
  //         return false;
  //       } else if ((this.PartyForm.controls.billingAddress.value === "" && this.PartyForm.controls.partyName.value == ""
  //         && this.PartyForm.controls.mobile.value === "" && this.PartyForm.controls.email.value === "")) {
  //         this.toastService.toastMsg({
  //           title: "Error",
  //           content: "Fill All Required Fields.",
  //         });
  //       }
  //       else {
  //         this.addPartyObject();
  //       }
  //     }
  //     else {
  //       if ((this.PartyForm.controls.billingAddress.value === "" || this.PartyForm.controls.partyName.value === ""
  //         || this.PartyForm.controls.mobile.value === "" || this.PartyForm.controls.email.value === ""
  //         || this.PartyForm.controls.gstIn.value === "" || this.PartyForm.controls.gstType.value == "")) {
  //         this.toastService.toastMsg({
  //           title: "Error",
  //           content: "Fill All Required Fields.",
  //         });
  //         return false;
  //       }
  //     }
  //   } else {
  //     this.toastService.toastMsg({
  //       title: "Error",
  //       content: "Fill All Required Fields.",
  //     });
  //     return;
  //   }
  // }

  // addPartyObject() {
  //   this.finalObject = {
  //     "partyDetails": {
  //       "isCustomer": this.isShowGstFields,
  //       "gstIn": this.PartyForm.controls.gstIn.value ? this.PartyForm.controls.gstIn.value : null,
  //       "partyName": this.PartyForm.controls.partyName.value,
  //       "mobile": this.PartyForm.controls.mobile.value ? (this.PartyForm.controls.mobile.value).toString() : null,
  //       "email": this.PartyForm.controls.email.value,
  //       "billingAddress": this.PartyForm.controls.billingAddress.value,
  //       "shippingAddress": this.PartyForm.controls.shippingAddress.value ? this.PartyForm.controls.shippingAddress.value : null,
  //       "openingBalance": null,
  //       "asOfDate": null,
  //       "balanceStatus": this.PartyForm.controls.balanceStatus.value,
  //       "payment": null,
  //       "creditPeriodDay": null,
  //       "creditLimit": null,
  //       "noOfCredits": null,
  //       "relationSince": null,
  //       "userId": this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
  //       "distance": null,
  //       "transporation": null,
  //       "gstType": null,
  //     },
  //     "groupAdd": null
  //   }

  //   this.partyService.postParty(this.finalObject).then((res) => {
  //     this.paramID = res.data.id
  //     if (res.success) {
  //       this.toastService.toastMsg({
  //         title: "Success",
  //         content: "Vendor Added Successfully!",
  //       });
  //       this.PartyForm.reset();
  //       this.modal.dismissAll();
  //       this.getAllParties();
  //       // this.submittedParty = false;
  //     }
  //   },
  //     (err) => {
  //       if (err.error.expose) {
  //         this.toastService.toastMsg({
  //           title: "Error",
  //           content: this.titleCasePipe.transform(err.error.error_message),
  //         });
  //       }
  //       else {
  //         this.toastService.toastMsg({
  //           title: "Error",
  //           content: "Something Went Wrong.",
  //         });
  //       }
  //     })
  // }

  addNewParty() {
    this.submittedParty = true;

    // Use Angular's built-in validation
    if (this.PartyForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return;
    }

    // Check if GST fields should be shown and validate accordingly
    if (this.isShowGstFields) {
      if (!this.PartyForm.controls.billingAddress.value || !this.PartyForm.controls.partyName.value ||
        !this.PartyForm.controls.mobile.value || !this.PartyForm.controls.email.value) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return;
      }
    } else {
      if (!this.PartyForm.controls.gstIn.value || !this.PartyForm.controls.billingAddress.value ||
        !this.PartyForm.controls.partyName.value || !this.PartyForm.controls.mobile.value ||
        !this.PartyForm.controls.email.value) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Fill All Required Fields.",
        });
        return;
      }
    }

    // Proceed with form submission if valid
    this.addPartyObject();
  }

  addPartyObject() {
    const finalObject = {
      partyDetails: {
        isCustomer: this.isShowGstFields,
        gstIn: this.PartyForm.controls.gstIn.value ? this.PartyForm.controls.gstIn.value : null,
        partyName: this.PartyForm.controls.partyName.value,
        mobile: this.PartyForm.controls.mobile.value ? this.PartyForm.controls.mobile.value.toString() : null,
        email: this.PartyForm.controls.email.value,
        billingAddress: this.PartyForm.controls.billingAddress.value,
        shippingAddress: this.PartyForm.controls.shippingAddress.value || null,
        openingBalance: null,
        asOfDate: null,
        balanceStatus: this.PartyForm.controls.balanceStatus.value,
        payment: null,
        creditPeriodDay: null,
        creditLimit: null,
        noOfCredits: null,
        relationSince: null,
        userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
        distance: null,
        transporation: null,
        gstType: null,
      },
      groupAdd: null
    };

    this.partyService.postParty(finalObject).then((res) => {
      if (res.success) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Contact Added Successfully!",
        });
        this.PartyForm.reset();
        this.modal.dismissAll();
        this.getAllParties();
        this.submittedParty = false;
        this.isShowGstFields = true;
      }
    }).catch((err) => {
      this.toastService.toastMsg({
        title: "Error",
        content: err.error?.expose ? this.titleCasePipe.transform(err.error.error_message) : "Something Went Wrong.",
      });
    });
  }

  customertoggleShow(value) {
    this.isShowGstFields = value;
    this.isShippingAddressinModal = false;
    this.PartyForm.patchValue({
      gstIn: null, // Assuming you want to clear gstIn as well
      partyName: '',
      mobile: null,
      email: '',
      billingAddress: '',
      shippingAddress: ''
    });

    Object.keys(this.PartyForm.controls).forEach(key => {
      this.PartyForm.get(key)?.markAsUntouched();
    });
    const gstInControl = this.PartyForm.get('gstIn');

    if (this.isShowGstFields) {
      gstInControl.disable();
    } else {
      gstInControl.enable();
    }
  }


  /**
   * Get Customer product info for remote inovoice generation
   */
  getCustomerProductByOrderId() {
    this.subTotal = 0;
    this.totalGST = 0;
    this.total = 0;
    this.remoteShopService
      .getCustomerProductByOrderId({}, this.orderId)
      .then((res) => {
        if (res) {
          this.showInvoiceGenerateData = true;
        }
        this.SaleInvoiceForm.patchValue({
          partyName: res.data.customerName,
          billingAddress: res.data.billingAddress,
          gstNo: res.data.gstIn,
        });
        this.subTotal = res.data.totalBillAmount - res.data.billGstAmount;
        this.totalGST = res.data.billGstAmount;
        this.total = res.data.totalBillAmount;
        this.orderData = res.data.sales_products;
      });
  }

  /**
   * Generate Sale Invoice of Remote Order
   */
  patchGenerateRemoteInovice() {
    let data = {
      orderDetails: {
        orderId: this.orderId,
        invoiceDate: this.todaysDate,
        ewayBillNo: this.SaleInvoiceForm.controls.ewayBillNo.value,
        invoiceNo: this.SaleInvoiceForm.controls.invoiceNo.value,
        lastInvoiceNumber: this.lastInvoiceNumber,
      },
    };
    this.remoteShopService.patchGenerateRemoteInovice(data).then(
      (res) => {
        if (res) {
          this.showPrintBtn = true;
          this.toastService.toastMsg({
            title: "Success",
            content: "Receipt Generated!",
          });
        }
      },
      (err) => {
        if (err.error.status == 406) {
          this.toastService.toastMsg({
            title: "Error",
            content: err.error.error_message,
          });
        } else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Internal Server Error",
          });
        }
      }
    );
  }

  /**
   * print receipt
   */
  // onPrint() {
  //   const printContents = document.getElementById("panel").innerHTML;
  //   const originalContents = document.body.innerHTML;
  //   window.print();
  // }

  /**
   * Get Invoice details by orderId
   */
  getInvoiceByOrderId() {
    this.remoteShopService.getInvoiceByOrderId({}, this.orderId).then((res) => {
      this.remoteProductData = res.data.sales_products;
      this.remoteGSTAmount = res.data.billGstAmount;
      this.remoteTotalBillAmount = res.data.totalBillAmount;
      this.billingAddress = res.data.billingAddress;
      this.customerName = res.data.customerName;
    });
  }

  /**
   * toggle for cash/sale
   * @param event
   */
  isCashSaleToggle(event) {
    this.isCashSale = event;
    this.SaleInvoiceForm.get('sendWhatsappMsg').setValue(!this.isCashSale)
    this.SaleInvoiceForm.get('amountByUser').setValue(this.billFinalTotal - this.billFlatOffer?.discountAmount)
  }

  toggleCheckboxAll(event) {
    this.deleteProductData = [];
    this.isAllChecked = event;

    if (event) {
      this.deleteProductData = this.productData;
      for (let i = 0; i < this.deleteProductData.length; i++) {
        this.formArr.controls[i].patchValue({
          read: true,
        });
        this.checkedAll = true;
      }
    } else {
      this.deleteProductData = [];
      for (let i = 0; i < this.productData.length; i++) {
        this.formArr.controls[i].patchValue({
          read: false,
        });
        this.checkedAll = false;
      }
    }
  }

  async changeQuantity(sign, i, quantity?) {
    switch (sign) {
      case "-":
        if (this.productData[i].quantity > 1) {
          this.productData[i].quantity = this.productData[i]?.quantity - 1;
        }
        break;
      case "+":
        this.productData[i].quantity = this.productData[i]?.quantity + 1;
        break;
      case "rm":
        this.productData.splice(i, 1);
        if (this.productData.length <= 0) {
          this.totalGST = 0;
          this.subTotal = 0;
          this.total = 0;
          this.newGstRate = 0;
          this.gstRate = 0;
          this.amtToMinusFromTotal = 0;
          this.showCounterDiscountField = false;
          // this.allValuesZero = true
        }
        break;
      case "qty":
        this.productData[i].quantity = quantity;
        break;
      default:
        this.productData[i];
        break;
    }

    await this.checkFreeProducts(this.productData)
    await this.checkOffer(this.productData[i]?.quantity, this.productData[i]?.id)
    this.removeCoupon()
    // this.removeCounter()
  }

  toggleCheckboxSingle(event, i) {
    if (event) {
      for (let j = 0; j < this.productData.length; j++) {
        if (j == i) {
          this.formArr.controls[i].patchValue({
            read: true,
          });
          this.singleChecked = event;
          this.deleteProductData.push(this.productData[i]);
        }
      }
    } else {
      this.formArr.controls[i].patchValue({
        read: false,
      });
      this.singleChecked = this.selectedCheckBox;
      for (let n = 0; n <= this.deleteProductData.length; n++) {
        if (this.productData[i].barcode == this.deleteProductData[n].barcode)
          this.deleteProductData.splice(n, 1);
      }
    }
  }

  getCredit(e) {
    // if (e.value == "true") {
    //   e.value = true;
    // } else {
    //   e.value = false;
    // }
    this.SaleInvoiceForm.controls.credit.value = e.value;
  }

  StringOnly(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  openAddPartyModal(name) {
    this.modal.open(name, {
      centered: true,
      backdrop: "static",
      size: "lg",
    });
  }

  modalDismiss() {
    this.PartyForm.reset()
    this.modal.dismissAll();
  }

  onCancel() {
    const currentRoute = localStorage.getItem('currentRoute');

    if (currentRoute) {
      // Navigate to the route stored in localStorage
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentRoute]);
      });
    } else {
      console.error('No current route found in localStorage');
    }
  }

  openDatepicker() {
    const selectedDate = this.todaysDate;
    if (isNaN(selectedDate.getTime())) {
      this.todaysDate = new Date();
    }
  }

  // Function to check offer for a product
  async checkOffer(quantity: number, productId: string, finalTotal?: any) {
    // let finalTotal = this.calculateFinalTotal()

    if (quantity > 0 && quantity) {
      const requestData = {
        quantity: quantity,
        productId: productId,
        finalTotal: finalTotal
      }

      await this.promotionService.checkoffer(requestData, this.currentUser.id).then(
        (response: any) => {
          if (response.success && response.data !== null) {
            const offerData = response.data;
            // Loop through your formArr and update each form group with the 'offer' value
            this.productData.forEach((product: any) => {
              // Find the matching product in productData list using product id and freeProductId
              if (product.id === offerData.freeProductId && offerData.type === 'DISCOUNT') {
                // Assign offer details to the matched product
                product.isOffer = true;
                product.offerData = response.data;
              }
            });
          } else {
            this.productData.forEach((product: any) => {
              if (product.id === productId) {
                product.isOffer = false;
                product.offerData = null;
              }
            })
          }
        },
        (error) => {
          // Handle errors if the API call fails
          console.error('Error checking offer:', error);
        }
      );
    }
    this.calculationTotal()
  }


  async flatDiscountFunc(finalTotal) {
    // let finalTotal = this.calculateFinalTotal()
    const requestData = {
      finalTotal: finalTotal
    }

     // Simulate delay before making the API call
    await new Promise((resolve) => setTimeout(resolve, 1));

    try {
      // Call the promotionService's flatDiscount method and wait for the response
      const response = await this.promotionService.flatDiscount(requestData, this.currentUser.id);

        if (response.success && response.data !== null) {
          const offerData = response.data;

          this.billFlatOffer = {
            discountAmount: offerData?.discountAmount,
            schemaName: offerData?.schemaName
          };

          // Uncomment if you want to apply the discount to the final total
          // this.billFinalTotal = this.billFinalTotal - offerData?.discountAmount;

        } else {
          this.billFlatOffer = {};
        }
      } catch (error) {
        // Handle any errors from the API call
        console.error('Error checking offer:', error);
      }

  }

  async applyGiftCard(finalTotal, giftcard_code) {
    try {
      const res: any = await this.promotionService.applyGiftCard({ giftcard_code: giftcard_code }, this.currentUser.id);
      
      if (res.success && res.data !== null) {
        if ((finalTotal || 0) > (res.data?.max_discount_amount || 0)) {
          this.giftCardAmtToMinusFromTotal = res.data?.max_discount_amount || 0;
          this.giftcardId = res.data.id;
          this.giftcardCode = res.data.giftcard_code;
          this.toastService.toastMsg({
            title: 'Success',
            content: `Gift Card Applied Successfully of ₹ ${(res.data?.max_discount_amount.toFixed(2) || 0)}`
          });
        } else {
          // Error case: Gift card amount exceeds the final total
          const exceededAmount = (res.data?.max_discount_amount || 0) - finalTotal;
          this.toastService.toastMsg({
            title: 'Error',
            content: `Gift Card amount exceeds the total invoice amount by ₹ ${exceededAmount.toFixed(2)}`
          });
          this.giftCardAmtToMinusFromTotal = 0;
        }
      } else {
        // Handle the case where the gift card is not valid
        this.giftCardAmtToMinusFromTotal = 0;
      }
    } catch (error) {
      // Handle errors if the API call fails
      console.error('Error checking offer:', error);
      this.giftCardAmtToMinusFromTotal = 0;
    }
  }

  giftCard(){
    this.calculationTotal();
    // Logic to apply gift card
    this.giftCardApplied = true;
  }

  // Function for a free product API
  async checkFreeProducts(productData: any[]) {
    const productArr = []
    productData.forEach((product) => {
      productArr.push({ quantity: product.quantity, productId: product.id });
    })

    await this.promotionService.checkFreeProducts({ productData: productArr }, this.currentUser.id).then(
      async (response: any) => {
        if (response.success && response.data !== null) {
          this.freeProductDataList = response.data.free;
          this.suggesionList = response.data.suggession;
          if (this.freeProductDataList && this.freeProductDataList.length) {

            // Loop through your formArr and update each form group with the 'free Product' value
            await this.productData.forEach(product => {
              // Find the matching product in productData list using product id and freeProductId
              const matchingFreeProduct = this.freeProductDataList.find(
                freeProduct => product.id === freeProduct.freeProductId
              );

              if (matchingFreeProduct) {
                product.isFreeProduct = true;
                product.schemaName = matchingFreeProduct.schemaName;
                product.freeProductQuantity = matchingFreeProduct.extraQuantity;
                product.freeProductTotalGst = matchingFreeProduct.gstOnExtraQuantity;
                product.freeProductTotalPrice = matchingFreeProduct.extraQuantityPrice;
                // product.freeProductsalePrice = matchingFreeProduct.freeProductsalePrice;
                // product.numberOfProductsApplied = matchingFreeProduct.numberOfProductsApplied;
                // product.freeProductExtraQuantity = matchingFreeProduct.extraQuantity;
                // product.freeProductExtraQuantityPrice = matchingFreeProduct.extraQuantityPrice;
              }
            });
          } else {
            this.productData.forEach((product: any) => {
              product.isFreeProduct = false;
            })
          }
        }
      },
      (error) => {
        // Handle errors if the API call fails
        console.error('Error checking offer:', error);
      }
    );
    // this.calculationTotal()
  }

  openCouponModal() {
    this.calculationTotal()
    const dialogRef: MatDialogRef<ApplyCouponComponent> = this.dialog.open(ApplyCouponComponent, {
      width: '500px',
      height: 'auto',
      // data: { billAmt : this.total },
      data: {
        billAmt: this.billFinalTotal - (this.billFlatOffer?.discountAmount || 0),/* Pass the bill amount */
        appliedCoupon: this.appliedCoupon, // Pass the appliedCoupon data to the modal
      },
      // data: { data },
      disableClose: true,
      // position: {
      //   right: '0',
      // },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.couponApplied) {
        // Handle the data received from the modal
        this.appliedCoupon = result.couponApplied;
        if (this.appliedCoupon !== undefined && this.appliedCoupon !== null) {
          this.couponApplied = true
        }
        this.calculationTotal()
      }
    });
  }

  removeCoupon() {
    this.couponApplied = false;
    this.appliedCoupon = null;

    this.removeCounter()
    // this.getCounterDiscountAmount()

    this.cdref.detectChanges();
  }

  removeCounter() {
    this.f.counterDiscount.value = 0
    this.counterOn = false;
    // this.removeCoupon()
    this.calculationTotal();
    this.cdref.detectChanges();
    // const counterDiscountControl = this.SaleInvoiceForm.get('counterDiscount');
    // counterDiscountControl?.setValue(Math.round(null))
  }

  removeGiftCard() {
    this.f.giftcard_code.value = null;
    this.giftCardApplied = false;
    this.removeCoupon()
    // this.calculationTotal();
    this.cdref.detectChanges();    
  }

  async getCounterDiscountAmount() {
    this.calculationTotal()
    this.counterOn = true

    await this.promotionService.counterDiscountAmount({ billAmount: Math.round((this.billFinalTotal || 0) - (this.billFlatOffer?.discountAmount || 0 )) }, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id).then(
      (response: any) => {
        if (response.success && response.data !== null) {
          this.counterAmt = response.data;
          const counterDiscountControl = this.SaleInvoiceForm.get('counterDiscount');
          counterDiscountControl?.setValue(Math.round(this.counterAmt.calculatedAmount))
          this.cdref.detectChanges()
        } else {
          this.counterOn = false
          this.toastService.toastMsg({
            title: 'Error',
            content: 'Please add data in the counter discount to apply discount'
          })
        }
      },
      (error) => {
        // Handle errors if the API call fails
        console.error('Error checking offer:', error);
      }
    );
    this.calculationTotal()
  }

  // Inside your component class
  async calculateFinalTotal(): Promise<void> {
    const total = this.total;
    const amtToMinusFromTotal = this.amtToMinusFromTotal || 0;
    const counterDiscount = this.f.counterDiscount.value || 0;
    const couponDiscount = this.appliedCoupon ? this.appliedCoupon?.discount_amount : 0;
    let finalTotal: number;

    // if (amtToMinusFromTotal !== 0 && counterDiscount !== 0 && couponDiscount !== 0) {
    //   finalTotal = total - amtToMinusFromTotal - counterDiscount - couponDiscount;
    // } else if(amtToMinusFromTotal === 0 && counterDiscount !== 0 && couponDiscount !== 0) {
    //   finalTotal = total - counterDiscount - couponDiscount;
    // } else if(amtToMinusFromTotal === 0 && counterDiscount !== 0 && couponDiscount !== 0) {
    //   finalTotal = total - counterDiscount - couponDiscount;
    // } else {
    //   finalTotal = total
    // }
    if (amtToMinusFromTotal !== undefined && amtToMinusFromTotal !== null &&
      counterDiscount !== undefined && counterDiscount !== null &&
      couponDiscount !== undefined && couponDiscount !== null) {
      finalTotal = total - (amtToMinusFromTotal || 0) - (counterDiscount || 0) - (couponDiscount || 0);
    } else {
      finalTotal = total
    }
    // this.checkOffer(1, null, finalTotal)

    this.billFinalTotal = finalTotal
    await this.flatDiscountFunc(this.billFinalTotal)

    // Update the validator for amountByUser
    let finalAmount = ((this.billFinalTotal || 0) - (this.billFlatOffer?.discountAmount || 0));
    
    if(finalAmount && (this.f.paymentMode.value === 'GIFTCARD') && (this.f.giftcard_code.value)) {
      // Wait for the applyGiftCard function to finish before continuing
      await this.applyGiftCard(finalAmount, this.f.giftcard_code.value);
      this.billFinalTotal = (this.billFinalTotal || 0) - (this.giftCardAmtToMinusFromTotal || 0);

      // Update again the final Amt
      finalAmount = ((this.billFinalTotal || 0) - (this.billFlatOffer?.discountAmount || 0));
    }

    if(this.isCashSale || this.currentUser.userType === "EMPLOYEE"){
      const amountByUserControl = this.SaleInvoiceForm.get('amountByUser');
      amountByUserControl.clearValidators();
      amountByUserControl.setValidators([Validators.required, this.minimumAmountValidator(finalAmount)]);
      amountByUserControl.updateValueAndValidity();
    }

    const amountByUser = this.f.amountByUser.value || 0;  // Handle empty input
    if (amountByUser > 0 && finalAmount < amountByUser) {
      this.changeDue = amountByUser - finalAmount;
      if (this.changeDue > 0) {
        this.toastService.toastMsg({
          title: 'Info',
          content: `Change Due: ${(this.changeDue).toFixed(2)} ₹`
        });
      }
    } else {
      this.changeDue = 0;  // Reset changeDue if conditions aren't met
    }

    const data = {
        customerName: this.myControl.value,
        productData: this.productData || [],
        subtotal: this.subTotal || 0,
        discountedSubtotal: (this.amtToMinusFromTotal - this.newGstRate) || 0,
        tax: this.gstRate || 0,
        discountedTax: this.newGstRate || 0,
        total: this.total || 0,
        discountedTotal: (this.total - this.amtToMinusFromTotal) || 0,
        counterDiscount: this.SaleInvoiceForm.value.counterDiscount || 0,
        couponDiscount: this.appliedCoupon || 0,
        flatDiscount: this.billFlatOffer || 0,
        finalTotal: ((this.billFinalTotal || 0) - (this.billFlatOffer?.discountAmount || 0 )) || 0,
        amountByUser: this.SaleInvoiceForm.value.amountByUser || 0,
        amountDue: this.changeDue || 0,
        isDiscTax: (this.newGstRate !== 0) || false,
        isDiscTotal: (this.amtToMinusFromTotal !== 0) || false,
        paymentMode: this.SaleInvoiceForm.controls.paymentMode.value,
        discountedAmt: ((amtToMinusFromTotal || 0) + (counterDiscount || 0) + (couponDiscount || 0) + (this.billFlatOffer?.discountAmount || 0) + (this.giftCardAmtToMinusFromTotal || 0)),
        giftCardAmtToMinusFromTotal: this.giftCardAmtToMinusFromTotal || 0
    }

    localStorage.setItem('invoiceData', JSON.stringify(data));
    // return finalTotal;
  }

  // Function to handle selection change in warehouse selection dropdown
  onWarehouseSelectionChange(event: any) {
    if (event.value === 'clear') {
      this.SaleInvoiceForm.get('warehouse').setValue(null); // Clear the selection
      this.productData = [];
      this.calculationTotal();
    }
  }

  openCustomerFacingDisplay() {
    // Open customer-facing display in a new tab
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/pages/customer-display']));

    // Get the current origin (for both localhost and deployed environments)
    const currentOrigin = window.location.origin;
    
    // Append the desired route
    const customerDisplayUrl = `${currentOrigin}/#/customer-display`;
    
    window.open(customerDisplayUrl, '_blank');
    // window.open('http://localhost:4600/#/customer-display', '_blank');
    // this.router.navigate(['/customer-display']);
  }

  customFieldConfiguration() {
    this.router.navigate(['pages/settings/invoice_generate']);
  }

  showLastSaleReceipt() {
    if (this.lastInvoiceId == null || isNaN(this.lastInvoiceId)) {
      console.error('Invalid lastInvoiceId:', this.lastInvoiceId);
      return;
    }
    this.getReceiptById(this.lastInvoiceId);
  }

  getAllSaleInvoice() {
    this.saleInvoiceService.getAllSaleInvoice({}, this.currentUser.id, "SALES", 500, 1).then((res) => {
      if(res.success){
        //If the current page is greater than 1 and the page data is empty,reset to the first page and fetch the user list again
        if (res.data.currentPage > 1 && res.data.pageData.length === 0) {
          this.currentPageNo = 0;
          this.getAllSaleInvoice();
        }
        this.invoiceData = res.data.pageData;
        this.lastInvoiceId = res.data.pageData[0].id;
        this.getReceiptById(this.lastInvoiceId);

      } else{
        this.invoiceData = [];
      }
    },
    (err) => {
      if(err.error.status === 404){
        this.invoiceData = [];
      }
      console.warn(err)
    })
  }

  onShowLastSaleReceiptClick() {
    this.getAllSaleInvoice();
    this.showPrintPage = true; // Set flag to true
    // this.getAllSaleInvoice();
  }

  getReceiptById(receiptId) {
    this.getProfileData();

    this.saleInvoiceService.getSaleInvoiceById({}, receiptId).then((res) => {
      if(res.success){
        this.salesData = res.data;
        this.saleProductData = res.data.sales_products;
        this.invoiceNo = res.data.invoiceNo;
        this.invoiceDate = res.data.invoiceDate;
        this.dueDate = res.data.dueDate;
        this.billingAddress = res.data.billingAddress;
        this.shippingAddress = res.data.shippingAddress;
        this.placeOfSupply = res.data.placeOfSupply;
        this.billGstAmount = res.data.billGstAmount;
        this.flatDiscount = res.data?.flatDiscount;
        this.giftcardCode = res.data?.giftcardCode;
        this.masterFinalGiftcardPrice = res.data?.masterFinalGiftcardPrice;
        this.totalGST = res.data.billGstAmount;
        this.totalBillAmount = res.data.totalBillAmount;
        this.finalAmount = res.data.masterFinalPrice;
        this.hsn = res.data?.sales_products[0]?.product?.hsn;
        this.convertToWord(this.finalAmount)

        if (this.salesData.supplyType == 'INTERSTATE') {
          this.gstStateMatch = false;
        } else {
          this.gstStateMatch = true;
        }
      }
    })
  }

  
  convertToWord(num) {
    const toWords = new ToWords({
      converterOptions: {
        currency: false,
        ignoreDecimal: false,
        ignoreZeroCurrency: true,
      },
    });
    this.finalAmount = numberToWords.toWords(num);
    this.currencySymbol = '₹';
  }

  openDialogPayment(mode: boolean): void {
    const dialogRef: MatDialogRef<CashToRegisterComponent> = this.dialog.open(CashToRegisterComponent, {
      width: '800px',
      height: '375px',
      data: { mode : mode },
      disableClose: true,
      position: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openCloseRegister() {
    this.router.navigate(['pages/close-register']);
  }

  getTextSize(selectedTextSize: string): number {
		switch (selectedTextSize) {
		  case 'Large':
			return 22;
		  case 'Medium':
			return 20;
		  case 'Small':
			return 18;
		  default:
			return 20; // Default to 'Medium' size if an invalid option is selected
		}
	}

  getTextSizeprint(TextSizeprint: string): number {
		switch (TextSizeprint) {
		  case 'Large':
			return 16;
		  case 'Medium':
			return 14;
		  case 'Small':
			return 12;
		  default:
			return 14; // Default to 'Medium' size if an invalid option is selected
		}
	}

  onPrint() {
    this.getProfileData();

    const tableElement = document.getElementById('normal-print'); // Get the table element

    if (tableElement) {
      const printContents = tableElement.outerHTML;

      // Open a new window for printing
      const printWindow = window.open('', '_blank', 'width=800,height=600');

      if (printWindow) {
        // Write the document content for printing
        printWindow.document.write(`
          <html>
            <head>
              <title>&nbsp;</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                  margin: 10px;
                  padding: 10px;
                }

                #originalForReceiptPrint {
                  display: flex !important;
                  justify-content: center;
                  border: 2px solid grey;
                  color: black !important;
                  margin: 0;
                  padding: 0;
                  font-size: 12px !important;
                  -webkit-print-color-adjust: exact;
                }
                #printPageButton {
                  display: none;
                }

                table {
                  width: 100%;
                  border-collapse: collapse;
                }

                th, td {
                  padding: 8px;
                  text-align: left;
                  border: 1px solid black;
                  font-size: 12px;
                }

                tr {
                  page-break-inside: avoid;
                }

                @media print {
                  body {
                    -webkit-print-color-adjust: exact;
                  }
                  @page {
                    size: A4;
                    margin: 1cm;
                  }
                }
              </style>
            </head>
            <body>
              ${printContents} <!-- Insert the table content -->
            </body>
          </html>
        `);

        printWindow.document.close(); // Close the document stream

        // Wait for the window to fully load before printing
        printWindow.onload = () => {
          printWindow.focus(); // Focus on the print window
          printWindow.print(); // Trigger the print dialog
          setTimeout(() => {
            printWindow?.close();
          }, 500);
          printWindow.onafterprint = function () {
            printWindow.close(); // Optionally close the print window after printing
          };
        };
      } else {
        console.error("Failed to open print window.");
      }
    } else {
      console.error('Element not found: excel-table');
    }
  }


  toTitleCase(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  
  thermalPrint() {
    const selectedTextSize = 'Medium';
    const textSize = this.getTextSize(selectedTextSize);
    // const textSizePrint = this.getTextSizeprint('Medium');

    const printContents = `
      <div class="card" style="box-shadow: none !important;">
        <div class="card-body">
          <div class="flex-container" style="margin-top: 16px;">
            <div class="flex-row" style="display: flex;flex-direction: column;margin: 8px 0px;">
              <div class="row-left" style="display: flex;flex-direction: column;flex-basis: 50%;">
                <span style="font-weight: 600;margin:5px 0px 12px; color: #000; font-size: ${textSize}px;">${this.tradeName ? this.tradeName : "NA"}</span>
                <span>Contact:&nbsp;${this.mobile ? this.mobile : 'NA'}</span>
              </div>
              <div class="row-right" style="display: flex;flex-direction:column;flex-basis:50%;margin-top: 16px;">
                <div class="right-content" style="display: flex;flex-direction: column;align-items: flex-start;margin:4px 0;">
                  <div class="content-length" style="flex-basis: 30%;">
                    <span style="font-weight: 600;">TAX INVOICE</span>
                  </div>
                  <div class="content-length" style="flex-basis: 40%;">
                    <button
                      style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                      id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0" type="button"
                      onclick="window.print()"><span class="fas fa-print me-1"></span>Thermal Print
                    </button>
                  </div>
                  <div class="content-length" style="flex-basis: 30%;">
                    <button
                      style="float: right;font-size: 16px !important;font-weight: bold;padding-right: 0px !important;"
                      id="printPageButton" class="btn btn-falcon-default btn-sm mb-sm-0" type="button"
                      onclick="window.print()"><span class="fas fa-print me-1"></span>Print
                    </button>
                    <div id="originalForReceiptPrint" style="margin: 4px 0;">
                      ORIGINAL FOR RECIPIENT
                    </div>
                  </div>
                </div>
                <div class="right-content" style="display: flex;flex-direction: column;align-items: flex-start;margin:4px 0;">
                  <div class="content-length">
                    Invoice #<span style="float: right;">:</span>
                  </div>
                  <div class="content-length" style="margin: 4px 0;">
                    ${this.invoiceNo}
                  </div>
                </div>
                <div class="right-content" style="display: flex;flex-direction: column;align-items: flex-start;margin:4px 0;">
                  <div class="content-length">
                    Invoice Date<span style="float: right;">:</span>
                  </div>
                  <div class="content-length" style="margin: 4px 0;">
                    ${new Date(this.invoiceDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).toUpperCase()}
                  </div>
                </div>
              </div>
            </div>

          <div class="flex-row" style="display: flex;flex-direction: column;margin: 8px 0px;">
              <div class="row-left" style="display: flex;flex-direction: column;flex-basis: 50%;">
                <span class="row-2-heading"
                  style="width: 80px;font-weight: 600;background-color: #ccd4dd;-webkit-print-color-adjust: exact;margin:8px 0px;padding:8px;background: ${this.selectedColor};">BILL TO</span>
                <span style="font-weight: 600;margin-bottom:4px;">${this.salesData?.customerName ? this.salesData?.customerName : "NA"}</span>
                <span style="margin-bottom: 4px;">${this.billingAddress ? this.billingAddress : "NA"}</span>
                <span style="margin-bottom: 4px;">Contact: &nbsp;${this.salesData?.mobile ? this.salesData?.mobile : "NA"}</span>
                <span style="margin-bottom: 4px;">GSTIN: &nbsp;${this.gstinNo ? this.gstinNo : "NA"}</span>
              </div>
              <div class="row-right" style="display: flex;flex-direction: column;flex-basis: 50%;margin-top:16px;">
                <span class="row-2-heading"
                  style="width: 90px;font-weight: 600;background-color: #ccd4dd;-webkit-print-color-adjust: exact;margin:8px 0px;padding:8px;background: ${this.selectedColor};">SHIP TO</span>
                <span style="font-weight: 600;margin-bottom:4px;">${this.salesData?.customerName ? this.salesData?.customerName : "NA"}</span>
                <span style="margin-bottom: 4px;">${this.shippingAddress ? this.shippingAddress : "NA"}</span>
              </div>
            </div>

            <div class="row table-responsive scrollbar fs--1">
              <table class="table tablePrint" style="font-size: 10px!important;">
                <thead class="inner-model-table-heading printHead"
                  style="font-size: 10px!important;background-color: #ccd4dd;-webkit-print-color-adjust: exact;padding-top: 20px !important;padding-bottom: 20px !important;font-size: 10px !important;">
                  <tr class="text-white" style="background: ${this.selectedColor};">
                    <th style="text-align: left;width:3rem;font-size: 10px!important;padding: 10px;background: ${this.selectedColor};">ITEMS</th>
                    <th style="text-align: right;font-size: 10px!important;padding: 10px;background: ${this.selectedColor};">QTY.</th>
                    <th style="text-align: right;font-size: 10px!important;padding: 10px;background: ${this.selectedColor};">RATE</th>
                    <th style="text-align: right;font-size: 10px!important;padding: 10px;background: ${this.selectedColor};">AMOUNT</th>
                  </tr>
                </thead>
                <tbody class="printBody">
                    <tr> ${this.saleProductData.map(i => `
                      <td style="word-wrap: break-word;max-width: 20px;white-space: wrap;padding:10px;">
                        <span style="text-transform: uppercase;font-size: 10px!important;">${i?.itemName}</span>
                      </td>
                      <td style="text-align: right;white-space: nowrap;font-size: 10px!important;padding:10px;">
                        ${i?.quantity}
                      </td>
                      <td style="text-align: right;font-size: 10px!important;padding:10px;">
                        ₹&nbsp;${((i?.pricePerUnit / (1 + (i?.gst/100))) || 0).toFixed(2)}
                      </td>
                      <td style="padding:10px;">
                        <span style="display: flex;flex-direction:column;align-items:flex-end;font-size: 10px!important;">
                          <span> ₹&nbsp;${((i?.quantity * (i?.pricePerUnit / (1 + (i?.gst/100)))) || 0).toFixed(2)}</span>
                          <span style="font-size: 8px;">(${i?.gst || 0}%)</span>
                        </span>
                      </td>
                    </tr>
                  `).join('')}
                </tbody>
                <tfoot class="inner-model-table-heading printFoot"
                  style="font-size: 10px!important;background-color: #ccd4dd;-webkit-print-color-adjust: exact;padding-top: 20px !important;padding-bottom: 20px !important;font-size: 10px !important;">
                  <tr style="background: ${this.selectedColor};">
                    <th style="text-align: left;font-size: 10px!important;padding:10px;">SUBTOTAL</th>
                    <td style="padding:10px;"></td>
                    <td style="padding:10px;"></td>
                    <th style="text-align: right;font-weight: 600;font-size: 10px!important;padding:10px;">
                      ₹&nbsp;${((this.salesData?.totalBillAmount - (this.salesData?.billGstAmount)) || 0).toFixed(2)}
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div class="flex-row" style="display: flex;margin: 8px 0px;">
              <div class="row-right" style="display: flex;flex-direction: column;flex-basis:70%;">
                <div class="right-content" style="display: flex;align-items: center;margin-top: 8px;">
                  <div class="content-length" style="flex-basis: 50%;">
                    <span style="font-weight: 600;">TAXABLE AMOUNT</span>
                  </div>
                  <div class="content-length" style="flex-basis: 50%;text-align: end;">
                    ₹&nbsp;${((this.totalBillAmount - this.billGstAmount)  || 0).toFixed(2)}
                  </div>
                </div>
                <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                  <div class="content-length" style="flex-basis: 50%;">
                    <span>VAT</span>
                  </div>
                  <div class="content-length" style="flex-basis: 50%;text-align: end;">
                    ₹&nbsp;${(this.salesData?.billGstAmount || 0).toFixed(2)}
                  </div>
                </div>
                <div class="right-content" *ngIf="this.flatDiscount && this.flatDiscount !== 0" style="display: flex;align-items: center;margin-top: 4px;">
                  <div class="content-length" style="flex-basis: 50%;">
                    <span>Flat Discount1</span>
                  </div>
                  <div class="content-length" style="flex-basis: 50%;text-align: end;">
                    -₹&nbsp;${(this.flatDiscount || 0).toFixed(2)}
                  </div>
                </div>
                <div class="right-content" *ngIf="this.giftcardCode" style="display: flex;align-items: center;margin-top: 4px;">
                  <div class="content-length" style="flex-basis: 50%;">
                    <span>Gift Card: ${this.giftcardCode}</span>
                  </div>
                  <div class="content-length" style="flex-basis: 50%;text-align: end;">
                    -₹&nbsp;${(this.masterFinalGiftcardPrice || 0).toFixed(2)}
                  </div>
                </div>
                <hr style="border: ${this.selectedColor ? '1px solid ' + this.selectedColor : 'none'}; -webkit-print-color-adjust: exact; width: 100%;">
                <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                  <div class="content-length" style="flex-basis: 50%;">
                    <span style="font-weight: 600;">TOTAL AMOUNT</span>
                  </div>
                  <div class="content-length" style="flex-basis: 50%;text-align: end;">
                    ₹&nbsp;${(this.salesData?.totalBillAmount || 0).toFixed(2)}
                  </div>
                </div>
                <hr style="border: ${this.selectedColor ? '1px solid ' + this.selectedColor : 'none'}; -webkit-print-color-adjust: exact; width: 100%;">
                <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                  <div class="content-length" style="flex-basis: 50%;">
                    <span>Received Amount</span>
                  </div>
                  <div class="content-length" style="flex-basis: 50%;text-align: end;">
                    ₹&nbsp;${(this.salesData?.received || 0).toFixed(2)}
                  </div>
                </div>
                <div class="right-content" style="display: flex;align-items: center;margin-top: 4px;">
                  <div class="content-length" style="flex-basis: 50%;">
                    <span style="font-weight: 600;">Balance</span>
                  </div>
                  <div class="content-length" style="flex-basis: 50%;text-align: end;">
                    ₹&nbsp;${(this.salesData?.balance || 0).toFixed(2)}
                  </div>
                </div>
                <div class="right-content" style="display: flex;align-items:end;justify-content:end;margin-top: 4px;">
                  <div style="text-align: end;">
                    <p style="margin-bottom: 4px;">Total Amount (in words)</p>
                    <span>${this.toTitleCase(this.finalAmount)} ${this.currencySymbol}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-row" style="display: flex;flex-direction: column;margin: 16px 0px;flex-basis: 40%;">
              <div style="display: flex;flex-direction: column;justify-content: flex-start;margin: 8px 0px;">
                <span class="mb-1 mt-5" style="font-weight: 600;">TERMS AND CONDITIONS</span>
                <span style="font-size: 10px; margin-bottom: 4px; word-wrap: break-word; max-width:300px; white-space: wrap;">${this.invoiceTermsCon ? this.invoiceTermsCon : 'NA'}</span>
              </div>
              <div style="margin: 8px 0px;">
                <div>
                  <label ondragover="return false;">
                    <img style="width:120px;height:80px;" src="${this.signImg ? this.signImg : ''}" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;

    const printWindow = window.open('', '_blank');

    const printStyles = `
      <style>

          body {
            font-size: 10px;
            font-family: Poppins;
          }
          #originalForReceiptPrint {
            display: flex !important;
            justify-content: center;
            border: 2px solid grey;
            color: black !important;
            margin: 0;
            padding: 0;
            font-size: 10px !important;
            -webkit-print-color-adjust: exact;
          }
          #printPageButton {
            display: none;
          }
          .table-responsive thead th {
            color: #000;
            background-color: #ccd4dd;
            -webkit-print-color-adjust: exact;
          }
          .table-responsive tbody tr, td {
            min-height: fit-content;
            height: auto !important;
          }
          .table-responsive th, td, tr {
            border-style: none !important;
          }
          table {
            width: 100%;
            border-collapse: collapse;
          }

          th, td {
            padding: 8px;
            text-align: left;
            border: 1px solid black;
            font-size: 12px;
          }

          tr {
            page-break-inside: avoid;
          }
        @media print {

          @page {
            size: 10cm 15cm;
            margin: 0;
          }

        }
      </style>
    `;

    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          ${printStyles}
        </head>
        <body>
          ${printContents}
        </body>
        <footer>&nbsp;</footer>
      </html>
    `);

    // printWindow.document.close();
    // printWindow.print();
    // printWindow.close();

    printWindow.document.close(); // Close the document stream

    // Wait for the window to fully load before printing
    printWindow.onload = () => {
      printWindow.focus(); // Focus on the print window
      printWindow.print(); // Trigger the print dialog
      setTimeout(() => {
        printWindow?.close();
      }, 500);
      printWindow.onafterprint = function () {
        printWindow.close(); // Optionally close the print window after printing
      };
    };
  }
}
