import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PromotionService } from 'src/app/core/services/promotion.service';
import { ToastNotificationService } from 'src/app/core/services/toast-notification.service';

@Component({
  selector: 'app-add-promotion',
  templateUrl: './add-promotion.component.html',
  styleUrls: ['./add-promotion.component.scss']
})
export class AddPromotionComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"))
  public promotionForm: any;
  public submitted: boolean = false;
  public today: Date;
  types: string[] = ['DISCOUNT', 'PRODUCT', 'FLAT'];  // ['DISCOUNT', 'PRODUCT', 'FLAT', 'COUPONS'];
  productsIdList: any;
  selectedProductIds: string[] = [];

  constructor(private formBuilder: FormBuilder,
    public router: Router, private commonService: CommonService,
    private toastService: ToastNotificationService, public dialog: MatDialog,
    private titleCasePipe: TitleCasePipe,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddPromotionComponent>,
    private promotionService: PromotionService
  ) {
    this.today = new Date();
  }

  ngOnInit(): void {
    try {
      this.promotionForm = this.formBuilder.group({
          schemaName: [null, Validators.required],
          buyQuantity: [null, Validators.required],
          getQuantity: [null, Validators.required],
          discountPercentage: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
          minBillAmt: [0, Validators.required],
          discountAmount: [0, Validators.required],
          type: ['DISCOUNT', Validators.required], // Default value 'DISCOUNT'
      },
      {
          validators: [
              this.atLeastOneValidator,
              this.discountValidator()
          ], // Apply the custom validator
      });
      this.getAllProductsAvailableForPromotion()
      const discountPercentageControl = this.promotionForm.get('discountPercentage');
      const discountAmountControl = this.promotionForm.get('discountAmount');
      const getMinBillAmtControl = this.promotionForm.get('minBillAmt');

      // Subscribe to valueChanges of discountPercentage control
      discountPercentageControl?.valueChanges.subscribe(value => {
          try {
              if (value && !discountAmountControl?.disabled) {
                  discountAmountControl?.disable();
              } else if (!value && discountAmountControl?.disabled) {
                  discountAmountControl?.enable();
              }
          } catch (error) {
              console.error('Error in discountPercentageControl subscription:', error);
          }
      });

      // Subscribe to valueChanges of discountAmount control
      discountAmountControl?.valueChanges.subscribe(value => {
          try {
              if (value && !discountPercentageControl?.disabled) {
                  discountPercentageControl?.disable();
              } else if (!value && discountPercentageControl?.disabled) {
                  discountPercentageControl?.enable();
              }
          } catch (error) {
              console.error('Error in discountAmountControl subscription:', error);
          }
      });

      // If type is PRODUCT then discounted fields are get hidden... so below is code for that condition
      // When the type changes, adjust validators accordingly
      const typeControl = this.promotionForm.get('type');

      typeControl?.valueChanges.subscribe((type: string) => {
        // Dynamically adjust validators based on the selected type
        if (type === 'PRODUCT') {
          discountPercentageControl?.clearValidators();
          discountAmountControl?.clearValidators();
          getMinBillAmtControl?.clearValidators();
        } else if (type === 'FLAT'){
          getMinBillAmtControl?.setValidators(Validators.required);
          discountAmountControl?.setValidators(Validators.required);
          buyQtyControl?.clearValidators();
          getQtyControl?.clearValidators();
          discountPercentageControl?.clearValidators();
        } else {
          discountPercentageControl?.setValidators([Validators.min(0), Validators.max(100)]);
          discountAmountControl?.setValidators(null);
          getMinBillAmtControl?.clearValidators();
        }

        // Update and validate the controls
        discountPercentageControl?.updateValueAndValidity();
        discountAmountControl?.updateValueAndValidity();
        getMinBillAmtControl?.updateValueAndValidity();
        getQtyControl?.updateValueAndValidity();
        buyQtyControl?.updateValueAndValidity();

        // Update the form level validators as well
        this.promotionForm.updateValueAndValidity();
      });

      // If type is FLAT then buy and get qty are get hidden... so below is code for that condition
      // When the type changes, adjust validators accordingly
      const buyQtyControl = this.promotionForm.get('buyQuantity');
      const getQtyControl = this.promotionForm.get('getQuantity');
      typeControl?.valueChanges.subscribe((type: string) => {
        // Dynamically adjust validators based on the selected type
        if (type === 'FLAT') {
          buyQtyControl?.clearValidators();
          getQtyControl?.clearValidators();
        } else {
          getQtyControl?.setValidators(Validators.required);
          buyQtyControl?.setValidators(Validators.required);
        }

        // Update and validate the controls
        getQtyControl?.updateValueAndValidity();
        buyQtyControl?.updateValueAndValidity();

        // Update the form level validators as well
        this.promotionForm.updateValueAndValidity();
      });
    } catch (error) {
        this.toastService.toastMsg({
          title: 'Error',
          content: error
        })
    }
  }

  atLeastOneValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const discountPercentage = control.get('discountPercentage').value;
    const discountAmount = control.get('discountAmount').value;
  
    // Check if the type is "DISCOUNT" and at least one of the fields is empty
    if (control.get('type').value === 'DISCOUNT' && (!discountPercentage && !discountAmount)) {
      return { atLeastOneError: true };
    }
  
    return null;
  };   
  
  get f() {
    return this.promotionForm.controls;
  }
    // Custom Validator
     discountValidator(): ValidatorFn {
      return (formGroup: FormGroup) => {
        const minBillAmt = formGroup.get('minBillAmt')?.value;
        const discountAmount = formGroup.get('discountAmount')?.value;
          // Ensure we are comparing numbers
      const minBill = parseFloat(minBillAmt);
      const discount = parseFloat(discountAmount);
   // Ensure values are numbers and not NaN or undefined
   // Perform the comparison and return error if discount > minimum bill
      if (!isNaN(minBill) && !isNaN(discount) && discount > minBill) {
        return { discountInvalid: true }; // Error when discount is greater than minimum bill
      }
        return null; // Return null if the values are valid
      };
    }

  // discountValidator(): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } | null => {
  //     const minBillAmt = control.get('minBillAmt')?.value;
  //     const discountAmount = control.get('discountAmount')?.value;
  
  //     return discountAmount > minBillAmt ? { 'discountInvalid': true } : null;
  //   };
  // }

  cancel() {
    this.dialogRef.close();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberDecimalOnly(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getAllProductsAvailableForPromotion() {
    this.promotionService.getAllProductsAvailableForPromotion({
      data: {}, 
      userId: this.currentUser.id, 
      page: 1, 
      size: 1000, 
      productType: 'GOODS'
    }).then((res) => {
      if(res.success) {
        this.productsIdList = res.data.rows;
      }
    },(err) => {
        if (err.error.status === 404) {
            this.toastService.toastMsg({
              title: 'Error',
              content: 'Data Not Found!!!'
            })
        } else {
          this.toastService.toastMsg({
            title: 'Error',
            content: this.titleCasePipe.transform(err.error.error_message)
          })
        }
      });
  }

  onSave() {
    this.submitted = true;
    if (this.promotionForm.invalid) {
      if (this.promotionForm.hasError('discountInvalid')) {
        this.toastService.toastMsg({
          title: "Error",
          content: "Discount Amount cannot be more than Minimum Bill Amount.",
        });
        return false; // Prevent further processing
      }
  
      // If there are other errors, show a generic error message
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    }else if(this.selectedProductIds.length < 1 && this.promotionForm.value.type === 'PRODUCT') {
      this.toastService.toastMsg({
        title: 'Error',
        content: 'please select atleast one product'
      })
    } else {
      const data = {
        "promotionData": {
          "type": this.promotionForm.value.type,
          "schemaName": this.promotionForm.value.schemaName,
          "buyQuantity": this.promotionForm.value.type !== 'FLAT' ? parseInt(this.promotionForm.value.buyQuantity, 10) : null,
          "getQuantity": this.promotionForm.value.type !== 'FLAT' ? parseInt(this.promotionForm.value.getQuantity, 10) : null,
          "discountPercentage": this.promotionForm.value.type === 'PRODUCT' ? 100.00 : parseFloat(this.promotionForm.value.discountPercentage),
          "discountAmount": this.promotionForm.value.type === 'PRODUCT' ? null : parseFloat(this.promotionForm.value.discountAmount),
          "isActive": true,
          "freeProductId": this.promotionForm.value.type === 'PRODUCT' ? this.selectedProductIds : null,
          // If DiscountAmount is there user cannot sent the discountPercentage value or vise versa
          "minumumBill": this.promotionForm.value.minBillAmt ? this.promotionForm.value.minBillAmt : null,
        }
      }
      this.promotionService.addPromotion(data, this.currentUser.id).then( res => {
        if(res.success) {
       
          this.toastService.toastMsg({
            title: "Success",
            content: "Promotion Addedd Successfully!",
          });
          this.commonService.notifyDataAdded();
          this.dialogRef.close();
        }
      })
    }
  }

}
