<div class="modal-header pt-0">
  <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 10px;" class="modal-title mb-0">Add Non Current Assets
  </h1>
  <div class="addProductButtons">
    <button style="margin-right: 10px !important;" type="button" class="btn btn-cancel"
      (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-save" (click)="postnonAsseTsForm()" *appHasPermission="['create']; name 'assets'">Save</button>
  </div>
</div>
<div class="modal-body">
  <div class="container-fluid px-2">
    <form [formGroup]="nonAssetsForm">
      <div class="row mb-2">
        <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6">
          <label class="form-label">Assets Name<span class="error-asterisk">*</span></label>
          <mat-form-field class="example-full-width" appearance="outline">
            <input matInput required matNativeControl matInput disableOptionCentering placeholder="Assets Name"
              formControlName="assetName" [ngClass]="{
                'is-invalid': (f.assetName.invalid && f.assetName.touched) && submitted }">

          </mat-form-field>
          <mat-error class="text-danger" *ngIf="
          (submitted || f.assetName.touched) && f.assetName.errors?.required
        ">
            Assets Name is Required.
          </mat-error>
        </div>
        <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6">
          <label class="form-label">Date</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <input matInput formControlName="assetDate" required ngModel bsDatepicker placeholder="DD MMM YYYY"
              [(ngModel)]="todaysDate" style="text-transform:uppercase!important;" id="datePicker" bsDatepicker
              [maxDate]="today" [bsConfig]="{
                            containerClass: 'theme-dark-blue',
                            dateInputFormat: 'DD MMM YYYY',
                             showWeekNumbers:false
                            }" autocomplete="off" (click)="openDatepicker()" />
            <span>
              <mat-icon class="iconcalender ">date_range</mat-icon>
            </span>
          </mat-form-field>

          <mat-error class="text-danger" *ngIf="
          (submitted || f.assetDate.touched) && f.assetDate.errors?.required
        ">
            Date is Required.
          </mat-error>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6">
          <label class="form-label">Value of Assets<span class="error-asterisk">*</span></label>
          <mat-form-field class="example-full-width" appearance="outline">
            <input matNativeControl (keypress)="numberOnly($event)" type="text" placeholder="Value of Assets (in ₹)" formControlName="valueOfAssets" />
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="
          (submitted || f.valueOfAssets.touched) && f.valueOfAssets.errors?.required
        ">
            Value of Assets is Required.
          </mat-error>
        </div>
        <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6">
          <label class="form-label">Scrap Value <span class="error-asterisk">*</span></label>
          <mat-form-field class="example-full-width" appearance="outline">
            <input  (keypress)="numberOnly($event)"matNativeControl type="text" placeholder="Scrap Value (in ₹)" formControlName="scrapValue" />
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="
          (submitted || f.scrapValue.touched) && f.scrapValue.errors?.required
        ">
            Scrap Value is Required.
          </mat-error>

        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6">
          <label class="form-label">Payment Mode<span class="error-asterisk">*</span></label>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-select required matNativeControl matInput disableOptionCentering placeholder="Payment Mode"
              formControlName="paymentMode">
              <mat-option value="Cash">Cash</mat-option>
              <mat-option value="Bank">Bank</mat-option>
              <mat-option value="Credit">Credit</mat-option>

            </mat-select>
          </mat-form-field>
          <mat-error class="text-danger" *ngIf="
          (submitted || f.paymentMode.touched) && f.paymentMode.errors?.required
        ">
            Payment Mode is Required.
          </mat-error>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12 col-sm-12 col-xl-12 col-lg-12">
          <label class="form-label mb-5">Additional Cost</label>

          <div class="row mt-2">
            <div class="table-responsive">
              <table class="table table-bordered fs--1 mb-3">
                <thead class="text-900 text-center inner-model-table-heading bg-white">
                  <tr>
                    <th class="p-2" style="width:10% !important;text-align: center;vertical-align: middle;">#</th>
                    <th class="p-2" style="width:50% !important;text-align: center;vertical-align: middle;">Name</th>
                    <th class="p-2" style="text-align: center;vertical-align: middle;">Amount (in ₹)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container formArrayName="addtionalDetails" *ngFor="
                                  let group of additionalDetailsControls.controls;
                                  let i = index
                                ">
                    <tr [formGroupName]="i">
                      <td style="vertical-align:middle;text-align: center;padding:9px">{{i+1}}</td>
                      <td style="vertical-align:middle;text-align: center;">
                        <input matInput type="text" formControlName="name" (keypress)="acceptString($event)"/>
                      </td>

                      <td style="vertical-align:middle;text-align: center;"> <input matInput
                          (keyup.enter)="addAddtionalDetails()" type="text" formControlName="amount" (keypress)="numberOnly($event)" /></td>

                      <td class=" Icons" style="vertical-align:middle;text-align: center;">
                        <button class="btn" type="button" (click)="deleteaddAddtionalDetails(group)">
                          <span class="bi-trash icon-color fs-1" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Delete" style="color: red;"></span>
                          <!-- Delete -->
                        </button>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>

              <div class="row d-flex justify-content-between">
                <div class="col-sm-12 col-md-9 col-lg-9"></div>
                <div class="col-sm-12 col-md-3 col-lg-3 text-nowrap mb-3">
                    <!-- TODO: ADD BUTTON -->
                    <a class="btn btn-outline-warn dash fs--1" (click)="addAddtionalDetails()">
                        <span id="boot-icon" class="bi bi-plus-lg newrow" style="color: #FC7643;"></span>
                        <span class="me-2 newrow" data-bs-toggle="tooltip" style="color: #FC7643;"
                            data-bs-placement="top" title="Add Row">
                            Add New Row</span>
                    </a>
                </div>
            </div>
              <!-- <div class="row d-flex justify-content-end mt-2">
                <div class="col-sm-12 col-md-6 col-lg-6 text-nowrap mb-1 d-grid">
                 <a class="btn btn-outline-warning dash fs--1" (click)="addAddtionalDetails()">
                    <span id="boot-icon" class="bi bi-plus-lg newrow"></span>
                    <span class="me-2 fs--1 text-black newrow" data-bs-toggle="tooltip" data-bs-placement="top"
                      title="Add Row">
                      Add New Row</span>
                  </a>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-6 col-sm-12 col-lg-3 col-xl-3">
          <label class="form-label" style="margin-top: 20px;">Cost of Assets</label>
        </div>
        <div class="col-md-6 col-sm-12 col-lg-4 col-xl-4">
          <mat-form-field class="example-full-width bg-light " appearance="outline">
            <input class="disbaleFieldBg" matNativeControl type="text" readonly disabled
            (keypress)="numberOnly($event)"  placeholder="Cost of Assets (in ₹)" formControlName="costOfAssets" />
          </mat-form-field>
        </div>
      </div>
      <div class="mb-3">
        <div class=" row  mb-3 col-md-12 col-xl-12 col-lg-12 col-sm-12">
          <label class="form-label">Upload Document</label>
          <div>
            <label class="mt-2">
                <span *ngIf="this.panDocument ==='' "
                    style="text-decoration: underline; color: #0174E8; cursor: pointer;margin-left: 10px;font-size: 14px;">
                    Upload File
                </span>
                <input #fileInput style="display: none;" type="file" name="file"
                    (change)="handleInputChangePan($event)" *ngIf="this.panDocument ==='' ">
            </label>
            <div>
                <span style="position: relative;top: -10px;margin-left: 10px;" *ngIf="this.panDocument !=='' ">
                    <img style="cursor: pointer;" *ngIf="this.fileType == 'image'" [src]="this.panDocument" width="30"
                        height="30" (click)="openImgPdfpan()">
                    <img style="cursor: pointer;" *ngIf="this.fileType == 'pdf'" [src]="'assets/img/icons/spot-illustrations/pdfLogo.jpg'" width="30"
                        height="30" (click)="openImgPdfpan()">
                        <span style="margin-left: 10px; margin-right: 10px;">{{ getLastWordAfterHyphenPan(this.documentPan) }}
                        </span><span><button class="close" style="color: red;border: none;font-size: 16px;
                            background-color: white;" type="button" (click)="deletePan($event)">&#x2715;</button></span>
                </span>
            </div>
          </div>  
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6 col-xl-3 col-lg-3 col-sm-12">
          <label class="form-label">Depreciation</label>
        </div>
        <div class="col-md-6 col-xl-3 col-lg-3 col-sm-12 ">
          <div class="form-check form-switch mb-0">
            <input class="form-check-input" id="customSwitch1" type="checkbox" formControlName="depreciation" />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6">
          <label class="form-label">Method</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-select matNativeControl matInput disableOptionCentering placeholder="Method Name"
              formControlName="assetsMethod">
              <mat-option value="Simple">Simple</mat-option>
              <mat-option value="WDM">WDM</mat-option>

            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6" *ngIf="nonAssetsForm.get('assetsMethod').value !='WDM'  ">
          <label class="form-label">Process</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-select matNativeControl matInput disableOptionCentering placeholder="Process Name"
              formControlName="assetProcess">
              <mat-option value="Depreciation" *ngIf="nonAssetsForm.get('assetsMethod').value =='Simple'">Depreciation
                %</mat-option>
              <mat-option value="Useful Life" *ngIf="nonAssetsForm.get('assetsMethod').value =='Simple' ">Useful
                Life</mat-option>

            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6"
          *ngIf="nonAssetsForm.get('assetsMethod').value === 'Simple' && nonAssetsForm.get('assetProcess').value === 'Depreciation'">
          <label class="form-label">Enter Amount</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <input matNativeControl type="text" placeholder="Enter Amount" formControlName="assetDeprecianinPer" (keypress)="numberOnly($event)" />
          </mat-form-field>
        </div>

        <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6"
          *ngIf="nonAssetsForm.get('assetsMethod').value =='WDM'  || nonAssetsForm.get('assetProcess').value =='Depreciation %'">
          <label class="form-label"> Enter Depreciation %</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <input matNativeControl (keypress)="numberOnly($event)" type="text" disabled placeholder="Depreciation %"
              formControlName="assetDeprecianinPer" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6"
          *ngIf="nonAssetsForm.get('assetProcess').value =='Useful Life'">
          <label class="form-label">Enter Year</label>
          <mat-form-field class="example-full-width" appearance="outline">
            <input matNativeControl type="text"  (keypress)="numberOnly($event)" disabled placeholder="Enter Year" formControlName="assetsYear" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>
