import { Component, Inject, OnInit, SimpleChanges } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { logWarnings } from "protractor/built/driverProviders";
import { ProductService } from "src/app/core/services/product.service";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
import { Constants } from "src/app/_helpers/constant";
import { Location, TitleCasePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CommonService } from "src/app/core/services/common.service";
import lang from "src/assets/langTranslation/language_translation"
import { WarehouseService } from "src/app/core/services/warehouse.service";
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: "app-product-update",
  templateUrl: "./product-update.component.html",
  styleUrls: ["./product-update.component.scss"],
})
export class ProductUpdateComponent implements OnInit {
  public lang = lang.UAE;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public unitValue: any;
  public unitSecondaryValue: any;
  public isDisabled: boolean = false;
  public showProductType: any;
  public serviceToggle: boolean;
  public isStockToggle: boolean = true;
  public ProductForm: any;
  public productId: string = "";
  public imageId: any;
  public todaysDate: any;
  public todaysDate2: any;
  public unit: any;
  public units: any;
  public secondaryunit: any;
  public warrantyType: string;
  // image upload
  public activeColor: string = "green";
  public baseColor: string = "#ccc";
  public overlayColor: string = "rgba(255,255,255,0.5)";
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public imageSrc: string = "";
  public file: any;
  public selectedFiles: any;
  public selectedFileName: any;
  public imageSrcOne: any = "";
  public imageSrcOneId: any;
  public imageSrcTwo: any = "";
  public imageSrcTwoId: any;
  public imageSrcThree: any = "";
  public imageSrcThreeId: any = ""
  public imageSrcFour: any = "";
  public imageSrcFourId: any = "";
  public imageSrcFive: any = "";
  public imageSrcFiveId: any = "";
  public imageSrcSix: any = "";
  public imageSrcSixId: any = "";
  public imageSrcSeven: any = "";
  public imgNumber: any;
  public submitted: boolean = false;
  public productCategory: any;
  public categoryIddd: any;
  public productPicUrl: string = '';
  blankImage = [];
  public currentInput: any;
  public multipleImage = [];
  public multipleImage1 = [];
  previews: string[] = [];
  public fetchImages: any;
  imageArray: any;
  public coverImageChange: boolean = false;
  public catId: number;
  public primarySelectUnit: any;
  public secondarySelectUnit: any;
  public unitsecondary: any;
  public maxFiles: number = 0;
  public Filesmax: number = 5;
  public static Uni = [
    "BAGS(BAG)",
    "BALE(BAL)",
    "BUNDLES(BDL)",
    "BUCKLES(BKL)",
    "BILLIONS OF UNITS(BOU)",
    "BOX(BOX)",
    "BOTTLES(BTL)",
    "BUNCHES(BUN)",
    "CANS(CAN)",
    "CUBIC METER(CBM)",
    "CUBIC CENTIMETER(CCM)",
    "CENTIMETER(CMS)",
    "CARTONS(CTN)",
    "DOZEN(DOZ)",
    "DRUM(DRM)",
    "GREAT GROSS(GGR)",
    "GRAMS(GMS)",
    "GROSS(GRS)",
    "GROSS YARDS(GYD)",
    "KILOGRAMS(KGS)",
    "KILOLITER(KLR)",
    "KILOMETRE(KME)",
    "MILLILITRE(MLT)",
    "METERS(MTR)",
    "METRIC TONS(MTS)",
    "NUMBERS(NOS)",
    "PACKS(PAC)",
    "PIECES(PCS)",
    "PAIRS(PRS)",
    "QUINTAL(QTL)",
    "ROLLS(ROL)",
    "SETS(SET)",
    "SQUARE FEET(SQF)",
    "SQUARE METERS(SQM)",
    "SQUARE YARDS(SQY)",
    "TABLETS(TBS)",
    "TEN GROSS(TGM)",
    "THOUSANDS(THD)",
    "TONNES(TON)",
    "TUBES(TUB)",
    "US GALLONS(UGS)",
    "UNITS(UNT)",
    "YARDS(YDS)",
  ]
  SecondaryUnit = ProductUpdateComponent.Uni;
  Units = ProductUpdateComponent.Uni;
  subCategoryList: any;
  activeSubCategoryList: any;

  ProductBrand: any;
  productCustomFieldsDetails: any[];
  customFieldsList: any[];
  warehouseList: any;
  warehouseId: any;
  dropdownValue = [];
  customDropdownOptions: { [key: string]: { optionName: string, optionId: number }[] } = {};

  constructor(private commonService: CommonService,
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private router: Router,
    private titleCasePipe: TitleCasePipe,
    public dialogRef: MatDialogRef<ProductUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public ProductData: any,
    private route: ActivatedRoute,
    private toastService: ToastNotificationService,
    private warehouseService: WarehouseService,
    private location: Location,
    private cdRef: ChangeDetectorRef
  ) {
    this.productId = this.ProductData.productId;
    this.warehouseId = this.ProductData.warehouseId;
  }

  ngOnInit(): void {
    this.todaysDate = new Date(new Date().setDate(new Date().getDate()));
    this.todaysDate2 = new Date(new Date().setDate(new Date().getDate() + 1));
    this.ProductForm = this.formBuilder.group({
      itemName: [""],
      category: [""],
      itemCode: ["", [Validators.required, Validators.minLength(4), Validators.maxLength(8), Validators.pattern('^[a-zA-Z0-9]*$')]],
      generateBarcode: ["", [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern("^[0-9]*$")]],
      description: [null],
      hsn: [null],
      unit: [null],
      price: ["", [Validators.pattern(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/)]],
      gstRate: ["5",],
      secondaryUnitFlag: [],
      secondaryUnit: ["", Validators.required],
      conversionRate: [""],
      //openingStock: [],
      asOfDate: [""],
      lowStockWarning: [],
      lowStockUnit: [null, Validators.required],
      additionalCess: [null],
      supportType: ["GUARANTEE"],
      supportDuration: [null],
      expiry: [null],
      warehouse: [null],
      months: [null],
      brand: [null],
      section: [null],
      subCategoryId: [null],
      // gender: [null],
      color: [null],
      // ageGroup: [null,[Validators.pattern('^[a-zA-Z0-9]*$')]],
      gender: [null],
      ageGroup: [null],
      promoPrice: [null],  // type: double
      promoStartDate: [null],
      promoEndDate: [null],
      pricingDetails: new FormArray([this.createPricingDetails()]),
    });

    this.getCustomeFieldByProduct();
    this.getProductCategory();
    this.getProductBrand();
    this.getProductSubCategory();
    this.getGoodsById();
    // this.getWarehouseList();
  }

  // ngAfterViewInit() {
  //   this.getGoodsById();
  // }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes['promoPrice']) {
    //   this.getGoodsById();
    // }
  }

  onGenderChange(event) {
    this.updateAgeGroupValidators();
  }

  generateCustomFields(): void {
    console.log('in generateCustomFields function')
    if (!this.customFieldsList || !Array.isArray(this.customFieldsList)) {
      console.error('Custom fields list is not properly initialized or is not an array.');
      return;
    }

    // Loop through customFieldsList and create form controls dynamically
    this.customFieldsList.forEach(field => {
      if (field?.type === 'TEXT') {
        // For text fields
        const control = new FormControl(field?.ProductCustomFieldsDetails[0]?.optionName || '');
        this.ProductForm.addControl(field.fieldName, control);        
        // control.setValue(field?.ProductCustomFieldsDetails[0]?.optionName);

      } else if (field?.type === 'DROPDOWN' && field?.productCustomFieldOptions) {
        // For dropdown fields

        const dropdownOptions = this.generateDropdownOptions(field);
        // control.setValue(field?.ProductCustomFieldsDetails[0]?.optionId || '');

        const selectedOption = dropdownOptions.find(option => option.optionId === field?.ProductCustomFieldsDetails[0]?.optionId);
        console.log(selectedOption)
        // const control = new FormControl(35);
        // const control = new FormControl({ optionId: null, optionName: '' });
        const control = new FormControl(selectedOption || { optionId: null, optionName: '' });
        this.ProductForm.addControl(field.fieldName, control);
        // this.formBuilder.control(selectedOption)
        // Save the options separately if needed for further processing
        this.customDropdownOptions[field.fieldName] = dropdownOptions;
        
        // const dropdownField = this.generateDropdownField(field);
        console.log(this.customDropdownOptions[field.fieldName]);
        // Add the FormArray control for dropdown options
        // this.ProductForm.addControl(field?.fieldName, dropdownField);
      }
    });

    // Patch the form with initial values
    this.setValuesToCustomFields();
  }

  // Helper function to generate dropdown options
  generateDropdownOptions(field): { optionName: string, optionId: number }[] {
    return field?.productCustomFieldOptions
      ? field.productCustomFieldOptions.map(option => ({
          optionName: option.optionName,
          optionId: option.id
        }))
      : [];
  }

  setValuesToCustomFields() {
    // console.log("Welcome to setValues function", this.customFieldsList);
    if (this.customFieldsList.length > 0) {
      this.customFieldsList.forEach(field => {
        const control = this.ProductForm.get(field.fieldName);
  
        if (control) {
          if (field?.type === 'DROPDOWN' && field?.productCustomFieldOptions) {
            // For dropdown fields
            // control.setValue(field?.ProductCustomFieldsDetails[0]?.optionId || '');

            const selectedOption = this.getDropdownOptions(field).find(option => option.optionId === field?.ProductCustomFieldsDetails[0]?.optionId);
            console.log(selectedOption)
            control.setValue(selectedOption || { optionId: null, optionName: '' });
            // control.setValue(35);

          } else {
            // For other types
            control.setValue(field?.ProductCustomFieldsDetails[0]?.optionName || '');
          }
        }
      });
      // console.log(this.ProductForm)
    }
  }

  compareOptions(option1: any, option2: any): boolean {
    console.log('compare options: ', option1, option2)
    return option1 && option2 ? option1.optionId === option2.optionId : option1 === option2;
  }

  // setValuesToCustomFields(){
  //   if(this.customFieldsList.length > 0) {
  //     this.customFieldsList.forEach(field => {
  //       const control = this.ProductForm.get(field?.fieldName);
  //       if (control) {
  //         if (field?.type === 'DROPDOWN' && field?.productCustomFieldOptions) {
  //           // For dropdown fields, initialize with an empty array
  //           // console.log({optionId : field.productCustomFieldOption?.id, optionName: field.optionName})
  //             control.setValue(field?.ProductCustomFieldsDetails[0]?.optionId);
  //             // control.setValue({value: null, options: [{optionId : field.productCustomFieldOption?.id, optionName: field.optionName}]});
  //           // } else {
  //           // }
  //         } else {
  //           // For other types, use a simpler control
  //           control.setValue(field?.ProductCustomFieldsDetails[0]?.optionName);
  //           // this.ProductForm.addControl(field.fieldName, this.formBuilder.control(''));
  //         }
  //       } else {
  //         if (field?.type === 'DROPDOWN') {
  //           // For dropdown fields, initialize with an empty array
  //           this.ProductForm.addControl(field?.fieldName, this.formBuilder.control([{optionId : field.ProductCustomFieldsDetails[0]?.optionId, optionName: field?.ProductCustomFieldsDetails[0]?.optionName}]));
  //           // this.ProductForm.addControl(field?.fieldName, this.formBuilder.control({ value: null, options: [] }));
  //         }else {
  //           this.ProductForm.addControl(field?.fieldName, this.formBuilder.control(''));
  //         }
  //       }
  //   });

  //   // Loop through the custom fields data
  //   // for (const customField of this.productCustomFieldsDetails) {
  //   //   console.log(customField)
  //   //   // Check if the field name exists in the form controls
  //   //   if (this.ProductForm.get(customField.fieldName)) {
  //   //     // Patch the value to the respective form control
  //   //     console.log(this.ProductForm.get(customField.fieldName))
  //   //     console.log(customField)
  //   //     this.ProductForm.get(customField.fieldName)?.patchValue(customField?.optionName);
  //   //   } else {
  //   //     // Add the missing field to the form dynamically
  //   //     if (customField.type === 'DROPDOWN') {
  //   //       // For dropdown fields, initialize with an empty array
  //   //       this.ProductForm.addControl(customField.fieldName, this.formBuilder.control({ value: null, options: [] }));
  //   //     } else {
  //   //       // For other types, use a simpler control
  //   //       this.ProductForm.addControl(customField.fieldName, this.formBuilder.control(customField?.value || ''));
  //   //     }
  //   //   }
  //   // }
  // }
  // }
  
  updateAgeGroupValidators() {
    const gender = this.ProductForm.get('gender').value.toLowerCase(); // Ensure lowercase for comparison
    const ageGroupControl = this.ProductForm.get('ageGroup');

    if (gender === 'men') {
      ageGroupControl.setValidators([Validators.min(18), Validators.max(100)]);
    } else if (gender === 'women') {
      ageGroupControl.setValidators([Validators.min(16), Validators.max(100)]);
    } else if (gender === 'boy') {
      ageGroupControl.setValidators([Validators.min(0), Validators.max(18)]);
    } else if (gender === 'girl') {
      ageGroupControl.setValidators([Validators.min(0), Validators.max(16)]);
    } else if (gender === 'unisex') { // Use lowercase "unisex"
      ageGroupControl.setValidators([Validators.min(18), Validators.max(100)]); // Define the age range for "Unisex" as per your requirement
    } else {
      ageGroupControl.setValidators(null); // Clear validators if no gender is selected
    }

    ageGroupControl.updateValueAndValidity();
  }
  // generateCustomFields(): void {
  //   if (!this.customFieldsList || !Array.isArray(this.customFieldsList)) {
  //     console.error('Custom fields list is not properly initialized or is not an array.');
  //     return;
  //   }

  //   // Create an object to store productCustomFieldOptions based on field name
  //   const dropdownOptionsMap: { [fieldName: string]: any[] } = {};
  //   // this.customFieldsList.forEach(field => {
  //   //   if (field.type === 'text') {
  //   //     // For text fields
  //   //     this.ProductForm.addControl(field.fieldName, this.formBuilder.control(''));
  //   //   } else if (field.type === 'dropdown' && field.productCustomFieldOptions) {
  //   //     // For dropdown fields
  //   //     const dropdownField = this.generateDropdownField(field);
  //   //     this.ProductForm.addControl(field.fieldName, dropdownField);
  //   //   }
  //   // });
  //   // Loop through customFieldsList and create form controls dynamically
  //   this.customFieldsList.forEach(field => {
  //     if (field.type === 'text') {
  //       // For text fields
  //       this.ProductForm.addControl(field.fieldName, this.formBuilder.control(''));
  //     } else if (field.type === 'dropdown' && field.productCustomFieldOptions) {
  //       // For dropdown fields
  //       this.ProductForm.addControl(field.fieldName, this.formBuilder.control(''));

  //       // Store productCustomFieldOptions based on field name
  //       dropdownOptionsMap[field.fieldName] = field.productCustomFieldOptions;

  //       // const dropdownField = this.generateDropdownField(field);
  //       // // Add the FormArray control for dropdown options
  //       // this.ProductForm.addControl(field.fieldName, dropdownField);
  //     }
  //   });
  //   console.log(dropdownOptionsMap)
  //   // Store the dropdown options map in a variable accessible to the template
  //   this.dropdownOptionsMap = dropdownOptionsMap;
  // }
  // generateCustomFields(): void {
  //   if (!this.customFieldsList || !Array.isArray(this.customFieldsList)) {
  //     console.error('Custom fields list is not properly initialized or is not an array.');
  //     return;
  //   }

  //   this.customFieldsList.forEach(field => {
  //     if (field.type === 'text') {
  //       // For text fields
  //       this.ProductForm.addControl(field.fieldName, this.formBuilder.control(''));
  //     } else if (field.type === 'dropdown' && field.productCustomFieldOptions) {
  //       // For dropdown fields
  //       const dropdownField = this.generateDropdownField(field);
  //       // Add the FormArray control for dropdown options
  //       this.ProductForm.setControl(field.fieldName, dropdownField.get('options'));
  //       console.log(this.ProductForm.get(field.fieldName));
  //     }
  //   });
  // }

  // function to handle dropdown control creation
  // generateDropdownField(field: any): FormGroup {
  //   const dropdownOptions = this.formBuilder.array(
  //     field.productCustomFieldOptions.map(option =>
  //       this.formBuilder.group({
  //         optionName: [option.optionName, Validators.required],
  //         optionId: [option.id]
  //       })
  //     )
  //   );
  //   //  returns a FormGroup for the dropdown field, including the FormArray for options.
  //   return this.formBuilder.group({
  //     options: dropdownOptions
  //   });
  // }
  generateDropdownField(field: any) {
    // return this.formBuilder.array(
    //   field?.ProductCustomFieldsDetails.map(option =>
    //     this.formBuilder.control({
    //       optionName: option?.optionName,
    //       optionId: option?.optionId
    //     })
    //   )
    // )
    return field?.productCustomFieldOptions.map(option =>
        this.formBuilder.control({
          optionName: option?.optionName,
          optionId: option?.id
        })
    );
  }

  // generateDropdownField(field: any): FormGroup {
  //   return this.formBuilder.group({
  //     options: this.formBuilder.array([])
  //   });
  // }

  // Function to get dropdown options for the template
  getDropdownOptions(field): { optionName: string, optionId: number }[] {
    return this.customDropdownOptions[field.fieldName] || [];
  }

  // getDropdownOptions(field: any): any[] {
  //   return field?.productCustomFieldOptions 
  //     ? field.productCustomFieldOptions.map(option => ({
  //         optionName: option?.optionName,
  //         optionId: option?.id
  //       }))
  //     : [];
        
  //   // const formArray = this.ProductForm.get(field?.fieldName) as FormArray;
  //   // return formArray ? formArray.value : [];
  // }

  getCustomeFieldByProduct(){
    // this.productService.getCustomFields({
    //   data:{},
    //   userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
    //   size: 1000,
    //   page: 1,
    //   search:'',
    //   isActive: ''
    // }).then(async (res) => {
    //   // Filter and assign active custom fields to customFieldsList
    //   if (res.data) {
    //     console.log(res.data.pageData)
    //     this.customFieldsList = await res.data.pageData.filter(field => field.isActive);
    //     // Call the function once data is assigned
    //     this.generateCustomFields();
    //   }
    // }, (err) => {
    //   if (err.error.expose) {
    //     this.toastService.toastMsg({
    //       title: "Error",
    //       content: this.titleCasePipe.transform(err.error.error_message),
    //     });
    //   }
    //   else {
    //     this.toastService.toastMsg({
    //       title: "Error",
    //       content: "Something Went Wrong.",
    //     })
    //   }
    // })
    this.productService.getCustomeFieldByProduct({}, this.productId, this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id).then(async (res) => {
      // Filter and assign active custom fields to customFieldsList
      if (res.data) {
        this.customFieldsList = await res.data.filter(field => field?.isActive);
        // Call the function once data is assigned
        this.generateCustomFields();
        // this.setValuesToCustomFields();
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      }
    })
  }

  getProductCategory() {
    this.productService.getCategory({
      data:{},
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search: '',
      isActive: ''
    }).then((res: any) => {
      this.productCategory = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })

      }
    })
  }

  getProductBrand() {
    this.productService.getBrandList({
      data:{},
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search:'',
      isActive: true
    }).then((res: any) => {
      this.ProductBrand = res.data.pageData;
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        })
      };
    })
  }

  getProductSubCategory() {
    this.productService.getSubCategory({
      data:{},
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
      size: 1000,
      page: 1,
      search:'',
      categoryId: this.ProductForm.controls.category.value,
      isActive: ''
    }).then((res: any) => {
      if (res.success) {
        this.subCategoryList = res.data.pageData;
        this.activeSubCategoryList = this.subCategoryList ?.filter(subcategory => subcategory.isActive === true);

      }
    },
      (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong.",
          });
        }
      })
  }

  get f() {
    return this.ProductForm.controls;
  }

  getUnit(event) {
    this.unit = event.source.value;
    console.log("unit", this.unit)
    const regex = /\(([^)]+)\)/;
    const match = this.unit.match(regex);
    if (match) {
      const result4 = match[1]; // The matched text inside the parentheses
      console.log(result4);
      this.primarySelectUnit = result4;
    } else {
      console.log("Substring not found.");
    }
    this.SecondaryUnit = this.SecondaryUnit.filter((e) => e !== this.unit);
  }

  private filterSecondary() {
    const selectedPrimary = this.SecondaryUnit.filter((us) => this.unit === us);
    const index = this.SecondaryUnit.indexOf(this.unit);
    if (index > -1) {
      this.SecondaryUnit.splice(index, 1);
    }
  }

  showSecondaryUnitField(checked: boolean) {
    this.isDisabled = checked;
    if (checked) {
      this.ProductForm.controls['secondaryUnit'].setValidators([Validators.required]);
      this.ProductForm.controls['conversionRate'].setValidators([Validators.required, Validators.min(1)]);
    } else {
      this.ProductForm.controls['secondaryUnit'].clearValidators();
      this.ProductForm.controls['conversionRate'].clearValidators();
    }
    this.ProductForm.controls['secondaryUnit'].updateValueAndValidity();
    this.ProductForm.controls['conversionRate'].updateValueAndValidity();
  }

  getEnableStockValue(checked: boolean) {
    this.isStockToggle = checked;
    if (checked) {
      this.ProductForm.controls['lowStockUnit'].setValidators([Validators.required, Validators.min(1)]);
    } else {
      this.ProductForm.controls['lowStockUnit'].clearValidators();
    }
    this.ProductForm.controls['lowStockUnit'].updateValueAndValidity();
  }

  getSecondaryUnit(event) {
    this.unitSecondaryValue = event.source.value;
    const regex = /\(([^)]+)\)/;
    const match = this.unitSecondaryValue.match(regex);

    if (match) {
      const result3 = match[1]; // The matched text inside the parentheses
      this.secondarySelectUnit = result3;
    } else {
    }
  }

  getCategoryType(event) {
    this.catId = event.source.value;
    if (this.catId == 2 || this.catId == 4 || this.catId == 8 || this.catId == 9 || this.catId == 10 || this.catId == 11 || this.catId == 14 || this.catId == 15) {
      this.ProductForm.get('expiry').value = new Date();
    }

    this.getProductSubCategory();
  }

  show(id) {
    if (id === 5) {
      this.showProductType = Constants.USER.GOODS;
      this.serviceToggle = false;
    } else if (id === 6) {
      this.showProductType = Constants.USER.SERVICE;
      this.serviceToggle = true;
    }
  }

  // getWarehouseList() {
  //   this.warehouseService.getAllWarehouse({
  //     data:{},
  //     userId: this.currentUser.id,
  //     size: 1000,
  //     page: 1,
  //     search:'',
  //   }).then((res: any) => {
  //     this.warehouseList = res.data.pageData;
  //   }, (err) => {
  //     if (err.error.expose) {
  //       this.toastService.toastMsg({
  //         title: "Error",
  //         content: this.titleCasePipe.transform(err.error.error_message),
  //       });
  //     }
  //     else {
  //       this.toastService.toastMsg({
  //         title: "Error",
  //         content: "Something Went Wrong.",
  //       })
  //     };
  //   })
  // }

  async getGoodsById() {
    // setTimeout(() => {
    try{
      const res = await this.productService.getGoodsById({}, this.productId)
      this.imageSrcSeven = res.data.coverImage ? res.data.coverImage : null;
        this.fetchImages = res.data.productsImages;
        // this.productCustomFieldsDetails = res.data.ProductCustomFieldsDetails
        // console.log(this.productCustomFieldsDetails);
        this.maxFiles = res.data.productsImages.length;
        this.unit = res.data.unit;
        this.catId = res.data.categoryId ? res.data.categoryId : null,
          await this.ProductForm.patchValue({
            promoPrice: res.data?.promoPrice,  // type: double
            promoStartDate: res.data?.promoStartDate ? (moment(res.data?.promoStartDate).format("DD MMM YYYY")) : moment(this.todaysDate).format("DD MMM YYYY"),
            promoEndDate: res.data?.promoEndDate ? (moment(res.data?.promoEndDate).format("DD MMM YYYY")) : moment(this.todaysDate).format("DD MMM YYYY"),
            itemName: res.data.itemName,
            category: res.data.categoryId ? res.data.categoryId : null,
            itemCode: res.data.itemCode,
            generateBarcode: res.data.barcode,
            description: res.data.description ? res.data.description : null,
            hsn: res.data.hsn,
            unit: res.data.unit,
            price: res.data.isPromoActive ? res.data.OldSalePrice : res.data.salePrice,
            gstRate: res.data.gstRate,
            secondaryUnitFlag: res.data.secondaryUnitFlag,
            secondaryUnit: res.data.secondaryUnit,
            conversionRate: res.data.conversionRate,
            //  openingStock: res.data.stock,
            asOfDate: (moment(res.data.asOfDate).format("DD MMM YYYY")),
            expiry: res.data.expiryDate ? (moment(res.data.expiryDate).toDate()) : null,
            lowStockWarning: res.data.lowStockWarning,
            lowStockUnit: res.data.lowStockUnits,
            additionalCess: res.data.additionalCess,
            supportType: res.data.supportType,
            warehouse: res.data.warehouse,
            // used: String(res.data.used),
            brand: res.data.brand ? res.data.brand.id : null,
            section: res.data.section,
            subCategoryId: res.data.subCategory ? res.data.subCategory.id : null,
            gender: res.data.gender,
            color: res.data.color,
            ageGroup: res.data.ageGroup,
          });
          
          // After patching, trigger change detection
          this.cdRef.detectChanges();
          // const partyList = res.data.parties_additionals;
          
          // const partyListLength = partyList.length;
          // if (partyListLength) {
            //   for (let i = 0; i < partyListLength; i++) {
              //     this.pricingDetailsControls.push(
                //       new FormGroup({
                  //         cost: new FormControl(partyList[i].department),
                  //         margin: new FormControl(partyList[i].additionalMobile),
                  //         directExpense: new FormControl(partyList[i].additionalContact),
                  //         mrp: new FormControl(partyList[i].mrp),
          //         additionalId: new FormControl(partyList[i].id),
          //       })
          //     )
          //   }
          // }
          // console.log(this.productCustomFieldsDetails)

          // Loop through customFieldsList and create form controls dynamically

        if (!res.data.secondaryUnitFlag) {
          this.ProductForm.get('secondaryUnit').clearValidators();
          this.ProductForm.get('secondaryUnit').updateValueAndValidity();
        }

        if (!res.data.lowStockWarning) {
          this.ProductForm.get('lowStockUnit').clearValidators();
          this.ProductForm.get('lowStockUnit').updateValueAndValidity();
        }

        this.isStockToggle = res.data.lowStockWarning;
        this.isDisabled = res.data.secondaryUnitFlag;
        
        const regex = /\(([^)]+)\)/;
        const match = this.unit.match(regex);
        
        if (match) {
          const result1 = match[1]; // The matched text inside the parentheses
          this.primarySelectUnit = result1;
        } else {
          console.log("Substring not found.");
        }
        
        this.unitsecondary = res.data.secondaryUnit;
        if (this.unitsecondary !== null) {
          const regex = /\(([^)]+)\)/;
          const match = this.unitsecondary.match(regex);
          if (match) {
            const result2 = match[1]; // The matched text inside the parentheses
            this.secondarySelectUnit = result2;
          } else {
          }
        }
        
        this.SecondaryUnit = this.SecondaryUnit.filter((e) => e !== this.unit);
        if (res.data.supportDuration) {
          const inputString = res.data.supportDuration;
          if (inputString  && typeof inputString === 'string'){
            const number = inputString.match(/\d+/)?.[0];
            const text = inputString.replace(number || '', '');
            if (number !== 'undefined') {
              this.ProductForm.get('supportDuration').setValue(number);
            }
            if ((text !== 'null')) {
            this.ProductForm.get('months').setValue(text);
            this.warrantyType = text;
          }else{
            this.ProductForm.get('months').setValue("Days");
            this.warrantyType="Days"
          }
        }
      }else{
        this.ProductForm.get('months').setValue("Days");
        this.warrantyType="Days"
      }
      // });
    } catch (err){
      console.log(err)
    }
    // }, 0);
  }

  selectWarrantyType(event) {
    this.warrantyType = event.value
  }

  // getProductFormFieldValue(field: any): any {
  //   const fieldValue = this.ProductForm.controls[field.fieldName].value;
  //   console.log(field)
  //   // Check if the field is a dropdown
  //   if (field.type === 'dropdown') {
  //     console.log(fieldValue);
  //     console.log(fieldValue.value);
  //     // If it's a dropdown, extract the selected option's ID and optionName
  //     const selectedOption = fieldValue ? fieldValue.find(opt => opt.optionId === fieldValue.value) : null;
  //     console.log(selectedOption)
  //     return {
  //       optionId: selectedOption ? selectedOption.optionId : null,
  //       optionName: selectedOption ? selectedOption.optionName : null,
  //     };
  //   }

  //   // If it's not a dropdown, return the value as is
  //   return fieldValue;
  // }
  // getProductFormFieldValue(field: any): any {
  //   const fieldValue = this.ProductForm.controls[field.fieldName];

  //   // Check if the field is a dropdown
  //   if (field.type === 'dropdown') {
  //     console.log(fieldValue);
  //     console.log(fieldValue.value);

  //     // If it's a dropdown, find the selected option based on the form control value
  //     // const selectedOption = formControl.value.options.find(option => option.optionId === formControl.value);
  //     const selectedOption = fieldValue ? fieldValue.value.find(opt => opt.optionId === fieldValue.value) : null;
  //     console.log(selectedOption);

  //     // Return the selected option or null if none is found
  //     return {
  //       optionId: selectedOption ? selectedOption.optionId : null,
  //       optionName: selectedOption ? selectedOption.optionName : null,
  //     };
  //   }

  //   // If it's not a dropdown, return the value as is
  //   return fieldValue;
  // }

  createCustomFieldData(field: any, fieldValue: any): any {
    return {
      fieldName: field.fieldName,
      value: fieldValue,
      productCustomFieldId: field.id,
      userId: this.currentUser.parentId ? this.currentUser.parentId : this.currentUser.id,
    };
  }

  onOptionClick(field: any, option: any): void {
    // Set the selected option manually
    const formArray = this.ProductForm.get(field.fieldName) as FormArray;
    console.log(this.ProductForm)
    console.log(field, option)
    // console.log(this.ProductForm.get(field.fieldName))
    // Clear existing values in the FormArray
    // while (formArray.length) {
    //   formArray.removeAt(0);
    // }
    
    let obj = {
      field: field,
      value: this.formBuilder.control(option)
    }
    console.log(this.ProductForm)
    this.dropdownValue.push(obj)
    // formArray.push(this.formBuilder.control(option));
    // this.ProductForm.get(field.fieldName).setValue(option);
  }

  onDropdownChange(field: any): void {
    const selectedOptionId = this.ProductForm.get(field.fieldName).value;
    // console.log(selectedOptionId)

    // Find the selected option in productCustomFieldOptions
    const selectedOption = field.productCustomFieldOptions.find(option => option.id === selectedOptionId);
    //   console.log('Selected Option:', selectedOption);

  }

  updateGoods() {
    console.log(this.ProductForm)
    this.submitted = true;
    if (this.ProductForm.invalid) {
      this.toastService.toastMsg({
        title: "Error",
        content: "Fill All Required Fields.",
      });
      return false;
    }
    else {
      if (this.catId == 2 || this.catId == 4 || this.catId == 8 || this.catId == 9 || this.catId == 10 || this.catId == 11 || this.catId == 14 || this.catId == 15) {
        let data = {
          "goodDetails": {
            "itemName": this.ProductForm.controls.itemName.value,
            "categoryId": this.ProductForm.controls.category.value,
            "itemCode": this.ProductForm.controls.itemCode.value,
            "barcode": this.ProductForm.controls.generateBarcode.value,
            "description": this.ProductForm.controls.description.value ? this.ProductForm.controls.description.value : null,
            "hsn": this.ProductForm.controls.hsn.value,
            "salePrice": this.ProductForm.controls.price.value,
            "gstRate": this.ProductForm.controls.gstRate.value,
            "unit": this.ProductForm.controls.unit.value,
            "secondaryUnitFlag": this.ProductForm.controls.secondaryUnitFlag.value,
            "secondaryUnit": this.ProductForm.controls.secondaryUnit.value,
            "conversionRate": this.ProductForm.controls.conversionRate.value,
            "asOfDate": moment(this.ProductForm.controls.asOfDate.value, "DD MMM YYYY").format("YYYY-MM-DD"),
            "lowStockWarning": this.ProductForm.controls.lowStockWarning.value,
            "lowStockUnits": this.ProductForm.controls.lowStockUnit.value,
            "expiryDate": this.ProductForm.controls.expiry.value ? moment(this.ProductForm.controls.expiry.value, "DD MMM YYYY").format("YYYY-MM-DD") : null,
            "additionalCess": this.ProductForm.controls.additionalCess.value,
            "supportType": this.ProductForm.controls.supportType.value,
            "supportDuration":
                this.ProductForm.controls.supportDuration.value?this.ProductForm.controls.supportDuration.value.toString()
                + (this.warrantyType?this.warrantyType.toString():null) : null,
            "coverImage": this.productPicUrl ?  this.productPicUrl : null,
                // "used": this.ProductForm.controls.used.value == "false" ? false : true,
            "brandId": this.ProductForm.controls.brand.value ? `${this.ProductForm.controls.brand.value}` : null,
            "section": this.ProductForm.controls.section.value,
            "subCategoryId": this.ProductForm.controls.subCategoryId.value ? `${this.ProductForm.controls.subCategoryId.value}` : null,
            "gender": this.ProductForm.controls.gender.value ? this.ProductForm.controls.gender.value : null,
            "color": this.ProductForm.controls.color.value,
            "ageGroup": this.ProductForm.controls.ageGroup.value,
            "promoPrice": this.ProductForm.controls.promoPrice.value ? this.ProductForm.controls.promoPrice.value : null,
            "promoStartDate": this.ProductForm.controls.promoStartDate.value ? moment(this.ProductForm.controls.promoStartDate.value, "DD MMM YYYY").utc().format("YYYY-MM-DD") : null,
            "promoEndDate": this.ProductForm.controls.promoEndDate.value ? moment(this.ProductForm.controls.promoEndDate.value, "DD MMM YYYY").utc().format("YYYY-MM-DD") : null,
          },
        //  "stock": this.ProductForm.controls.openingStock.value,
          "warehouseId": `${this.warehouseId}`,
          "productImage": this.imageArray ? this.imageArray : this.blankImage,
          "fields": [],
          "dropdownFields": []
        }

        let noDataImage2 = {
          "goodDetails": {
            "itemName": this.ProductForm.controls.itemName.value,
            "categoryId": this.ProductForm.controls.category.value,
            "itemCode": this.ProductForm.controls.itemCode.value,
            "barcode": this.ProductForm.controls.generateBarcode.value,
            "description": this.ProductForm.controls.description.value ? this.ProductForm.controls.description.value : null,
            "hsn": this.ProductForm.controls.hsn.value,
            "salePrice": this.ProductForm.controls.price.value,
            "gstRate": this.ProductForm.controls.gstRate.value,
            "unit": this.ProductForm.controls.unit.value,
            "secondaryUnitFlag": this.ProductForm.controls.secondaryUnitFlag.value,
            "secondaryUnit": this.ProductForm.controls.secondaryUnit.value,
            "conversionRate": this.ProductForm.controls.conversionRate.value,
            "asOfDate": moment(this.ProductForm.controls.asOfDate.value, "DD MMM YYYY").format("YYYY-MM-DD"),
            "lowStockWarning": this.ProductForm.controls.lowStockWarning.value,
            "lowStockUnits": this.ProductForm.controls.lowStockUnit.value,
            "expiryDate": this.ProductForm.controls.expiry.value ? moment(this.ProductForm.controls.expiry.value, "DD MMM YYYY").format("YYYY-MM-DD") : null,
            "additionalCess": this.ProductForm.controls.additionalCess.value,
            "supportType": this.ProductForm.controls.supportType.value,
            "supportDuration":
                this.ProductForm.controls.supportDuration.value?this.ProductForm.controls.supportDuration.value.toString()
                + (this.warrantyType?this.warrantyType.toString():null) : null,
            // "used": this.ProductForm.controls.used.value == "false" ? false : true,
            "brandId": this.ProductForm.controls.brand.value ? `${ this.ProductForm.controls.brand.value }` : null,
            "section": this.ProductForm.controls.section.value,
            "subCategoryId": this.ProductForm.controls.subCategoryId.value ? `${this.ProductForm.controls.subCategoryId.value}` : null,
            "gender": this.ProductForm.controls.gender.value ? this.ProductForm.controls.gender.value : null,
            "color": this.ProductForm.controls.color.value,
            "ageGroup": this.ProductForm.controls.ageGroup.value,
            "promoPrice": this.ProductForm.controls.promoPrice.value ? this.ProductForm.controls.promoPrice.value : null,
            "promoStartDate": this.ProductForm.controls.promoStartDate.value ? moment(this.ProductForm.controls.promoStartDate.value, "DD MMM YYYY").utc().format("YYYY-MM-DD") : null,
            "promoEndDate": this.ProductForm.controls.promoEndDate.value ? moment(this.ProductForm.controls.promoEndDate.value, "DD MMM YYYY").utc().format("YYYY-MM-DD") : null,
          },
          //"stock": this.ProductForm.controls.openingStock.value,
          "warehouseId": `${this.warehouseId}`,
          "productImage": this.imageArray ? this.imageArray : this.blankImage,
          "fields": [],
          "dropdownFields": []
        }

        if (this.coverImageChange === true) {
          // Iterate over existing custom fields
          if (this.productCustomFieldsDetails && this.productCustomFieldsDetails.length > 0) {
            this.productCustomFieldsDetails.forEach(field => {
              // const fieldValue = this.getProductFormFieldValue(field);
              const fieldValue = this.ProductForm.controls[field.fieldName].value;
              const customFieldData = this.createCustomFieldData(field, fieldValue);
              if(field.type === 'TEXT'){
                data.fields.push(customFieldData);
              } 
            });
            // this.dropdownValue.map(item => {
            //   const customFieldData = this.createCustomFieldData(item?.field, item?.value.value);
            //   data.dropdownFields.push(customFieldData);
            // })

            // Handle dropdown values separately
            this.dropdownValue.forEach(item => {
              // Ensure value is an array if it's a dropdown
              const fieldValueArray = Array.isArray(item.value.value) ? item.value.value : [item.value.value];
              const customFieldData = this.createCustomFieldData(item.field, fieldValueArray);
              data.dropdownFields.push(customFieldData);
            });
          }

          // Iterate over dynamically generated custom fields
          if (this.customFieldsList && this.customFieldsList.length > 0) {
            this.customFieldsList.forEach(field => {
              // const fieldValue = this.getProductFormFieldValue(field);
              const fieldValue = this.ProductForm.controls[field.fieldName].value;
              const customFieldData = this.createCustomFieldData(field, fieldValue);
              if(field.type === 'TEXT'){
                data.fields.push(customFieldData);
              }                        
            });
            // this.dropdownValue.map(item => {
            //   const customFieldData = this.createCustomFieldData(item?.field, item?.value.value);
            //   data.dropdownFields.push(customFieldData);
            // })

            // Handle dropdown values separately
            this.dropdownValue.forEach(item => {
              // Ensure value is an array if it's a dropdown
              const fieldValueArray = Array.isArray(item.value.value) ? item.value.value : [item.value.value];
              const customFieldData = this.createCustomFieldData(item.field, fieldValueArray);
              data.dropdownFields.push(customFieldData);
            });
          }

          this.productService.updateProduct(data, this.productId).then((res) => {
            if (res) {
              this.toastService.toastMsg({
                title: "Success",
                content: "Product Updated Successfully!",
              });
              this.productService.notifyProductAdded();
              this.dialogRef.close();
            }

          }, (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went To Wrong.",
              });
            }
          })
        }
        else {
          // Iterate over existing custom fields
          if (this.productCustomFieldsDetails && this.productCustomFieldsDetails.length > 0) {
            this.productCustomFieldsDetails.forEach(field => {
              const fieldValue = this.ProductForm.controls[field.fieldName].value;
              // const fieldValue = this.getProductFormFieldValue(field);
              const customFieldData = this.createCustomFieldData(field, fieldValue);
              if(field.type === 'TEXT'){
                noDataImage2.fields.push(customFieldData);
              }  
            });
            // this.dropdownValue.map(item => {
            //   const customFieldData = this.createCustomFieldData(item?.field, item?.value.value);
            //   noDataImage2.dropdownFields.push(customFieldData);
            // })

            // Handle dropdown values separately
            this.dropdownValue.forEach(item => {
              // Ensure value is an array if it's a dropdown
              const fieldValueArray = Array.isArray(item.value.value) ? item.value.value : [item.value.value];
              const customFieldData = this.createCustomFieldData(item.field, fieldValueArray);
              noDataImage2.dropdownFields.push(customFieldData);
            });
          }

          // Iterate over dynamically generated custom fields
          if (this.customFieldsList && this.customFieldsList.length > 0) {
            this.customFieldsList.forEach(field => {
              // const fieldValue = this.getProductFormFieldValue(field);
              const fieldValue = this.ProductForm.controls[field.fieldName].value;
              const customFieldData = this.createCustomFieldData(field, fieldValue);
              if(field.type === 'TEXT'){
                noDataImage2.fields.push(customFieldData);
              }           
            });
            // this.dropdownValue.map(item => {
            //   const customFieldData = this.createCustomFieldData(item?.field, item?.value.value);
            //   noDataImage2.dropdownFields.push(customFieldData);
            // })
            // Handle dropdown values separately
            this.dropdownValue.forEach(item => {
              // Ensure value is an array if it's a dropdown
              const fieldValueArray = Array.isArray(item.value.value) ? item.value.value : [item.value.value];
              const customFieldData = this.createCustomFieldData(item.field, fieldValueArray);
              noDataImage2.dropdownFields.push(customFieldData);
            });
          }

          if (!this.f.promoPrice.value && !(this.f.promoPrice.value > 0)) {
            delete noDataImage2.goodDetails.promoStartDate;
            delete noDataImage2.goodDetails.promoEndDate;
            delete data.goodDetails.promoStartDate;
            delete data.goodDetails.promoEndDate;
          }
          this.productService.updateProduct(noDataImage2, this.productId).then((res) => {
            if (res) {
              this.toastService.toastMsg({
                title: "Success",
                content: "Product Updated Successfully!",
              });
              this.productService.notifyProductAdded();
              this.dialogRef.close();
            }

          }, (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went To Wrong.",
              });
            }
          })
        }
      }
      else {
        let data = {
          "goodDetails": {
            "itemName": this.ProductForm.controls.itemName.value,
            "categoryId": this.ProductForm.controls.category.value,
            "itemCode": this.ProductForm.controls.itemCode.value,
            "barcode": this.ProductForm.controls.generateBarcode.value,
            "description": this.ProductForm.controls.description.value ? this.ProductForm.controls.description.value : null,
            "hsn": this.ProductForm.controls.hsn.value,
            "salePrice": this.ProductForm.controls.price.value,
            "gstRate": this.ProductForm.controls.gstRate.value,
            "unit": this.ProductForm.controls.unit.value,
            "secondaryUnitFlag": this.ProductForm.controls.secondaryUnitFlag.value,
            "secondaryUnit": this.ProductForm.controls.secondaryUnit.value,
            "conversionRate": this.ProductForm.controls.conversionRate.value,
            "asOfDate": moment(this.ProductForm.controls.asOfDate.value, "DD MMM YYYY").format("YYYY-MM-DD"),
            "lowStockWarning": this.ProductForm.controls.lowStockWarning.value,
            "lowStockUnits": this.ProductForm.controls.lowStockUnit.value,
            "additionalCess": this.ProductForm.controls.additionalCess.value,
            "supportType": this.ProductForm.controls.supportType.value,
            "supportDuration":
                this.ProductForm.controls.supportDuration.value?this.ProductForm.controls.supportDuration.value.toString()
                + (this.warrantyType?this.warrantyType.toString():null) : null,
            "brandId": this.ProductForm.controls.brand.value ? `${this.ProductForm.controls.brand.value}` : null,
            "section": this.ProductForm.controls.section.value,
            "subCategoryId": this.ProductForm.controls.subCategoryId.value ? `${this.ProductForm.controls.subCategoryId.value}` : null,
            "gender": this.ProductForm.controls.gender.value ? this.ProductForm.controls.gender.value : null,
            "color": this.ProductForm.controls.color.value,
            "ageGroup": this.ProductForm.controls.ageGroup.value,
            // "used": this.ProductForm.controls.used.value == "false" ? false : true,
            "coverImage": this.productPicUrl ?  this.productPicUrl : null,
            "promoPrice": this.ProductForm.controls.promoPrice.value ? this.ProductForm.controls.promoPrice.value : null,
            "promoStartDate": this.ProductForm.controls.promoStartDate.value ? moment(this.ProductForm.controls.promoStartDate.value, "DD MMM YYYY").utc().format("YYYY-MM-DD") : null,
            "promoEndDate": this.ProductForm.controls.promoEndDate.value ? moment(this.ProductForm.controls.promoEndDate.value, "DD MMM YYYY").utc().format("YYYY-MM-DD") : null,
          },
        //  "stock": this.ProductForm.controls.openingStock.value,
          "warehouseId": `${this.warehouseId}`,
          "productImage": this.imageArray ? this.imageArray : this.blankImage,
          "fields": [],
          "dropdownFields": []
        }

        let noDataImage2 = {
          "goodDetails": {
            "itemName": this.ProductForm.controls.itemName.value,
            "categoryId": this.ProductForm.controls.category.value,
            "itemCode": this.ProductForm.controls.itemCode.value,
            "barcode": this.ProductForm.controls.generateBarcode.value,
            "description": this.ProductForm.controls.description.value ? this.ProductForm.controls.description.value : null,
            "hsn": this.ProductForm.controls.hsn.value,
            "salePrice": this.ProductForm.controls.price.value,
            "gstRate": this.ProductForm.controls.gstRate.value,
            "unit": this.ProductForm.controls.unit.value,
            "secondaryUnitFlag": this.ProductForm.controls.secondaryUnitFlag.value,
            "secondaryUnit": this.ProductForm.controls.secondaryUnit.value,
            "conversionRate": this.ProductForm.controls.conversionRate.value,
            "asOfDate": moment(this.ProductForm.controls.asOfDate.value, "DD MMM YYYY").format("YYYY-MM-DD"),
            "lowStockWarning": this.ProductForm.controls.lowStockWarning.value,
            "lowStockUnits": this.ProductForm.controls.lowStockUnit.value,
            "additionalCess": this.ProductForm.controls.additionalCess.value,
            "supportType": this.ProductForm.controls.supportType.value,
            "supportDuration":
                this.ProductForm.controls.supportDuration.value?this.ProductForm.controls.supportDuration.value.toString()
                + (this.warrantyType?this.warrantyType.toString():null) : null,
            "brandId": this.ProductForm.controls.brand.value ? `${ this.ProductForm.controls.brand.value}` : null,
            "section": this.ProductForm.controls.section.value,
            "subCategoryId": this.ProductForm.controls.subCategoryId.value ? `${this.ProductForm.controls.subCategoryId.value}` : null,
            "gender": this.ProductForm.controls.gender.value ? this.ProductForm.controls.gender.value : null,
            "color": this.ProductForm.controls.color.value,
            "ageGroup": this.ProductForm.controls.ageGroup.value,
            "promoPrice": this.ProductForm.controls.promoPrice.value ? this.ProductForm.controls.promoPrice.value : null,
            "promoStartDate": this.ProductForm.controls.promoStartDate.value ? moment(this.ProductForm.controls.promoStartDate.value, "DD MMM YYYY").utc().format("YYYY-MM-DD") : null,
            "promoEndDate": this.ProductForm.controls.promoEndDate.value ? moment(this.ProductForm.controls.promoEndDate.value, "DD MMM YYYY").utc().format("YYYY-MM-DD") : null,
            // "used": this.ProductForm.controls.used.value == "false" ? false : true,
          },
         // "stock": this.ProductForm.controls.openingStock.value,
          "warehouseId": `${this.warehouseId}`,
          "productImage": this.imageArray ? this.imageArray : this.blankImage,
          "fields": [],
          "dropdownFields": []
        }

        if (this.coverImageChange === true) {
          // Iterate over existing custom fields
          if (this.productCustomFieldsDetails && this.productCustomFieldsDetails.length > 0) {
            this.productCustomFieldsDetails.forEach(field => {
              // const fieldValue = this.getProductFormFieldValue(field);
              const fieldValue = this.ProductForm.controls[field.fieldName].value;
              const customFieldData = this.createCustomFieldData(field, fieldValue);
              if(field.type === 'TEXT'){
                data.fields.push(customFieldData);
              } 
            });
            // this.dropdownValue.map(item => {
            //   const customFieldData = this.createCustomFieldData(item?.field, item?.value.value);
            //   data.dropdownFields.push(customFieldData);
            // })

            // Handle dropdown values separately
            this.dropdownValue.forEach(item => {
              // Ensure value is an array if it's a dropdown
              const fieldValueArray = Array.isArray(item.value.value) ? item.value.value : [item.value.value];
              const customFieldData = this.createCustomFieldData(item.field, fieldValueArray);
              data.dropdownFields.push(customFieldData);
            });
          }

          // Iterate over dynamically generated custom fields
          if (this.customFieldsList && this.customFieldsList.length > 0) {
            this.customFieldsList.forEach(field => {
              // const fieldValue = this.getProductFormFieldValue(field);
              const fieldValue = this.ProductForm.controls[field.fieldName].value;
              const customFieldData = this.createCustomFieldData(field, fieldValue);
              if(field.type === 'TEXT'){
                data.fields.push(customFieldData);
              } 
            });
            // this.dropdownValue.map(item => {
            //   const customFieldData = this.createCustomFieldData(item?.field, item?.value.value);
            //   data.dropdownFields.push(customFieldData);
            // })

            // Handle dropdown values separately
            this.dropdownValue.forEach(item => {
              // Ensure value is an array if it's a dropdown
              const fieldValueArray = Array.isArray(item.value.value) ? item.value.value : [item.value.value];
              const customFieldData = this.createCustomFieldData(item.field, fieldValueArray);
              data.dropdownFields.push(customFieldData);
            });
          }

          if (!this.f.promoPrice.value && !(this.f.promoPrice.value > 0)) {
            delete noDataImage2.goodDetails.promoStartDate;
            delete noDataImage2.goodDetails.promoEndDate;
            delete data.goodDetails.promoStartDate;
            delete data.goodDetails.promoEndDate;
          }
          this.productService.updateProduct(data, this.productId).then((res) => {
            if (res) {
              this.toastService.toastMsg({
                title: "Success",
                content: "Product Updated Successfully!",
              });
              this.productService.notifyProductAdded();
              this.dialogRef.close();
            }

          }, (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went To Wrong.",
              });
            }
          })
        }
        else {
          // Iterate over existing custom fields
          if (this.productCustomFieldsDetails && this.productCustomFieldsDetails.length > 0) {
            this.productCustomFieldsDetails.forEach(field => {
              // const fieldValue = this.getProductFormFieldValue(field);
              const fieldValue = this.ProductForm.controls[field.fieldName].value;
              const customFieldData = this.createCustomFieldData(field, fieldValue);
              if(field.type === 'TEXT'){
                noDataImage2.fields.push(customFieldData);
              }
            });
            // this.dropdownValue.map(item => {
            //   const customFieldData = this.createCustomFieldData(item?.field, item?.value.value);
            //   noDataImage2.dropdownFields.push(customFieldData);
            // })
            // Handle dropdown values separately
            this.dropdownValue.forEach(item => {
              // Ensure value is an array if it's a dropdown
              const fieldValueArray = Array.isArray(item.value.value) ? item.value.value : [item.value.value];
              const customFieldData = this.createCustomFieldData(item.field, fieldValueArray);
              noDataImage2.dropdownFields.push(customFieldData);
            });
          }

          // Iterate over dynamically generated custom fields
          if (this.customFieldsList && this.customFieldsList.length > 0) {
            this.customFieldsList.forEach(field => {
              // const fieldValue = this.getProductFormFieldValue(field);
              const fieldValue = this.ProductForm.controls[field.fieldName].value;
              const customFieldData = this.createCustomFieldData(field, fieldValue);
              if(field.type === 'TEXT'){
                noDataImage2.fields.push(customFieldData);
              }                        
            });
            // this.dropdownValue.map(item => {
            //   const customFieldData = this.createCustomFieldData(item?.field, item?.value.value);
            //   noDataImage2.dropdownFields.push(customFieldData);
            // })

            // Handle dropdown values separately
            this.dropdownValue.forEach(item => {
              // Ensure value is an array if it's a dropdown
              const fieldValueArray = Array.isArray(item.value.value) ? item.value.value : [item.value.value];
              const customFieldData = this.createCustomFieldData(item.field, fieldValueArray);
              noDataImage2.dropdownFields.push(customFieldData);
            });
          }

          this.productService.updateProduct(noDataImage2, this.productId).then((res) => {
            if (res) {
              this.toastService.toastMsg({
                title: "Success",
                content: "Product Updated Successfully!",
              });
              this.productService.notifyProductAdded();
              this.dialogRef.close();
            }

          }, (err) => {
            if (err.error.expose) {
              this.toastService.toastMsg({
                title: "Error",
                content: this.titleCasePipe.transform(err.error.error_message),
              });
            }
            else {
              this.toastService.toastMsg({
                title: "Error",
                content: "Something Went To Wrong.",
              });
            }
          })
        }
      }
    }
  }


  cancel() {
    this.dialogRef.close();
    this.imageSrcOne = '';
    this.imageSrcTwo = '';
    this.imageSrcThree = '';
    this.imageSrcFour = '';
    this.imageSrcFive = '';
    this.imageSrcSix = '';
    this.imageSrcSeven = '';
  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }
  handleInputChange(e, imgNumber?: any) {
    this.imgNumber = imgNumber;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file.type.match(pattern)) {
      alert("invalid format");
      return;
    }
    this.loaded = false;
    if (e.target.files && e.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.ProductForm.patchValue({
          file: reader.result,
        });
        if (file == null) {
          this.toastService.toastMsg({
            title: "Error",
            content: "Please Select Image To Upload.",
          });
        } else {
          if (this.imgNumber == 1) {
            formdata.set("uploadProductImage", file);
          } else if (this.imgNumber == 2) {
            formdata.set("uploadProductImage", file);
          } else if (this.imgNumber == 3) {
            formdata.set("uploadProductImage", file);
          } else if (this.imgNumber == 4) {
            formdata.set("uploadProductImage", file);
          } else if (this.imgNumber == 5) {
            formdata.set("uploadProductImage", file);
          } else if (this.imgNumber == 6) {
            formdata.set("uploadProductImage", file);
          } else if (this.imgNumber == 7) {
            this.imageSrcSeven = reader.result;
            formdata.set("uploadProductImage", file);
          }
          this.productService
            .postProductImage(formdata, this.productId)
            .then((res) => {
              if (res) {
                this.toastService.toastMsg({
                  title: "Error",
                  content: "Please Select Image To Upload.",
                });
                this.getProductImage();
                this.getGoodsById();
              }
            });
        }
      };
    }
  }

  getProductImage() {
    this.productService.getProductImage({}, this.productId).then((res) => {
      let productImage = res.data;
      if (productImage) {
        for (let i = 0; i <= productImage.length; i++) {
          if (productImage[0] && i == 0) {
            this.imageSrcOne = productImage[0].productImage;
          }
          if (productImage[1] && i == 1) {
            this.imageSrcTwo = productImage[1].productImage;
          }
          if (productImage[2] && i == 2) {
            this.imageSrcThree = productImage[2].productImage;
          }
          if (productImage[3] && i == 3) {
            this.imageSrcFour = productImage[3].productImage;
          }
          if (productImage[4] && i == 4) {
            this.imageSrcFive = productImage[4].productImage;
          }
          if (productImage[5] && i == 5) {
            this.imageSrcSix = productImage[5].productImage;
          }
        }
      }
    });
  }

  handleCoverDragEnter() {
    this.dragging = true;
  }
  handleCoverDragLeave() {
    this.dragging = false;
  }
  handleCoverImageLoad() {
    this.imageLoaded = true;
  }
  handleCoverDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.coverImgChange(e);
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberDecimalOnly(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  coverImgChange(e) {
    this.coverImageChange = true;
    var pattern = /image-*/;
    let formdata = new FormData();
    let reader = new FileReader();
    let file = e.target.files[0];

    if (!file.type.match(pattern)) {
      alert("invalid format");
      return;
    }
    const fileSizeLimit = 1 * 1024 * 1024; // 6 MB
    if (file.size > fileSizeLimit) {
      alert("File size exceeds the limit. Please select a smaller image (up to 1 MB).");
      return;
    }
    else {
      this.loaded = false;
      if (e.target.files && e.target.files[0]) {
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.ProductForm.patchValue({
            file: reader.result,
          });

          if (file == null) {
            this.toastService.toastMsg({
              title: "Error",
              content: "Please Select Image To Upload.",
            });
          } else {
            this.imageSrcSeven = reader.result;

            formdata.set("upload", file);
            e.srcElement.value = "";
            this.productService.postCoverImage1(formdata).then((res) => {
              this.productPicUrl = res.data.locations[0];
              this.toastService.toastMsg({
                title: "Success",
                content: "Image Uploaded Successfully!",
              });

            });

          }
        };
      }
    }
  }
  DeleteProductImg(imageId) {
    //6 image delete code
    this.productService.deleteproductImgId({}, imageId).then((res) => {
      this.toastService.toastMsg({
        title: "Success",
        content: "Image Deleted Successfully!",
      });
      this.getGoodsById();

    }
      , (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went To Wrong.",
          });
        }
      });
  }
  startUpload() {
    document.getElementById('uploadCoverPic').click();
  }
  startUploadImageSix() {
    document.getElementById('imageSrcSix').click();
  }
  startUploadImageFive() {
    document.getElementById('imageSrcFive').click();
  }
  startUploadImageFour() {
    document.getElementById('imageSrcFour').click();
  }
  startUploadImageThree() {
    document.getElementById('imageSrcThree').click()
  }
  startUploadImageTwo() {
    document.getElementById('imageSrcTwo').click()
  }
  startUploadImageOne() {
    document.getElementById('imageSrcOne').click()
  }

  cancelCoverImageCover() {
    //delete for cover pic
    this.productService.deleteproductCoverImgId({}, this.productId).then((res) => {
      this.toastService.toastMsg({
        title: "Success",
        content: "Cover Image Deleted Successfully!",
      });
      this.imageSrcSeven = "";
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
        this.getGoodsById()
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong.",
        });
      }
    });
  }

  cancelCoverImage(id: any) {
    this.DeleteProductImg(id);
  }

  onFileSelected(event) {
    const maxFileSize = 1024 * 1024; // 1 MB
    const selectedFiles = event.target.files;
    if (this.maxFiles == 5) {
      this.toastService.toastMsg({
        title: "Error",
        content: "You Have Already Select ${this.maxFiles} Images.",
      });
      event.srcElement.value = "";
    }
    else {
      if (selectedFiles.length > (this.Filesmax - this.maxFiles)) {

        this.toastService.toastMsg({
          title: "Error",
          content: "You Can Only Select Up To ${this.Filesmax - thismaxFiles} Images.",
        });
        event.srcElement.value = "";
        return;
      }

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        if (file.size > maxFileSize) {
          // Handle file size too large error

          this.toastService.toastMsg({
            title: "Error",
            content: "Image ${file.name} Exceeds The Maximum Size Of 1 MB",
          });
          event.srcElement.value = "";
          return;
        }
      }

      // Continue with file processing
      const frmData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(selectedFiles[i]);
        frmData.append("upload", selectedFiles[i]);
      }
      this.maxFiles = this.maxFiles + selectedFiles.length;
      this.uploadMultiplefile(frmData);
      event.srcElement.value = "";
    }
  }

  uploadMultiplefile(frmData: FormData) {
    this.productService.postCoverImage1(frmData).then((res) => {
      if (res) {
        if (this.multipleImage.length == 0) {
          this.multipleImage = res.data.locations;
          let imageArray = [];

          for (var i = 0; i < this.multipleImage.length; i++) {
            let obj = {
              "productId": this.productId,
              "productImage": this.multipleImage[i]
            };
            imageArray.push(obj);

          }
          this.imageArray = imageArray;

        }
        else {
          this.multipleImage1 = res.data.locations;
          for (var i = 0; i < this.multipleImage1.length; i++) {
            this.multipleImage.push(this.multipleImage1[i]);
          }

          let imageArray = [];

          for (var i = 0; i < this.multipleImage.length; i++) {
            let obj = {
              "productId": this.productId,
              "productImage": this.multipleImage[i]
            };
            imageArray.push(obj);

          }
          this.imageArray = imageArray;


        }

        this.toastService.toastMsg({
          title: "Success",
          content: "Product Images Added Successfully!",
        });
      }

    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went To Wrong.",
        });
      }
    });
  }

  cancelmultiImage(i: number) {
    this.previews.splice(i, 1); // Removes one element, starting from index
    this.maxFiles = this.maxFiles - 1; // Decrement maxFiles by 1

    this.imageArray.splice(i, 1);
  }

  openDatepicker(item) {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate = this.ProductForm.get(item).value;
    if (selectedDate && isNaN(selectedDate.getTime())) {
      // Set a default date or any valid date here
      this.ProductForm.get(item).setValue(new Date());
    }
  }

  openDatepickerexpiry() {
    // Check if the current value of taskdate is an "Invalid Date"
    const selectedDate2 = this.ProductForm.get('expiry').value;

    // Convert the value to a Date object if it is not already one
    const expiryDate = new Date(selectedDate2);

    if (expiryDate && isNaN(expiryDate.getTime())) {
      // Set a default date or any valid date here if the conversion fails
      this.ProductForm.get('expiry').setValue(new Date());
    } else {
      // Ensure the form control holds a valid Date object
      this.ProductForm.get('expiry').setValue(expiryDate);
    }
  }

  autoGenerate() {
    this.productService.autoGenerateBarcode({}, this.currentUser.id).then((codeData) => {
      if (codeData) {
        this.ProductForm.patchValue({
          generateBarcode: codeData.data
        })
      }
    }, (err) => {
      if (err.error.expose) {
        this.toastService.toastMsg({
          title: "Error",
          content: this.titleCasePipe.transform(err.error.error_message),
        });
      }
      else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Something Went Wrong.",
        });
      }
    })
  }


  get pricingDetailsControls() {
    return this.ProductForm.get('pricingDetails') as FormArray;
  }

  createPricingDetails(): FormGroup {
    return new FormGroup({
      cost: new FormControl(""),
      directExpense: new FormControl(""),
      margin: new FormControl("",),
      mrp: new FormControl("",),
    });
  }

  addPricingDetails(): void {
    const data = this.ProductForm.get('pricingDetails') as FormArray;
    data.push(this.createPricingDetails());
  }

  deletePricingDetails(index: number): void {
    const data = this.ProductForm.get('pricingDetails') as FormArray;
    data.removeAt(index)
  }

  calculateMinDate() {
    const promoStartDate = this.ProductForm.get('promoStartDate').value;
    
    if (promoStartDate) {
      const minDate = moment(promoStartDate, 'DD MMM YYYY').toDate(); 
      minDate.setDate(minDate.getDate() + 1);
      return minDate;
    }
    return null;
  }
}

