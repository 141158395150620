import { TitleCasePipe, formatDate } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild,AfterViewInit, ChangeDetectorRef, OnChanges, SimpleChanges } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ProfileService } from "src/app/core/services/profile.service";
import { ToastNotificationService } from "src/app/core/services/toast-notification.service";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from "@angular/material/autocomplete";
import { Observable, pipe } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import * as moment from "moment";
import { PartyService } from "src/app/core/services/party.service";
import { MatDialog } from '@angular/material/dialog';
import { ScheduleShopService } from "src/app/core/services/schedule-shop.service";
import lang from "src/assets/langTranslation/language_translation"
interface IRange {
  value: Date[];
  label: string;
}
@Component({
  selector: 'app-add-new-trade',
  templateUrl: './add-new-trade.component.html',
  styleUrls: ['./add-new-trade.component.scss']
})
export class AddNewTradeComponent implements OnInit {
  hide: boolean = true;
  public lang = lang.UAE;
  public dragging: boolean = false;
  public loaded: boolean = false;
  public imageLoaded: boolean = false;
  public activeColor: string = 'green';
  public baseColor: string = '#ccc';
  public signImg: any = '';
  public profilePic: any = '';
  public overlayColor: string = 'rgba(255,255,255,0.5)';
  partners: any[] = [];
  cityArr: string[] = [
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jharkhand',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram'
  ];

  ranges: IRange[] = [
    {
      value: [
        new Date(new Date().setDate(new Date().getDate() - 7)),
        new Date(),
      ],
      label: "Last 7 Days",
    },
    {
      value: [
        new Date(new Date().setDate(new Date().getMonth() - 1)),
        new Date(),
      ],
      label: "Last Month",
    },
  ];
  today: Date;
  maxDate: Date;
  public typeList = [];
  public value: any;
  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  public data: any;
  public ProfileForm: FormGroup;
  public TradeForm:FormGroup;
  public addressForm: FormGroup;
  public businessForm: FormGroup;
  public dateRange: FormGroup;
  public updated: boolean = false;
  public submitted: boolean;
  public date = new Date();
  public addressId: string = "";
  public additionalPlaceOfBusiness: FormArray;
  public addressLength;
  public pinDetails: any;
  public gstDetails: any;
  public finalAddress: any;
  public pin: any;
  public businessNature: any = [];
  public compositeType: boolean = false;
  public isShown: boolean = true;
  public mobileOtp: any;
  public visible = true;
  public selectable = true;
  public removable = true;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public categoryCtrl = new FormControl('',[Validators.required]);
  public filteredCategory: Observable<string[]>;
  public categoryBusiness: any = [];
  public categoryName: any = [];
  public categoryId: any = [];
  businesscategory: any[] = [];
  public opened = false;
  public isDisabledGST: boolean = true;
  public addressArray = [];
  public partenerAray=[]
  public businessType: any;
  public constitutionBusinessType: any;
  public legTrad: boolean = false;
  public cinFlag: boolean = false;
  public partnerSubmitted=false
  partnerDetails: any[] = [];
  additionaDetailsData: any[] = [];
  isLoading: boolean = false;
  public cosntBusiness = [
    "Proprietorship",
    "Partnership",
    "Private Limited Company",
    "Public Limited Company",
    "Limited Liability Partnership",
    "Hindu Undivided Family"
  ];
  public uaeCosntBusiness = [
    "Sole proprietorship",
    "Civil Company",
    "Limited Liability Company (LLC)",
    "Partnership",
    "Private Share Holding Company",
    "Public Share Holding Company",
    "Branch of Foreign Companies/Representative Office",
    "Branch of GCC companies",
    "Branch of Free zone company",
    "Branch of Dubai based companies",
    "Branch of UAE based companies"
  ];
  @ViewChild("categoryInput") categoryInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  @ViewChild("autocompleteTrigger") matACTrigger: MatAutocompleteTrigger;
  public array = [];
  serachByCategory: any;
  public ischecked: boolean = false;
  signatureData: string;
  imgNumber: any;
  showUploadInput: boolean = false;
  public imageSrc: any = "";
  profileImage: File;
  signatureImage: File;
  showAdditionalFields = false;
  editedRowIndex: number = -1;  //to store the index of the currently edited row
  additionaList: any[] = [];
  businessPlaceAdd: FormGroup;
  shopId: any;
  isFirstChange: boolean = true;

  constructor(
    public profileService: ProfileService, public partyService: PartyService, public scheduleShopService: ScheduleShopService,
    public fb: FormBuilder, private cdr: ChangeDetectorRef,
    public router: Router,
    private toastService: ToastNotificationService, private titleCasePipe: TitleCasePipe,
    private dialog: MatDialog
  ) {
    this.today = new Date();
    this.maxDate = new Date(
      this.today.getFullYear(),
      this.today.getMonth(),
      25
    );
  }

  async ngOnInit() {
    //form for profile data
    try {
      this.TradeForm =this.fb.group({
      })

      this.ProfileForm = this.fb.group({
        firstName: ["", [Validators.required, Validators.pattern("^[a-zA-Z ]*$")]],
        legalName: [{value: "", disabled: true}],
        businessType: [""],
        email: ["", [Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]],
        mobile: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
        gstinNo: [{value: "", disabled: true}],
        // state: ["", [Validators.required]],
        city: [null, [Validators.required]],
        district: [null],
        landmark: [""],
        country: ['India'],
        homeno: ["", ],
        address: ["", [Validators.required]],
        accountMethod: [{value: "", disabled: true}],
        compositeType: [{value: "", disabled: true}],
        businessNature: [""],
        tradeName: ["",  [Validators.required,Validators.maxLength(100)]],
        financialYearFrom: [{value: "", disabled: true}],
        cinNumber: [{value: "", disabled: true}],
        profileImage: [null],
        signatureImage: [null],
        pincode: [null],
        password: [null, [Validators.required]],
      });
      this.getType();

      this.getBusinessCategory();
      //calling get data api for profile
      this.getProfileData();

      //form for multiple address
      this.addressForm = this.fb.group({
        addresses: new FormArray([]),
      });

      // For Additional place of Business 
      this.businessPlaceAdd = this.fb.group({
        pincode: [null],
        // state: ['', Validators.required],
        district: [null],
        country: ['India', Validators.required],
        city: [null, Validators.required],
        homeno: ['', Validators.required],
        address: ['adress'],
        areaStreet: ['', Validators.required],
        landmark: ['', Validators.required],
      });

      //for partner Form
      this.businessForm = this.fb.group({
        position: [""],
        nameOfPartner: [""],
        licenceAndCertificate: [""],
        documentNumber: [""],
      })

      this.ProfileForm.get('businessType').valueChanges.subscribe((value) => {
        // Check if it's the first change
        if (this.isFirstChange) {
          this.isFirstChange = false;
          return;
        }
  
        // It's not the first change, so display the confirmation message
          const newConstitution = value
          this.onChangeConstitution(newConstitution);
      });
      
    } catch (error) {
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.cdr.detectChanges();
    });
  }

  onChangeConstitution(newConstitution) {
    const confirmMessage = `Do you really want to change the constitution of the business? This will lead to losing the previous data.`;
    const isConfirmed = window.confirm(confirmMessage);

    if (!isConfirmed) {
      return;
    }
  }

  getallPartnerList(){
    this.profileService.getBusinessPartners({}, this.currentUser.id).then((res :any) => {
    this.partnerDetails = res.data.filter(data => {
      return(!(data.position === null) && !(data.nameOfPartner === null))
    })
    });
  }

  getAdditionaDetails(){
    this.additionaDetailsData = []
    this.profileService.getAdditionaDetails({}, this.currentUser.id).then((res :any) => {
    this.additionaDetailsData = res.data.filter(data => {
      return (!(data.documentNumber === null) && !(data.licenceAndCertificate === null))
    })
    });
  }

  toggleAdditionalFields() {
    this.showAdditionalFields = !this.showAdditionalFields;
  }

  validatecinNumber(cin) {
    let cinNo = cin.toUpperCase();
    var regex =
      /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;

    if (regex.test(cinNo)) {
    } else {
      this.ProfileForm.controls.cinNumber.setErrors({ incorrect: true });
    }
  }

  validatepanNumber(pan) {
    let panNo = pan.toUpperCase();
    var regex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;

    if (regex.test(panNo)) {
    } else {
      this.ProfileForm.controls.panNumber.setErrors({ incorrect: true });
    }
  }

  /**
   * get gst type
   * @param gstType
   */

  /**
   * Get Controls of FormArray
   */
  get addressControls() {
    return this.addressForm.get("addresses") as FormArray;
  }
 
  addAddress(): void {
    try{
      const data = this.addressForm.get("addresses") as FormArray;
      this.cdr.detectChanges();
      if (data) {
        data.push(this.createAddress());
        this.cdr.detectChanges();

      }
    } catch {
    }
  }

  /**
   * Delete array index from FormArray
   * @param index
   */
  deleteAddressForm(index): void {
    const data = this.addressForm.get("addresses") as FormArray;
    data.removeAt(index);
  }


  /**
   * Add index to FormArray
   * @returns
   */
  createAddress(): FormGroup {
    return new FormGroup({
      homeno: new FormControl(),
      pincode: new FormControl(null),
      address: new FormControl(),
      city: new FormControl(),
      // state: new FormControl(),
      country: new FormControl('India'),
      district: new FormControl(),
      landmark: new FormControl(),
    });
  }


  /**
   * Remove index from FormArray
   * @param i
   */
  removeAddress(i: number) {
    (<FormArray>this.addressForm.get("addresses")).removeAt(i);
  }

  /**
   * Get Formcontrol of FormGroup
   */
  get f() {
    return this.ProfileForm.controls;
  }
  get fpart(){
    return this.businessForm.controls;
  }

  toggleShow(checked: boolean) {
    this.isShown = checked;
    
    if (!this.isShown) {
      this.ProfileForm.get("gstinNo").clearValidators();
      this.ProfileForm.get("gstinNo").updateValueAndValidity();
    } else {
      this.ProfileForm.get("gstinNo").setValidators([]);
      this.ProfileForm.get("gstinNo").updateValueAndValidity();
    }
  }
  
  getCategoryNamesFromIds(ids: number[]): string[] {
    return ids.map(id => {
      const category = this.categoryBusiness.find(item => item.id === id);
      return category ? category.category : '';
    });
  } 

  /**
   * Get user profile data
   */
  async getProfileData() {
    await this.profileService.getUserProfile({}, this.currentUser.id).then((res) => {
      if(res.success){
        let mobileNumber = res.data.basicdetails.mobile;
        if (mobileNumber?.length === 9) {
          mobileNumber = '0' + mobileNumber;
        }

        this.data = res.data;
        this.shopId = res.data.basicdetails?.id;
        this.constitutionBusinessType = res.data.additional.businessType
        let otherAddresses = res.data.otherAddresses;
        if (res.data.additional.gstType === "Composite") {
          this.compositeType = true;
        }
        if (otherAddresses.length > 0) {
          for (let i = 0; i < otherAddresses.length; i++) {
            this.addressId = otherAddresses[i].id;
            this.addressControls.push(
              new FormGroup({
                address: new FormControl(otherAddresses[i]?.address),
                city: new FormControl(otherAddresses[i]?.city),
                country: new FormControl(otherAddresses[i]?.country),
                homeno: new FormControl(otherAddresses[i]?.homeno),
                pincode: new FormControl(otherAddresses[i]?.pincode ? otherAddresses[i].pincode : null),
                // state: new FormControl(otherAddresses[i].state),
                landmark: new FormControl(otherAddresses[i]?.landmark),
                district: new FormControl(otherAddresses[i]?.district),
                id: new FormControl(otherAddresses[i]?.id),
              })
            );
          }
        }
        if (res.data.additional.isRegistered != null) {
          this.toggleShow(res.data.additional.isRegistered);
        }
        if (res.data.additional.gstinNo != null) {
        }
        let businessNatureData = res.data.businessTypes.map((item) => item.id);
        let businesscategory = res.data.businessCategory.map((item) => item.id);
        this.businesscategory = res.data.businessCategory.map((item) => item.id);
        this.categoryName = this.getCategoryNamesFromIds(this.businesscategory);
        //sending data from get api to form by patching
        this.ProfileForm.patchValue({
          // email: res.data.basicdetails.email,
          businessType: res.data.additional.businessTypes,
          firstName: res.data.basicdetails.firstName,
          totalPlaces: res.data.additional.totalPlaces,
          legalName: res.data.additional.legalName,
          gstinNo: res.data.additional.gstinNo
            ? res.data.additional.gstinNo
            : null,
          compositeType: res.data.additional.compositeType,
          fullName: res.data.basicdetails.firstName,
          mobile: mobileNumber,          
          // tradeName: res.data.additional.tradeName,
          accountMethod: res.data.additional.accountMethod,
          pincode: res.data.primaryAddress.pincode ? res.data.primaryAddress.pincode : null,
          // state: res.data.primaryAddress.state ? res.data.primaryAddress.state : "",
          city: res.data.primaryAddress.city ? res.data.primaryAddress.city : "",
          country: res.data.primaryAddress.country ? res.data.primaryAddress.country : 'India',
          address: res.data.primaryAddress.address ? res.data.primaryAddress.address : "",
          landmark: res.data.primaryAddress.landmark ? res.data.primaryAddress.landmark : "",
          district: res.data.primaryAddress.district ? res.data.primaryAddress.district : null,
          homeno: res.data.primaryAddress.homeno ? res.data.primaryAddress.homeno : "",
          panNumber: res.data.additional.panNumber ? res.data.additional.panNumber : "",
          financialYearFrom: res.data.additional.financialYearFrom ? moment(res.data.additional.financialYearFrom).format(
            "DD-MMM-YYYY"
          ) : "",
          bookBeginningFrom: res.data.additional.bookBeginningFrom ? moment(res.data.additional.bookBeginningFrom).format(
            "DD-MMM-YYYY"
          ) : "",
  
          cinNumber: res.data.additional.cinNumber,
          businessNature: businessNatureData,
          categoryBusiness: [...this.categoryId, ...this.businesscategory],
        });
      }
    });
    this.getallPartnerList();
    this.getAdditionaDetails()
  }
  //get pincode api

  getPincodeDetails(pin) {
    this.profileService.getUserPincode({}, pin).then((res) => {
      this.pinDetails = res;
      this.ProfileForm.patchValue({
        district: res.data.districtName,
         state: res.data.stateName,
      });
    });
  }

  getPincodeDetailsForAdditionalAddress(pin, index) {
    if (pin.length == 6) {
      this.profileService.getUserPincode({}, pin).then((res) => {
        this.pinDetails = res.data;
        this.addressControls.controls[index].patchValue({
          pincode: this.pinDetails.pincode,
          state: this.pinDetails.stateName,
          district: this.pinDetails.districtName,
        });
        this.addressControls
          .at(index)
          .patchValue( res.data.districtName);
      });
    }
  }



  /**
   * get nature of business list
   */
  public getType() {
    this.profileService.getTypes({}).then((response) => {
      this.typeList = response.data;
    });
}

  /**
   * Update user profile
   * @returns
   */
  UpdateNewShop() {
    this.submitted = true;

    let registerdProfileData = {
      basicDetails: {
        firstName: this.ProfileForm.controls.firstName.value,
        mobile: this.ProfileForm.controls.mobile.value,
        lastName : null,  // need to remove from Backend
        email : this.ProfileForm.controls.email.value, 
        parentId : this.currentUser.id,
        password : this.ProfileForm.controls.password.value,

      },
      shopDetails: {
        isRegistered: this.isShown,
        legalName: null,
        businessType: this.ProfileForm.controls.businessType.value
        ? this.ProfileForm.controls.businessType.value.toString()
        : " ",
        gstType: null,
        compositeType: this.ProfileForm.controls.compositeType.value ? this.ProfileForm.controls.compositeType.value : "Trader",
        gstinNo: this.ProfileForm.controls.gstinNo.value ? this.ProfileForm.controls.gstinNo.value.toUpperCase() : null,
        financialYearFrom: this.ProfileForm.controls.financialYearFrom.value,
        accountMethod: this.ProfileForm.controls.accountMethod.value,
        tradeName: this.ProfileForm.controls.tradeName.value,
      },
      address: {
        city: this.ProfileForm.controls.city.value,
        pincode:this.ProfileForm.controls.pincode.value?this.ProfileForm.controls.pincode.value : null,
        // city:null,
        district: null,
        landmark: this.ProfileForm.controls.landmark.value ? this.ProfileForm.controls.landmark.value : "landmark",
        country: this.ProfileForm.controls.country.value,
        homeno:null,
        address: this.ProfileForm.controls.address.value,
      },
      businesses: this.ProfileForm.controls.businessNature.value,
      categoryBusiness: [...this.categoryId, ...this.businesscategory],
    };

    let finalObject;

    if (!this.isShown) {
      finalObject = registerdProfileData;
    } else {
      finalObject = registerdProfileData;
    }
    if(this.ProfileForm.valid ){
      this.scheduleShopService.addNewShop(finalObject).then((res : any) => {
          if (res.success) {
            
          this.toastService.toastMsg({
            title: "Success",
            content: "Shop Added Successfully!",
          });
            this.router.navigate(["pages/shopping/shop-schedule-page/shop-details-list"]);
          }
        },
        (err) => {
          if (err.error.ststus === 500) {
            this.toastService.toastMsg({
              title: "Error",
              content: err.error.error_message,
            });
          } else{
            this.toastService.toastMsg({
              title: "Error",
              content: err.error.error_message,
            });
          }
        }
        );
      } else {
        this.toastService.toastMsg({
          title: "Error",
          content: "Please Fill Up Mandatory Details First Before Submit",
        });
      }
    }
      
  /**
   * Add multiple address
   */
  addMultipleAddress() {
  this.submitted=true;
  const newAddress = this.addressForm.get('addresses').value[this.addressControls.length - 1];

  // Check if the new address is not null and contains valid data
  if (newAddress.address && newAddress.city && newAddress.country &&  newAddress.homeno && newAddress.landmark ) {
    const addressData = {
      id: newAddress.id || "",
      address: newAddress.address,
      city: newAddress.city,
      country: newAddress.country,
      district: null,
      homeno: newAddress.homeno,
      landmark: newAddress.landmark,
      pincode: newAddress.pincode
      // state: newAddress.state
    };

    this.profileService.postMultipleAddress({ addresses: [addressData] }, this.shopId)
        .then(
          (res) => {
            if (res.success) {
              this.toastService.toastMsg({
                title: "Success",
                content: "Address Added Successfully!",
              });
              this.addressArray = [];
            }
          },
          (err) => {
            if (err.error.status == 400) {
              this.toastService.toastMsg({
                title: "Error",
                content: "Fill All Additional Address Details.",
              });
            }
          }
        );
    }
  }

  isInvalidDate(event) {
    let test = event.target.value;

    if (test == "Invalid date") {
      event.target.value = formatDate(new Date(), "DD/MM/", "en"); // Change it here
    }
  }
  /**
   * update multiple address by addressId
   * @param item
   */
  updateMultipleAddress(item) {
    this.submitted=true;
    let data = {
      homeno: item.value.homeno ? item.value.homeno : "",
      landmark: item.value.landmark ? item.value.landmark : "",
      district: null,
      pincode: item.value.pincode ? item.value.pincode : null,
      address: item.value.address ? item.value.address : "",
      city: item.value.city ? item.value.city : "",
      // state: item.value.state ? item.value.state : "",
      country: item.value.country ? item.value.country : "",
    };
    this.profileService
      .updateMultipleAddress(data, item.value.id)
      .then((res) => {
        if (res) {
          this.toastService.toastMsg({
            title: "Success",
            content: "Address Updated Successfully!",
          });
        
        }
      }, (err) => {
        if (err.error.expose) {
          this.toastService.toastMsg({
            title: "Error",
            content: this.titleCasePipe.transform(err.error.error_message),
          });
        }
        else {
          this.toastService.toastMsg({
            title: "Error",
            content: "Something Went Wrong",
          });
        }
      });
  }


  /**
   * Delete Addresses by addressId
   * @param item
   */
  deleteAddress(item) {
    this.profileService.deleteAddressById({}, item.value.id).then((res) => {
      if (res) {
        this.toastService.toastMsg({
          title: "Success",
          content: "Address Deleted Successfully!",
        });
      }
    });
  }


  /**
   * Update Mobile
   */
  updateMobile() {
    let data = {
      mobile: this.ProfileForm.controls.mobile.value,
    };
    this.profileService
      .updateClientMobileOTP(data, this.currentUser.id)
      .then((res) => {
        this.toastService.toastMsg({
          title: "Success",
          content: "OTP Sent Successfully!",
        });
      });
  }

  /**
   *
   */
  updateMobileNumber() {
    let data = {
      email: this.ProfileForm.controls.email.value,
      mobile: this.ProfileForm.controls.mobile.value,
      mobileotp: this.mobileOtp,
    };
    this.profileService.updateClientMobile(data).then((res) => {
      this.toastService.toastMsg({
        title: "Success",
        content: "Contact # Verified Successfully!",
      });
    });
  }

  /**
   * add category name in categortNames array
   * @param event
   */
  add(event: MatChipInputEvent): void {
    let input = event.input;
    let value = event.value;
    // Add our category
    if ((value || "").trim()) {
      this.typeList.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = "";
    }
    this.categoryCtrl.setValue(value);
  }

  /**
   * remove/delete selected category
   * @param category
   */
  remove(category: any): void {
    let index = this.categoryName.indexOf(category);
    if (index >= 0) {
      this.categoryName.splice(index, 1);
      this.categoryId.splice(index, 1);
    }
  }

  /**
   * called when category selected from dropdown
   * @param event
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    let newValue = event.option.viewValue;
    if (this.categoryName.includes(newValue)) {
      this.categoryName = [
        ...this.categoryName.filter((fruit) => fruit !== newValue),
      ];
    } else {
      this.categoryName.push(event.option.viewValue);
    }
    this.categoryInput.nativeElement.value = "";
  }

  /**
   * open category dropdown panel
   * @param trigger
   */
  openAuto(trigger: MatAutocompleteTrigger) {
    trigger.openPanel();
    this.categoryInput.nativeElement.focus();
  }

  searchCategoryByCategory(categoryInput) {
    this.profileService.getCategoryBySearch({}, categoryInput).then((res) => {
      this.serachByCategory = res.data;
    });
  }
  /**
   * Get all categories
   */
  getBusinessCategory() {
    this.profileService.getBuisnessCategoryAll({}).then((res) => {
      this.categoryBusiness = res.data.rows;
      this.filteredCategory = this.categoryCtrl.valueChanges.pipe(
        startWith(""),
        map((name) =>
          name ? this.filterNames(name) : this.categoryBusiness.slice()
        )
      );
    });
  }

  /**
   * filter search category names
   * @param name
   * @returns
   */
  private filterNames(name: string): string[] {
    return this.categoryBusiness.filter((list) => {
        list.category.toLowerCase().indexOf(name) === 0
      }
    );
  }



  getCategoryId(list) {
    this.array.push(list.id);
    this.categoryId = this.array.filter(
      (item, index, self) => self.indexOf(item) === index
    );
    this.getBusinessCategory();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  validateTRN(TRN) {
    if (TRN.length === 15) {
        this.isDisabledGST = false;
    } else {
      this.isDisabledGST = true;
      this.ProfileForm.controls.gstinNo.setErrors({ incorrect: true });
    }
  }
}
