<div class="modal-header pt-0">
    <h1 style="color: #EB8B3F; font-weight: bolder;margin-left: 5px;" class="modal-title mb-0">Add Warehouse</h1>

    <div class="addProductButtons">
        <button type="button" style="margin-right: 10px;" class="btn btn-cancel" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-save ms-2" (click)="onSave()" *appHasPermission="['create']; name 'warehouse'">Save</button>
    </div>
</div>

<div class="modal-body">
    <form [formGroup]="warehouseForm">
        <div class="row mb-3">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <label class="form-label">Name<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input matInput  placeholder="Name"  maxlength="50" formControlName="name" required (keypress)="StringOnly($event)" >
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submitted || f.name.touched) && f.name.errors?.required">
                Name is required.
              </mat-error>
            </div>
        </div>

        <div class="row mb-3">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label class="form-label">Short Name<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input matInput placeholder="Short Name"  maxlength="50" formControlName="shortName" required  (keypress)="StringOnly($event)">
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submitted || f.shortName.touched) && f.shortName.errors?.required">
                Short Name is required.
              </mat-error>
            </div>
        
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label class="form-label">Business Name<span class="error-asterisk">*</span></label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input matInput placeholder="Business Name" maxlength="50" formControlName="businessName" required>
              </mat-form-field>
              <mat-error class="text-danger" *ngIf="(submitted || f.businessName.touched) && f.businessName.errors?.required">
                Business Name is required.
              </mat-error>
            </div>
        </div> 

        <div class="row mb-3">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label class="form-label ms-2"> Email Address<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input type="email" matInput required formControlName="email" pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"
                        placeholder="Email" [ngClass]="{'is-invalid': f.email.invalid && f.email.touched && submitted}">
                </mat-form-field>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.email.touched) &&f.email.errors?.pattern">
                    Enter a valid email id.
                </mat-error>
                <mat-error class="text-danger"
                    *ngIf="(submitted || f.email.touched) && f.email.errors?.required">
                    Email is required.
                </mat-error>
            </div>
    
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Password<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Password" maxlength="50" formControlName="password" [type]="hide ? 'password' : 'text'" required>
                    <button mat-icon-button matSuffix (click)="hide = !hide" aria-label="Toggle password visibility">
                        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.password.touched) && f.password.errors?.required">
                    Password is required.
                </mat-error>
            </div>
            
        </div>  

        <div class="row mb-3">
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <label class="form-label">Contact Person</label>
              <mat-form-field class="example-full-width" appearance="outline">
                <input matInput placeholder="Contact Person" maxlength="50" formControlName="contactPerson">
              </mat-form-field>
            </div>
        
            <div style="font-size: 12px" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <label class="form-label">Contact #<span class="error-asterisk">*</span></label>
                <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Contact #" minlength="10" maxLength="10" (keypress)="numberOnly($event)" formControlName="mobile" required>
                </mat-form-field>
                <mat-error class="text-danger" *ngIf="(submitted || f.mobile.touched) && f.mobile.errors?.required">
                    Contact # is required.
                </mat-error>
                <mat-error *ngIf="f.mobile.errors?.maxlength">
                    Contact # Must be 10 digits.
                </mat-error>
                <mat-error *ngIf="f.mobile.errors?.minlength">
                    Contact # Must be 10 digits.
                </mat-error>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-12">
                <div class="mb-3">
                    <label for="billingAddress" class="form-label">Address<span class="error-asterisk">*</span></label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput id="address" formControlName="address" maxlength="100" required rows="2"
                            placeholder="Billing Address" [ngClass]="{
                                            'is-invalid': f.address.invalid && f.address.touched && submitted
                                        }" autocomplete="off"></textarea>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.address.touched) && f.address.errors?.required">
                        Enter a valid Address.
                    </mat-error>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="mb-3">
                    <label class="form-label" for="country">Country</label>
                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-select matNativeControl disableOptionCentering formControlName="country"
                            placeholder="Country" autocomplete="off" panelClass="custom-mat-select-panel">
                            <mat-option *ngFor="let country of countryList" [value]="country.countryName">{{country.countryName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.country.touched) &&f.country.errors?.required">
                        Enter a valid Country.
                    </mat-error>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="mb-3">
                    <label class="form-label" for="pincode">Pincode</label>
                    <mat-form-field class="mb-3 example-full-width" appearance="outline">
                        <input matInput type="text" id="pincode" placeholder="Pincode" maxlength="6" minlength="6" formControlName="pincode" 
                            autocomplete="off" (keypress)="numberOnly($event)">
                    </mat-form-field>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="mb-3">
                    <label class="form-label" for="city">City <span class="error-asterisk">*</span></label>
                    <mat-form-field class="mb-3" class="example-full-width" appearance="outline">
                        <mat-select required formControlName="city" name="pintxt" id="pintxt" placeholder="City"
                            [ngClass]="{
                            'is-invalid': (f.city.invalid && f.city.touched) && submitted }">
                        <mat-option *ngFor="let city of city" [value]="city">{{ city }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error class="text-danger"
                        *ngIf="(submitted || f.city.touched) &&f.city.errors?.required">
                        Enter a valid City.
                    </mat-error>
                </div>
            </div>
            <!-- <div class="col-md-6 col-sm-12">
                <div class="mb-3">
                    <label class="form-label" for="city">City<span class="error-asterisk">*</span></label>
                    <mat-form-field class="mb-3" class="example-full-width" appearance="outline">
                        <input matInput type="text" id="city" formControlName="city" placeholder="City"
                            autocomplete="off"
                            [ngClass]="{'is-invalid': f.city.invalid && f.city.touched && submitted}" />
                    </mat-form-field>
                </div>
            </div> -->
            
            <!-- <div class="col-md-6 col-sm-12">
                <div class="mb-3">
                    <label class="form-label" for="state">State<span class="error-asterisk">*</span></label>
                    <mat-form-field class="mb-3" class="example-full-width" appearance="outline">
                        <input matInput type="text" id="state" formControlName="state" placeholder="State"
                            autocomplete="off"
                            [ngClass]="{'is-invalid': f.state.invalid && f.state.touched && submitted}" />
                    </mat-form-field>
                </div>
            </div> -->

        </div>

    </form>
</div>