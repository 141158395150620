<div class="container-fluid my-2">
  <div clas="row mb-2" style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
    <div>
      <img src="../../assets/img/dashboard-party-profiles/aayu-fashion-logo.png"
        class="img-responsive" style="width: 225px;height: 75px;">
    </div>
    <div style="font-size: 32px;font-weight: bold;color: #FC7643;margin: 20px 5px;">Aayu Fashion</div>
    <div style="font-size: 16px;font-weight: 500;color: purple;margin-bottom: 20px;">www.aayufashion.com</div>
  </div>
  <div class="row mb-2">
    <div class="col-md-12 col-lg-8">
      <div class="card" style="padding: 10px 10px 30px 10px;">
        <table class="table">
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Discount</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody *ngFor="let product of productData">
            <tr>
              <td>{{ product?.itemName }}</td>
              <td>₹&nbsp; {{ product?.basePrice }}</td>
              <td>{{ product?.quantity }}</td>
              <!-- <td> ₹&nbsp; {{ product?.subTotal }}</td> -->
              <td>{{ product?.offerData?.discountPercentage || 0 | number:'1.2-2' }} %</td>
              <td> ₹&nbsp; {{ product?.total }}</td>
            </tr>
            <tr *ngIf="product?.isOffer">
              <td colspan="1" class="text-success">
                {{ product.offerData?.schemaName}}
              </td>
              <td colspan="3"></td>
              <td colspan="1" class="text-success">
                ₹&nbsp; - {{ (product?.total - product.offerData?.totalDiscountedPrice) | number:'1.2-2' }}
              </td>
            </tr>
            <tr *ngIf="product.isFreeProduct">
              <td colspan="2" class="text-success" style="text-align: left;">
                {{ product?.schemaName | uppercase }}
              </td>
              <td colspan="1" class="text-success" style="text-align: left;">
                {{ product?.freeProductQuantity}}
              </td>
              <td colspan="1"></td>
              <td colspan="1" class="text-success" style="text-align:left;">
                ₹&nbsp; - {{ product?.freeProductTotalPrice | number:'1.2-2' }}
              </td>
            </tr>
          </tbody>
          <tbody *ngFor="productData === 0">
            <tr class="text-center">
              <!-- <td style="width:100px;">&nbsp;</td> -->
              <td colspan="6">
                <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                  alt="notfound1" width="200" height="150" />
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
    <div class="col-md-12 col-lg-4">
      <div class="card mb-2">
        <div *ngIf="customerName" class="name">
          <div class="customerName">{{ customerName }}</div>
        </div>
        <div class="card-body" *ngIf="discountedAmt">
          <div class="text-success">Applied Discount</div>
          <div>{{ (discountedAmt || 0) | number:'1.2-2' }} ₹&nbsp;</div>
        </div>
        <div class="card-body" style="background-color: #FFF0EA; color: #000;">
          <div>Sub Total</div>
          <div *ngIf="!isDiscTotal">{{ (subtotal || 0) | number:'1.2-2' }} ₹&nbsp;</div>
          <div *ngIf="isDiscTotal">{{ ((discountedTotal || 0) - ((tax || 0) - (discountedTax || 0))) | number:'1.2-2' }} ₹&nbsp;</div>
        </div>
        <!-- <div class="card-body"  style="background-color: rgb(237, 207, 153);">
          <div>Discounted Total</div>
        </div> -->
        <div class="dropdown-divider p-0 m-0"></div>
        <div class="card-body">
          <div>Tax</div>
          <div *ngIf="!isDiscTax">{{ (tax || 0) | number:'1.2-2' }} ₹&nbsp;</div>
          <div *ngIf="isDiscTax">{{ ((tax || 0) - (discountedTax || 0)) | number:'1.2-2' }} ₹&nbsp;</div>
        </div>
        <!-- <div class="card-body">
          <div>Discounted Tax</div>
        </div> -->
        <div class="card-body" style="background-color: #FFF0EA; color: #000;">
          <div>Total</div>
          <div *ngIf="!isDiscTotal">{{ (total || 0) | number:'1.2-2' }} ₹&nbsp;</div>
          <div *ngIf="isDiscTotal">{{ (discountedTotal || 0) | number:'1.2-2' }} ₹&nbsp;</div>
        </div>
        <div class="dropdown-divider p-0 m-0"></div>
        <table class="table table-responsive">
          <tbody>
            <tr>
              <td style="border-right: 1px solid #FFF0EA;">
                <div style="display: flex;flex-direction: column;">
                  <div class="p-2" style="text-align: left;font-size: 16px;color:#000;font-weight: 500;">
                    Total
                  </div>
                  <div class="p-2" style="text-align: right;font-size: 15px;color: green;font-weight: 700;">
                    ₹&nbsp;{{ (finalTotal || 0 ) | number:'1.2-2'}}
                  </div>
                  <!-- <div *ngIf="billFinalTotal !== null && billFinalTotal !== undefined" class="p-2" style="text-align: right;font-size: 15px;color: orange;">
                    ₹&nbsp;{{ ((billFinalTotal || 0) - (billFlatOffer?.discountAmount || 0 )) | number:'1.2-2'}}
                  </div> -->
                </div>
              </td>
              <td>
                <div style="display: flex;flex-direction: column;">
                  <div class="p-2" style="text-align: left;font-size: 16px;color: black;font-weight: 500;">
                    Amount Due
                  </div>
                  <div class="p-2" style="text-align: right;font-size: 15px;color: orange;font-weight: 700;">
                    ₹&nbsp;{{ amountDue || 0 | number:'1.2-2'}}
                  </div>
                  <!-- <div class="p-2" style="text-align: right;font-size: 15px;color: green;">
                    ₹&nbsp;{{ changeDue || 0 | number:'1.2-2'}}
                  </div> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="dropdown-divider"></div>
        <div style="padding: 10px 5px;">
          <div>Payment Currency - ₹&nbsp;</div>
        </div>
        <div class="dropdown-divider"></div>
        <div class="mt-3">
          <label style="font-size: 14px;color: black;margin-left: 5px;">Add Payment</label><br>
          <button type="button" class="btn paymentMode mx-2 my-1"
          [ngClass]="{'selected-mode': paymentMode === 'CASH'}">
            Cash
          </button>
          <button type="button" class="btn paymentMode mx-1 my-1"
            [ngClass]="{'selected-mode': paymentMode === 'GIFTCARD'}">
            Gift Card
          </button>
          <button type="button" class="btn paymentMode mx-2 my-1"
            [ngClass]="{'selected-mode': paymentMode === 'CREDITCARD'}">
            Credit Card
          </button>
        </div>
        <div class="p-1 w-100">
          <mat-form-field style="font-size: 12px;" class="example-full-width" appearance="outline">
            <input type="text" matInput  value = "">
          </mat-form-field>
        </div>
      </div>
      <!-- <div class="card">
        <div class="card-body" style="display: flex;flex-direction: column;">
          <div style="display: flex;">
            <div style="display: flex;flex-direction: column;flex-basis: 50%;">
              <div style="font-weight: 500;font-size: 16px;">Total</div>
              <div style="align-self: flex-end;padding: 10px 20px;font-size: 18px;color: lightgreen;">{{ (finalTotal || 0) | number:'1.2-2' }} ₹&nbsp;</div>
            </div>
            <div style="display: flex;flex-direction: column;flex-basis: 50%;">
              <div style="font-weight: 500;font-size: 16px;">Amount Due</div>
              <div style="align-self: flex-end;padding: 10px 20px;font-size: 18px;color: orange;">{{ (amountDue || 0) | number:'1.2-2' }} ₹&nbsp;</div>
            </div>
          </div>
          <div class="dropdown-divider"></div>
          <div style="display:flex;flex-direction: column;">
            <div style="padding-bottom: 20px;">Payment Mode</div>
            <div style="display: flex; gap: 2rem;">
              <button class="btn paymentBtn"
                      [ngClass]="{'selected-mode': paymentMode === 'CASH'}">
                Cash
              </button>
              <button class="btn paymentBtn"
                      [ngClass]="{'selected-mode': paymentMode === 'GIFTCARD'}">
                Gift Card
              </button>
              <button class="btn paymentBtn"
                      [ngClass]="{'selected-mode': paymentMode === 'CREDITCARD'}">
                Credit Card
              </button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
