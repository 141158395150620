<main class="main" id="top">
    <div class="" data-layout="container-fluid">
        <script>
            var isFluid = JSON.parse(localStorage.getItem("isFluid"));
            if (isFluid) {
                var container = document.querySelector("[data-layout]");
                container.classList.remove("container");
                container.classList.add("container-fluid");
            }
        </script>

        <!-- side bar -->
        <nav class="navbar navbar-vertical navbar-expand-md " id="sidebarPrint">
            <div class="row">
                <script>
                    var navbarStyle = localStorage.getItem("isNavbarVerticalCollapsed");
                    if (isNavbarVerticalCollapsed === "true") {
                        document
                            .querySelector(".navbar-vertical")
                            .classList.add(`navbar-${navbarStyle}`);
                    }
                </script>
                <div class="d-flex align-items-center hide22">
                    <div class="toggle-icon-wrapper d-flex align-items-center m-2">

                        <button class="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                            data-bs-toggle="tooltip" data-bs-placement="left" title=""
                            data-bs-original-title="Toggle Navigation" aria-label="Toggle Navigation"><span
                                class="navbar-toggle-icon"><span class="toggle-line"></span></span></button>

                    </div>
                </div>

            </div>
            <div class="custom-li collapse navbar-collapse" id="navbarVerticalCollapse"
                style="background-color: #110152!important;overflow: auto;width: 100%!important;margin:0!important;padding: 0!important;height:100vh;">
                <div class="navbar-vertical-content scrollbar" style="width: auto;">
                    <!-- <div class="navbar-vertical-content scrollbar"> -->

                    <ul class="navbar-nav d-flex align-items-center flex-column mb-3" id="navbarVerticalNav">
                        <li class="nav-item d-flex justify-content-center mb-2 align-items-center"
                            routerLinkActive="active" routerLink="/pages/sale-invoice" *appHasPermission="['read']; name 'sales'">
                            <a class="nav-link" role="button" aria-expanded="true" aria-controls="Sales" *ngIf="currentUser?.userType === 'EMPLOYEE'">
                                <div class="nav-link-icon">
                                    <img style="font-size: 1.1rem" width="20" height="20"
                                        src="/assets/icons/dashboard-icons/transporting.png" class="bi-pie-chart" />
                                </div>
                                <div style="font-size: 16px" class="nav-link-text text-center">
                                    Sale Invoice
                                </div>
                            </a>
                        </li>
                        <li *ngFor="let navBarItem of navBarFeatures" [routerLink]="navBarItem.routerLink"
                            routerLinkActive="active"
                            class="nav-item d-flex justify-content-center mb-2 align-items-center">
                            <a class="nav-link" role="button" aria-expanded="true"
                                [attr.aria-controls]="navBarItem.name.toLowerCase()">
                                <div class="nav-link-icon">
                                    <img [src]="navBarItem.icon" [class]="navBarItem.iconClass"
                                        [style.fontSize]="navBarItem.width ? '1.1rem' : null"
                                        [attr.width]="navBarItem.width? navBarItem.width : null"
                                        [attr.height]="navBarItem.height? navBarItem.height : null">
                                </div>
                                <div style="font-size: 16px;" class="nav-link-text text-center">{{ navBarItem.name }}
                                </div>
                            </a>
                        </li>
                    </ul>

                </div>
            </div>
        </nav>


        <div class="content" style="width: auto;margin-right: 0 !important;padding-left: 10px;padding-right: 10px;">
            <nav class="navbar navbar-expand-md bg-white" id="headNavPrint" style="height:auto;">
                <div class="container-fluid bg-white">
                    <a class="navbar-brand" id="headingPrintHide">
                        <button class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3 collapsed"
                            type="button" data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse"
                            aria-controls="navbarVerticalCollapse" aria-expanded="false"
                            aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span
                                    class="toggle-line"></span></span></button>
                        <div class="d-flex align-items-center py-3">

                            <img class="me-2" src="assets/img/icons/spot-illustrations/test_logo.png" alt=""
                                alt="iceipts logo" width="35" height="35" />

                        </div>
                    </a>

                    <div id="headingPrintHide" class="headerName">
                        <span class="welcome">Welcome {{ firstName }}</span>
                    </div>

                    <form #insideElement #searchInput class="search-form" (ngSubmit)="onSearch()" id="headingPrintHide">
                        <input type="search" [(ngModel)]="searchTerm" name="searchTerm" placeholder="Search Here..."
                            (input)="onSearch()" (keyup.enter)="onEnter()">
                        <button type="submit" (keyup.enter)="onSearch()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </form>

                    <div class="dropdown dropdownList" [ngClass]="{'show': filteredCategories.length > 0}" (click)="closeDropdown()" id="headingPrintHide">
                        <button class="dropbtn align-items-center"
                            [style.display]="filteredCategories.length > 0 ? 'flex' : 'none'">Categories</button>
                        <div class="dropdown-content"
                            [style.display]="filteredCategories.length > 0 ? 'block' : 'none'">
                            <a *ngFor="let category of filteredCategories" [routerLink]="[decodeURLComponent(categoryRouterLinks[category])]">
                                {{ category }}
                            </a>
                                <!-- [routerLink]="[decodeURLComponent(categoryRouterLinks[category])]">{{ category }}</a> -->
                        </div>
                    </div>

                    <div class="collapse navbar-collapse nav-list-items buttonList" id="navbarNav">
                        <div class="navbar-nav navbar-horizantal listOfButton">

                            <!-- <button type="button" id="printPageButton" class="btn text-nowrap">
                                <span style="text-decoration: underline;font-size:16px;font-weight:700;">
                                    Get Pro
                                </span>
                            </button> -->

                            <!--
                            <div class="nav-item createNewButton navOption-btns">
                                <button type="button" class="btn text-nowrap" [matMenuTriggerFor]="createNew">
                                    <span>
                                        <i class="bi bi-check2-circle my-2 mx-1"></i>
                                    </span>
                                    Create New
                                </button>
                                <mat-menu #createNew="matMenu">
                                    <ng-container *ngFor="let category of createNewBtn">
                                        <button mat-menu-item [matMenuTriggerFor]="category?.name">{{ category?.name }}</button>
                                    </ng-container>
                                </mat-menu>

                                <ng-container *ngFor="let category of createNewBtn">
                                  <mat-menu [matMenuTriggerFor]="category.name" #{{ category.name }}="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog(subcategory)" *ngFor="let subcategory of category.subcategories">
                                      {{ subcategory }}
                                    </button>
                                  </mat-menu>
                                </ng-container>
                              </div>            -->
                            <div class="nav-item reportButton navOption-btns" *appHasPermission="['read']; name 'reports'">
                                <button type="button" class="btn text-nowrap" id="headingPrintHide"  routerLinkActive="active"  routerLink="/pages/end-day-reports">
                                    <span><i class="bi bi-check2-circle my-2 mx-1"></i></span>
                                    Day End Report
                                </button>
                            </div>

                            <div class="nav-item createNewButton navOption-btns">

                                <button type="button" class="btn text-nowrap" [matMenuTriggerFor]="createNew" id="headingPrintHide">
                                    <span><i class="bi bi-check2-circle my-2 mx-1"></i></span>
                                    Create New
                                </button>
                                <!-- <mat-menu #createNew="matMenu">
                                    <button mat-menu-item [matMenuTriggerFor]="Contact">Contacts</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Product">Product</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Sale">Sale</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Purchase">Purchase</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Bank">Bank</button>
                                    <button mat-menu-item [matMenuTriggerFor]="HRMS">HRMS</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Marketing">Marketing</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Accounting">Accounting</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Shopping">Shopping</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Warehouse">Warehouse</button>
                                </mat-menu> -->

                                <mat-menu #createNew="matMenu">
                                    <ng-container *ngIf="hasPermissionForAnySubcategory(['vendor', 'vendorgroup'])">
                                        <button mat-menu-item [matMenuTriggerFor]="Contact">Contacts</button>
                                    </ng-container>
                                    <ng-container *ngIf="hasPermissionForAnySubcategory(['product', 'service'])">
                                        <button mat-menu-item [matMenuTriggerFor]="Product">Product</button>
                                    </ng-container>
                                    <ng-container *ngIf="hasPermissionForAnySubcategory(['sales', 'paymentin', 'estimation', 'sale-saleorder', 'sale-salereturn'])">
                                        <button mat-menu-item [matMenuTriggerFor]="Sale">Sale</button>
                                    </ng-container>
                                    <ng-container *ngIf="hasPermissionForAnySubcategory(['purchaseinvoicelist', 'paymentoutlist', 'purchaseorderlist', 'purchasereturnlist'])">
                                        <button mat-menu-item [matMenuTriggerFor]="Purchase">Purchase</button>
                                    </ng-container>
                                    <ng-container *ngIf="hasPermissionForAnySubcategory(['bankdetails'])">
                                        <button mat-menu-item [matMenuTriggerFor]="Bank">Bank</button>
                                    </ng-container>
                                    <ng-container *ngIf="hasPermissionForAnySubcategory(['admin', 'employee'])">
                                        <button mat-menu-item [matMenuTriggerFor]="HRMS">HRMS</button>
                                    </ng-container>
                                    <ng-container *ngIf="hasPermissionForAnySubcategory(['crm','campaign', 'offers'])">
                                        <button mat-menu-item [matMenuTriggerFor]="Marketing">Marketing</button>
                                    </ng-container>
                                    <ng-container *ngIf="hasPermissionForAnySubcategory(['expenses', 'assets'])">
                                        <button mat-menu-item [matMenuTriggerFor]="Accounting">Accounting</button>
                                    </ng-container>
                                    <ng-container *appHasPermission="['create']; name 'shopschedule'">
                                        <button mat-menu-item [matMenuTriggerFor]="Shopping">Shopping</button>
                                    </ng-container>
                                    <ng-container *appHasPermission="['create']; name 'warehouse'">
                                        <button mat-menu-item [matMenuTriggerFor]="Warehouse">Warehouse</button>
                                    </ng-container>
                                </mat-menu>
                                <mat-menu #Contact="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Contact')" *appHasPermission="['create']; name 'vendor'">Contacts</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Vendor_Group')" *appHasPermission="['create']; name 'vendorgroup'">Vendor Group</button>
                                </mat-menu>

                                <mat-menu #Product="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Product')" *appHasPermission="['create']; name 'product'">Product</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Service')" *appHasPermission="['create']; name 'service'">Service</button>
                                </mat-menu>

                                <mat-menu #Sale="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('sale-invoice')" *appHasPermission="['create']; name 'sales'">Sale Invoice</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('payment-in')" *appHasPermission="['create']; name 'paymentin'">Payment In</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Estimation-Invoice')" *appHasPermission="['create']; name 'estimation'">Estimation Invoice</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('sale-order')" *appHasPermission="['create']; name 'saleorder'">Sale Order</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('sale-return')" *appHasPermission="['create']; name 'salereturn'">Sale Return</button>
                                </mat-menu>

                                <mat-menu #Purchase="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('purchase-invoice')" *appHasPermission="['create']; name 'purchaseinvoicelist'">Purchase Invoice</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('payment-out')" *appHasPermission="['create']; name 'paymentoutlist'">PaymentOut</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('purchase-order')" *appHasPermission="['create']; name 'purchaseorderlist'">Purchase Order</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('purchase-return')" *appHasPermission="['create']; name 'purchasereturnlist'">Purchase Return</button>
                                </mat-menu>

                                <mat-menu #Bank="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('bank')" *appHasPermission="['create']; name 'bankdetails'">Bank</button>
                                </mat-menu>

                                <mat-menu #HRMS="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Admin')" *appHasPermission="['create']; name 'admin'">Add Role</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Employee')" *appHasPermission="['create']; name 'employee'">Add Employee</button>                                </mat-menu>

                                <mat-menu #Marketing="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('add-promotion')" *appHasPermission="['create']; name 'offers'">Add Promotion</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Coupons')" *appHasPermission="['create']; name 'offers'">Coupons</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Campaign')" *appHasPermission="['create']; name 'campaign'">Campaign</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Template')" *appHasPermission="['create']; name 'campaign'">Template</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Lead')" *appHasPermission="['create']; name 'crm'">Lead</button>

                                </mat-menu>

                                <mat-menu #Accounting="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Expense')" *appHasPermission="['create']; name 'expenses'">Expense</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('add-asset')" *appHasPermission="['create']; name 'assets'">Current Asset</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('add-non-asset')" *appHasPermission="['create']; name 'assets'">Non Current Asset</button>
                                </mat-menu>

                                <mat-menu #Shopping="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Appointment')" *appHasPermission="['create']; name 'shopschedule'">Appointment</button>
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Time-Schedule')" *appHasPermission="['create']; name 'shopschedule'">Time Schedule</button>
                                </mat-menu>

                                <mat-menu #Warehouse="matMenu">
                                    <button mat-menu-item class="nav-link" (click)="openDialog('Warehouse')" *appHasPermission="['create']; name 'warehouse'">Warehouse</button>
                                </mat-menu>
                            </div>

                            <div class="nav-item reportButton navOption-btns" *appHasPermission="['read']; name 'reports'">
                                <button type="button" class="btn" [matMenuTriggerFor]="Reports" id="headingPrintHide">
                                    <span>
                                        <i class="bi bi-check2-circle m-2"></i></span>
                                    Reports</button>
                                <mat-menu #Reports="matMenu">
                                    <button mat-menu-item [matMenuTriggerFor]="Contact_Reports">Contact Reports</button>
                                    <button mat-menu-item [matMenuTriggerFor]="Product_Reports">Product Reports</button>
                                </mat-menu>
                                <mat-menu #Contact_Reports="matMenu">
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/party-statement">Contact Statement</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/party-wise-profit-loss">Contact Wise Profit &
                                        Loss</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/all-parties">All Contacts</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/party-report-by-item">Contact Report By Item</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/sale-purchase-by-party">Sale Purchase By Contact</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/sale-purchase-by-party-group"> Sale
                                        Purchase By Contact Group</button>
                                </mat-menu>

                                <mat-menu #Product_Reports="matMenu">
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/item-batch-report">Item
                                        Batch Report</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/item-detail">Item Detail</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/item-report-by-party">Item Report By Contact</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/item-wise-discount">Item Wise Discount</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/item-wise-profit-and-loss">Item Wise Profit &
                                        Loss</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/low-stock-summary">Low
                                        Stock Summary</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/stock-summary">Stock Summary</button>
                                    <button mat-menu-item class="nav-link" routerLinkActive="active"
                                        routerLink="/pages/stock-detail">Stock Detail</button>
                                </mat-menu>
                            </div>

                            <div class="nav-item mx-4 bellIcon">

                                <div class=" col-4" id="headingPrintHide">
                                    <a class="nav-link notification-indicator notification-indicator-primary px-2 fa-icon-wait"
                                        *ngIf="isShow" id="navbarDropdownNotification" role="button"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                                            class="svg-inline--fa fa fa-bell fa-w-14 fs-2 py-2"
                                            data-fa-transform="shrink-6"></span></a>
                                    <a *ngIf="!isShow" style="color: var(--falcon-navbar-light-color)"
                                        id="navbarDropdownNotification" role="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false"><span
                                            class="svg-inline--fa fa fa-bell fa-w-14 fs-2 py-2"
                                            data-fa-transform="shrink-6"></span></a>
                                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-card dropdown-menu-notification dropdown-caret-bg"
                                        aria-labelledby="navbarDropdownNotification">
                                        <div class="card card-notification shadow-none">
                                            <div class="card-header">
                                                <div class="row justify-content-between align-items-center">
                                                    <div class="col-auto">
                                                        <h4 class="card-header-title mb-0">Notifications</h4>
                                                    </div>
                                                    <div class="col-auto ps-0 ps-sm-3">
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="font-size: 12px;" class="scrollbar-overlay"
                                                style="max-height: 19rem">
                                                <div class="list-group list-group-flush fw-normal">
                                                    <div class="notification-body list-group-title border-bottom text-center p-2"
                                                        *ngIf="!isData">
                                                        No new notifications.
                                                    </div>
                                                    <div *ngIf="isData && !isShow">
                                                        <div class="list-group-item"
                                                            *ngFor="let i of notificationReadArray">
                                                            <a class="notification notification-flush notification-unread"
                                                                (click)="GoTOPages(type)">
                                                                <div class="notification-avatar">
                                                                    <div class="avatar avatar-2xl me-3">

                                                                    </div>
                                                                </div>
                                                                <div class="notification-body">
                                                                    <strong>{{i.title}}</strong>
                                                                    <p class="mb-1">
                                                                        {{i.msg}}
                                                                    </p>
                                                                    <p>{{type}}</p>
                                                                    <span class="notification-time"><span class="me-2"
                                                                            role="img"
                                                                            aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="isData && isShow">
                                                        <div class="list-group-item"
                                                            *ngFor="let i of notificationUnreadArray">
                                                            <a class="notification notification-flush notification-unread"
                                                                (click)="GoTOPages(type)">
                                                                <div class="notification-avatar">
                                                                    <div class="avatar avatar-2xl me-3">

                                                                    </div>
                                                                </div>
                                                                <div class="notification-body">
                                                                    <strong>{{i.title}}</strong>
                                                                    <p class="mb-1">
                                                                        {{i.msg}}
                                                                    </p>
                                                                    <p>{{type}}</p>
                                                                    <span class="notification-time"><span class="me-2"
                                                                            role="img"
                                                                            aria-label="Emoji">💬</span>{{i.timeAgo}}
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer text-center border-top" *ngIf="isData">
                                                <a class="card-link d-block" routerLink="/pages/notifications">View
                                                    all</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="nav-item profilePic">
                                <a class="nav-link p-0" id="navbarDropdownUser" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <div class="avatar avatar-xl my-2" id="headingPrintHide">
                                        <img class="rounded-circle"
                                            [src]="imageSrc ? imageSrc : '/assets/images/default-profile-pic.JPG'" />
                                    </div>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end py-3"
                                    style="font-size: 13px !important;min-width: 150px;"
                                    aria-labelledby="navbarDropdownUser">
                                    <div class="bg-white dark__bg-1000 rounded-2 py-2">
                                        <a class="dropdown-item fw-bold text-warning"
                                            routerLink="/auth/subscription"><span
                                                class="fas fa-crown me-1"></span><span>Go
                                                Pro</span></a>

                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" *ngIf="this.currentUser.userType == 'CLIENT'"
                                            routerLinkActive="active" routerLink="/pages/profile">Profile &amp;
                                            Account</a>

                                        <div class="dropdown-divider" *ngIf="this.currentUser.userType == 'CLIENT'"></div>
                                        <a class="dropdown-item" *ngIf="this.currentUser.userType == 'CLIENT'"
                                            routerLinkActive="active" routerLink="/pages/profile-settings">Settings</a>
                                        <div class="dropdown-divider" *ngIf="this.currentUser.userType == 'CLIENT'"></div>
                                        <a class="dropdown-item" *ngIf="this.currentUser.userType == 'CLIENT'"
                                            routerLinkActive="active" routerLink="/pages/profile-settings">Change log</a>
                                        <div class="dropdown-divider" *ngIf="this.currentUser.userType == 'CLIENT'"></div>
                                        <a class="dropdown-item" *appHasPermission="['read']; name 'employee'"
                                            routerLinkActive="active" style="cursor: pointer;" (click)="openDialogForSwitchingUser()">Switch User</a>
                                        <div class="dropdown-divider" *appHasPermission="['read']; name 'employee'"></div>
                                        <a class="dropdown-item" style="cursor: pointer;" (click)="logout()">Logout</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <router-outlet></router-outlet>
        </div>
    </div>

</main>
