import { Component, OnInit } from '@angular/core';
import { SaleInvoiceService } from '../core/services/sale-invoice.service';
import { ToWords } from 'to-words';
import { ProfileService } from '../core/services/profile.service';
import * as numberToWords from 'number-to-words';

@Component({
  selector: 'app-customer-facing-display',
  templateUrl: './customer-facing-display.component.html',
  styleUrls: ['./customer-facing-display.component.scss'],
})
export class CustomerFacingDisplayComponent implements OnInit {

  public currentUser = JSON.parse(localStorage.getItem("currentUser"));
  invoiceData: any;
  productData: any[] = [];
  finalTotal: any;
  amountDue: any;
  discountedTotal: any;
  subtotal: any;
  discountedTax: any;
  tax: any;
  isDiscTax: boolean;
  isDiscTotal: boolean;
  paymentMode: string;
  discountedAmt: any;
  customerName: any;
  giftCardAmtToMinusFromTotal: any;
  total: any;

  constructor(private saleInvoiceService: SaleInvoiceService,
    public profileService: ProfileService,
  ) { }

  ngOnInit(): void {
    window.addEventListener('storage', (event) => {
      if (event.key === 'invoiceData') {
        this.updateInvoiceDisplay(JSON.parse(event.newValue));
      }
    });
    // Subscribe to the invoice data and update the UI in real-time
    this.saleInvoiceService.currentInvoiceData.subscribe((data) => {
      this.invoiceData = data;
      console.log("Invoice data updated", this.invoiceData);
    });
  }

  updateInvoiceDisplay(updatedData) {
    console.log("Invoice data updated", updatedData);
    this.productData = updatedData.productData;  // Update product data
    this.subtotal = updatedData.subtotal;
    this.discountedTax = updatedData.discountedTax;
    this.tax = updatedData.tax;
    this.discountedTotal = updatedData.discountedTotal;
    this.finalTotal = updatedData.finalTotal;
    this.amountDue = updatedData?.amountDue;
    this.isDiscTax = updatedData?.isDiscTax;
    this.isDiscTotal = updatedData?.isDiscTotal;
    this.paymentMode = updatedData?.paymentMode;
    this.discountedAmt = updatedData?.discountedAmt;
    this.customerName = updatedData?.customerName;
    this.total = updatedData?.total;
    this.giftCardAmtToMinusFromTotal = updatedData?.giftCardAmtToMinusFromTotal;

    // Update other values as needed
  }
}
