import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UrlConstants } from '../../_helpers/url-constants'
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { promise } from 'protractor';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(public httpClient: HttpService, public http: HttpClient) { }


  updateUserprofile(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.putUserProfile + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });


  }
  getGstNumber(data: {}, gstin): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getGst + gstin, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  warehouseUpdate(data, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.warehouseUpdate + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });


  }

  getUserProfile(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getUserProfile + id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getBuisnessCategoryAll(data:{}):any{
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getBusNessCategory , 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }
  getUserPincode(data: {}, pincode): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addPincode + pincode, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getTypes(data: {}): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getTypes, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  postMultipleAddress(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addMultipleAddress + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  addBusinessPartners(data:{},id):any{
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addBusinessPartners + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  addLicDocument(data:{},id):any{
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.addLicDocument + id, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deletePartner(data:{},userId, partnerId):any{
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deletePartner + "?userId=" + userId + "&partnerId=" + partnerId, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  updateBusinessPartner(data:{},id):any{
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.updateBusinessPartner + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }
  getBusinessPartners(data:{},id){
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getBusinessPartners +id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }

  /* chat-bot regarding */
  botLogin(data:{}, userId, userToken){
    return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.botLogin + userId + "?userToken=" +userToken, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }

  getAdditionaDetails(data:{},id){
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getBusinessPartners +id, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }

  updateMultipleAddress(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.updateMultipleAddress + id, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  deleteAddressById(data: {}, id): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleteMultipleAddress + id, 'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  updateClientMobileOTP(data: {}, userId) {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.mobileUpdateOtp + userId, 'PUT').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  updateClientMobile(data: {}) {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.clientMobileUpdate, 'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  getUserSubscriptionDeatils(data: {}, userId){
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.getSubscribedUserDetails +userId, 'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });

  }
  getCategoryBySearch(data:{},SearchCategory):any{
  return new Promise((resolve, reject) => {
      this.httpClient.inventory(data, UrlConstants.getCategoryByCAtegorySearch +"?search=" +SearchCategory,  'GET').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  
  postProfileImage(data: {}, userId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.profileImagePost + userId ,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
  signatureImgAPI(data: {}, userId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.signatureImage + userId ,  'POST').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteProfileImg(data: {}, userId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleteProfileImg + userId ,  'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  deleteSignature(data: {}, userId:string): any {
    return new Promise((resolve, reject) => {
      this.httpClient.call(data, UrlConstants.deleteSignature + userId ,  'DELETE').subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }
}

