<div class="widget">
  <mat-card class="hidden-scroll">
    <mat-card-header style="display: flex;align-items: center;flex-wrap: wrap;">
      <mat-card-title class="mb-0">Best Selling Products</mat-card-title>
      <div class="widgeticons">
        <div class="dropdown" *ngIf="updatedProductList.length !== 0">
          <button mat-raised-button class="dropdown-toggle my-2" type="button" id="filterByDropdown" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="filterMenu">
            Filter By : {{ selectedFilter || "All" }}
          </button>
          <mat-menu #filterMenu="matMenu">
            <button mat-menu-item (click)="selectFilter('low Stock')"
              [ngClass]="{ selected: selectedFilter === 'low Stock' }">
              Low Stock
            </button>
            <button mat-menu-item (click)="selectFilter('In Stock')"
              [ngClass]="{ selected: selectedFilter === 'In Stock' }">
              In Stock
            </button>
            <button mat-menu-item (click)="selectFilter('out Of Stock')"
              [ngClass]="{ selected: selectedFilter === 'out Of Stock' }">
              Out Of Stock
            </button>
            <button mat-menu-item (click)="openPriceRangeDialog()" [ngClass]="{ selected: selectedFilter === 'Price' }">
              Price
            </button>
            <button mat-menu-item (click)="selectFilter('All')" [ngClass]="{ selected: selectedFilter === 'All' }">
              Remove All
            </button>
          </mat-menu>
        </div>
        <div class="dropdown" *ngIf="updatedProductList.length !== 0">
          <button mat-raised-button class="dropdown-toggle my-2" type="button" id="sortByDropdown" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" [matMenuTriggerFor]="sortMenu">
            Sort By : {{ selectedSort || "Relevance" }}
          </button>
          <mat-menu #sortMenu="matMenu">
            <button mat-menu-item (click)="selectSort('Price: Low to High')">
              Price: Low to High
            </button>
            <button mat-menu-item (click)="selectSort('Price: High to Low')">
              Price: High to Low
            </button>
            <button mat-menu-item (click)="selectSort('Stock: Low to High')">
              Stock: Low to High
            </button>
            <button mat-menu-item (click)="selectSort('Stock: High to Low')">
              Stock: High to Low
            </button>
            <button mat-menu-item (click)="selectSort('Total Sales')">
              Total Sales
            </button>
            <button mat-menu-item (click)="selectSort('Relevance')">
              Relevance
            </button>
          </mat-menu>
        </div>
      </div>
    </mat-card-header>

    <mat-card-content>
      <div class="table-container hidden-scroll">
        <div *ngIf="filteredProductList.length === 0" style="color: red">
          {{ emptyListMessage }}
        </div>

        <table class="table table-responsive mt-3">
          <thead>
            <tr style="font-family: 'Poppins', sans-serif">
              <th style="width: 15%">Product ID</th>
              <th>Image</th>
              <th>Product Name</th>
              <th>Total Sales</th>
              <th>Stock</th>
              <th>Status</th>
              <th style="text-align: right; width: 5%">Price</th>
            </tr>
          </thead>
          <tbody *ngFor="let item of updatedProductList" style="font-family: 'Poppins', sans-serif">
            <tr style="align-items: center">
              <td style="width: 15%">#{{ item.itemCode }}</td>
              <td>
                <img src="{{ item.coverImage }}" height="60px" width="70px" alt="Product Image"
                  onerror="this.src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcm9rPgL_MfNP7BIgeb8vn5Gb8J0F2Y_EAYylwRWTakA&usqp=CAU&ec=48665699'" />
              </td>
              <td class="long-name">{{ item.itemName }}</td>
              <td>{{ item.totalSold }}</td>
              <td>{{ item.stock }}</td>
              <td>
                <button mat-flat-button [ngClass]="{
                    'status-badge': true,
                    danger: item.status === 'outOfStock',
                    warning: item.status === 'lowStock',
                    success:
                      item.status !== 'outOfStock' && item.status !== 'lowStock'
                  }">
                  {{
                  item.status === "outOfStock"
                  ? "Out of Stock"
                  : item.status === "lowStock"
                  ? "Low Stock"
                  : item.status === "inStock"
                  ? "In Stock"
                  : item.status
                  }}
                </button>
              </td>
              <td style="text-align: right; width: 5%">
                ₹&nbsp;{{ item.salePrice | number : "1.2-2" }}
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="updatedProductList.length === 0" class="updatedProduct-notFound">
            <tr class="text-center">
              <td colspan="7">
                <img class="image-responsive" src="../../../assets/img/icons/spot-illustrations/notfound1.png"
                  alt="notfound1" width="200" height="150" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
